import React, { Component } from 'react'
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import { createLead } from "../data/lead";
import Input from "../components/Input";
import Select from "../components/Select";
import Radios from "../components/Radios";
import FormProvider from "../components/FormProvider";
import Loader from "../components/Loader";

const PROJECT_TYPES = [
    { label: 'Type de projet', value: '' },
    { label: 'Conseil transfo numérique', value: 'Conseil transfo numérique' },
    { label: 'Sprint', value: 'Sprint' },
    { label: 'Projet au forfait', value: 'Projet au forfait' },
    { label: 'Recrutement', value: 'Recrutement' },
    { label: 'Infogérance', value: 'Infogérance' },
    { label: 'Dev au temps passé / régie', value: 'Dev au temps passé / régie' },
    { label: 'Audit tech', value: 'Audit tech' },
];
const PROJECT_SIZES = [
    { label: 'Taille du projet', value: '' },
    { label: 'Grand', value: 'Grand' },
    { label: 'Moyen', value: 'Moyen' },
    { label: 'Faible', value: 'Faible' },
];
const EVENT_QUALIFICATIONS = [
    { label: 'Qualification de l\'évènement', value: '' },
    { label: 'Hotcall', value: 'Hotcall' },
    { label: 'Coldcall', value: 'Coldcall' },
    { label: 'Réunion en physique', value: 'Réunion en physique' },
    { label: 'Email', value: 'Email' },
];
const PROJECT_SIGNING_PROBABILITIES = [
    { label: 'Probabilité signature du projet', value: '' },
    { label: 'Grande', value: 'Grande' },
    { label: 'Moyenne', value: 'Moyenne' },
    { label: 'Faible', value: 'Faible' },
];
const EVENT_RESULTS = [
    { label: 'Issue de l\'évènement', value: '' },
    { label: 'Garder contact - MLT', value: 'Garder contact - MLT' },
    { label: 'Prise de réunion en physique', value: 'Prise de réunion en physique' },
    { label: 'Prise de réunion call / vidéo', value: 'Prise de réunion call / vidéo' },
    { label: 'Ghost / Dead / Pas de relance', value: 'Ghost / Dead / Pas de relance' },
    { label: 'Envoi d\'un devis', value: 'Envoi d\'un devis' },
    { label: 'Signature du devis', value: 'Signature du devis' },
    { label: 'NRP', value: 'NRP' },
    { label: 'NRP + Message', value: 'NRP + Message' },
];

class LeadPages extends Component {
    constructor(props) {
        super(props);

        const url = new URLSearchParams(props.location.search)

        this.state = {
            firstname: url.has('firstname') ? url.get('firstname') : '',
            lastname: url.has('lastname') ? url.get('lastname') : '',
            company_name: url.has('company_name') ? url.get('company_name') : '',
            email: url.has('email') ? url.get('email') : '',
            phone: url.has('phone') ? url.get('phone') : '',
            project_type: '',
            project_size: '',
            event_qualification: '',
            project_specific_identity: 'true',
            project_signing_probability: '',
            event_result: '',
            errors: null,
            is_sended: false,
            in_loading: false
        }
    }

    submit = (e) => {
        e.preventDefault()

        if (this.state.in_loading) return;

        this.setState({
            in_loading: true
        }, () => {
            this.props.createLead(this.state)
                .then(res => {
                    this.setState({
                        is_sended: true,
                        in_loading: false
                    })
                })
                .catch(e => console.error(e))
        })
    }

    renderForm() {
        return (
            <FormProvider onSubmit={this.submit} errors={this.state.errors}>
                <Input required
                       name={'firstname'}
                       placeholder={'Jean'}
                       label={'Prénom'}
                       value={this.state.firstname}
                       onChange={firstname => this.setState({ firstname })}
                       type={'text'}
                />
                <Input required
                       name={'lastname'}
                       placeholder={'Dupont'}
                       label={'Nom de famille'}
                       value={this.state.lastname}
                       onChange={lastname => this.setState({ lastname })}
                       type={'text'}
                />
                <Input required
                       name={'company_name'}
                       placeholder={'Bluesquare Computing'}
                       label={'Nom de l\'entreprise'}
                       value={this.state.company_name}
                       onChange={company_name => this.setState({ company_name })}
                       type={'text'}
                />
                <Input required
                       name={'email'}
                       placeholder={'foo@bar.io'}
                       label={'Email'}
                       value={this.state.email}
                       onChange={email => this.setState({ email })}
                       type={'email'}
                />
                <Input required
                       name={'phone'}
                       placeholder={'06 95 96 97 98'}
                       label={'Téléphone'}
                       value={this.state.phone}
                       onChange={phone => this.setState({ phone })}
                       type={'tel'}
                />
                <Select name={'project_type'}
                        id={'project_type'}
                        label={'Type de projet'}
                        value={this.state.project_type}
                        options={PROJECT_TYPES}
                        onChange={project_type => this.setState({ project_type })}
                        required={true}
                />
                <Select name={'project_size'}
                        id={'project_size'}
                        label={'Taille du projet'}
                        value={this.state.project_size}
                        options={PROJECT_SIZES}
                        onChange={project_size => this.setState({ project_size })}
                        required={true}
                />
                <Select name={'event_qualification'}
                        id={'event_qualification'}
                        label={'Qualification de l\'évènement'}
                        value={this.state.event_qualification}
                        options={EVENT_QUALIFICATIONS}
                        onChange={event_qualification => this.setState({ event_qualification })}
                        required={true}
                />
                <Radios
                    value={this.state.project_specific_identity}
                    onChange={project_specific_identity => this.setState({ project_specific_identity })}
                    options={[
                        { label: 'Oui', value: 'true' },
                        { label: 'Non', value: 'false' },
                    ]}
                    label={'Project spécifique identifié ?'}
                    name={'project_specific_identity'}
                />
                <Select name={'project_signing_probability'}
                        id={'project_signing_probability'}
                        label={'Probabilité signature du projet'}
                        value={this.state.project_signing_probability}
                        options={PROJECT_SIGNING_PROBABILITIES}
                        onChange={project_signing_probability => this.setState({ project_signing_probability })}
                        required={true}
                />
                <Select name={'event_result'}
                        id={'event_result'}
                        label={'Issue de l\'évènement'}
                        value={this.state.event_result}
                        options={EVENT_RESULTS}
                        onChange={event_result => this.setState({ event_result })}
                        required={true}
                />
                <button className="btn btn-primary btn-block mb-3">Submit</button>
            </FormProvider>
        )
    }

    renderSuccess() {
        return (
            <div className="bg-success text-center text-white p-4">
                Le formulaire à bien été envoyé
            </div>
        )
    }

    render() {
        return (
            <div className={'container mt-4'}>
                <div className="row">
                    <div className="col align-self-center">
                        {
                            this.state.is_sended && !this.state.in_loading
                                ? this.renderSuccess()
                                : this.state.in_loading
                                    ? <Loader display={true}/>
                                    : this.renderForm()
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, { createLead })(withRouter(LeadPages));