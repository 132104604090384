import {request} from '../helpers'



// Fetch all modules

const MODULES_FETCH_ALL = 'MODULES_FETCH_ALL'
const MODULES_FETCH_ALL_SUCCESS = 'MODULES_FETCH_ALL_SUCCESS'
const MODULES_FETCH_ALL_FAIL = 'MODULES_FETCH_ALL_FAIL'

export function getModules(missionId)
{
    return request(MODULES_FETCH_ALL, 'get', '/missionModules/mission/' + missionId)
}


export function getReferenceModules()
{
    return request(MODULES_FETCH_ALL, 'get', '/mission-modules-references')
}

export function cloneModule(mission,module)
{
    let data = {mission, module}
    return request(MODULES_FETCH_ALL, 'post', '/mission-module/clone',data)
}


// Create a module

export const MODULES_CREATE_MODULE = 'MODULES_CREATE_MODULE'
export const MODULES_CREATE_MODULE_SUCCESS = 'MODULES_CREATE_MODULE_SUCCESS'
export const MODULES_CREATE_MODULE_FAIL = 'MODULES_CREATE_MODULE_FAIL'

export function createModule(name, description, mission_id)
{
    const data = { name, description, mission_id }
    return request(MODULES_CREATE_MODULE, 'put', '/missionModules', data)
}


// Update a module

const MODULES_UPDATE_MODULE = 'MODULES_UPDATE_MODULE'
const MODULES_UPDATE_MODULE_SUCCESS = 'MODULES_UPDATE_MODULE_SUCCESS'
const MODULES_UPDATE_MODULE_FAIL = 'MODULES_UPDATE_MODULE_FAIL'

export function updateModule(id, name, description, isReference)
{
    const data = { id, name, description, isReference };
    return request(MODULES_UPDATE_MODULE, 'post', '/missionModules/' + id, data, {id})
}


// Delete a module

export const MODULES_DELETE_MODULE = 'MODULES_DELETE_MODULE'
export const MODULES_DELETE_MODULE_SUCCESS = 'MODULES_DELETE_MODULE_SUCCESS'
export const MODULES_DELETE_MODULE_FAIL = 'MODULES_DELETE_MODULE_FAIL'

export function deleteModule(id)
{
    return request(MODULES_DELETE_MODULE, 'delete', '/missionModules/' + id, {}, {id})
}


// Position up

export const MODULE_POSITION = 'MODULE_POSITION'

export function modulePosition(id, position, mission_id)
{
    const data = { id, position, mission_id }
    return request(MODULE_POSITION, 'post', 'missionModules/position/' + id, data, {id})
}


// Store

const defaultState = {
    index: [],
    loading: false
};

// Reducer

export default function modulesReducer(state = defaultState, action)
{
    let index;

    switch(action.type)
    {
        case MODULES_CREATE_MODULE:
        case MODULES_FETCH_ALL:
        case MODULES_DELETE_MODULE:
        case MODULES_UPDATE_MODULE:
            return { ...state, loading: true }

        case MODULES_FETCH_ALL_FAIL:
        case MODULES_DELETE_MODULE_FAIL:
        case MODULES_CREATE_MODULE_FAIL:
        case MODULES_UPDATE_MODULE_FAIL:
            return { ...state, loading: false }

        case MODULES_FETCH_ALL_SUCCESS:
            index = [...state.index]
            action.payload.data.map((module) => {
                index = index.filter((n) => n.id != module.id)
                index.push(module)
            })
            return {
                ...state,
                index,
                loading: false
            }

        case MODULES_CREATE_MODULE_SUCCESS:
            index = [...state.index]
            index.push(action.payload.data.missionModule)
            return { ...state, index, loading: false }

        case MODULES_UPDATE_MODULE_SUCCESS:
            index = state.index.filter(( missionModule ) => missionModule.id !== action.meta.previousAction.id)
            index.push(action.payload.data.missionModule)
            return { ...state, index, loading: false }

        case MODULES_DELETE_MODULE_SUCCESS:
            index = [...state.index].filter((module) => module.id !== action.meta.previousAction.id)
            return { ...state, index, loading: false }
    }

    return state;
}
