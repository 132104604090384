import React, {Component} from 'react';
import CopyTextButton from "./CopyTextButton";
import '../styles/generalpurpose/hoverdisplayproperties.scss';
import '../styles/generalpurpose/pseudoclasscolors.scss';

export default class Informations extends Component {
    render() {
        if (!this.props.data || !this.props.data.length) return null;

        return this.props.data.map((item) => {
            let id = Math.floor(Math.random() * 1000);
            if (item.id)
                id = item.id;

            let value = item.value;
            if (value && typeof value === 'object')
                value = value.props.value;

            return (
                <div key={id} className="row">
                    <div className="col-md-3 text-black-50">
                        {item.label}
                    </div>
                    <CopyTextButton
                        wrapperClass={"col-md-9 hover-blue-tint active-blue-light"}
                        wrapperStyle={{cursor: 'pointer'}} iconClass={"far fa-copy"}
                        textToCopy={value}
                        copyLabel={item.value}
                    />
                </div>
            )
        })
    }
}
