import {request} from '../helpers'

// Actions

const PRIVILEGES_ROLES_INDEX = 'PRIVILEGES_ROLES_INDEX'

export function getRolesIndex()
{
    return request(PRIVILEGES_ROLES_INDEX, 'get', '/privileges/roles')
}

const PRIVILEGES_USERS_INDEX = 'PRIVILEGES_USERS_INDEX'

export function getUsersIndex()
{
    return request(PRIVILEGES_USERS_INDEX, 'get', '/privileges/users')
}

const PRIVILEGES_ROLES_LIST = 'PRIVILEGES_ROLES_LIST'

export function getRolesList(type, id = null)
{
    return request(PRIVILEGES_ROLES_LIST, 'get', '/privileges/roles/' + type + (id !== null ? '/' + id : ''))
}

//const PRIVILEGES_USERS_LIST = 'PRIVILEGES_ROLES_LIST'

export function getUsersList(type, id = null)
{
    return request(PRIVILEGES_ROLES_LIST, 'get', '/privileges/users/' + type + (id !== null ? '/' + id : ''))
}

const PRIVILEGES_GRANT = 'PRIVILEGES_GRANT'

export function grantTo(from_type, from_id, target_type, target_id = null)
{
    return request(PRIVILEGES_GRANT, 'post', '/privileges/add', {from_type,from_id,target_type,target_id})
}

const PRIVILEGES_REMOVE = 'PRIVILEGES_REMOVE'

export function removePrivilege(id)
{
    return request(PRIVILEGES_REMOVE, 'delete', '/privileges/remove/' + id)
}

// Store

const defaultState = {}

// Reducer

export default function Reducer(state = defaultState, action)
{
    switch(action.type) {}
    return state
}
