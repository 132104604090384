import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {getMissions} from "../data/missions";
import {withRouter} from "react-router-dom";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import MissionAddModal from "../components/missions/MissionAddModal";
import { getMissionStatus } from "../helpers";

const MISSION_STATUS_FILTER = {
    'quotation_pending': 'Pitch',
    'ongoing': 'Ongoing',
    'quotation_declined': 'Lost',
    'finished': 'Closed',
    'recurring': 'Recurring'
};

class MissionsPage extends Component
{
    state = {
        missions: [],
        showAddModal: false,
        company_name: null
    };

    loadMissions = () => {
        this.props.getMissions(0, 'no-filter', 'false', 'no-filter', 'false', 'true')
            .then((res) => {
                this.setState({
                    missions: res.payload.data
                });
            });
    }

    componentDidMount()
    {
        this.loadMissions();
        this.actionFormatter = this.actionFormatter.bind(this);
        this.statusFormatter = this.statusFormatter.bind(this);

        const url = window.location.search
        const params = new URLSearchParams(url)

        if (params.get('company_name'))
            this.setState({
                company_name: params.get('company_name')
            })
    }

    actionFormatter(cell, row) {
        return (
            <a className={'btn btn-primary'} target={'_blank'} href={'/missions/'+cell} >
                Voir
            </a>
        );
    }


    percentFormatter(cell,row) {
        return (
            <div>
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow={cell}
                         aria-valuemin="0" aria-valuemax="100" style={{width:cell+'%'}}>
                        <span className="sr-only">{cell}% complété</span>
                    </div>
                </div>
                <div>
                    <em>{cell}% ( {row.days_spent} j / {row.sold_days} )</em>
                </div>
            </div>
        )
    }

    euroFormatter(cell) {
        return cell + ' €'
    }

    invoiceFormatter(cell,row) {
        return (
            <div>
                <a href={'/invoices/' + row.id} target={'_blank'} style={{color: 'black'}}>
                    {cell > 0 ? cell + " €" : "Pas de facture" }
                </a>
            </div>
        )
    }

    statusFormatter(cell){
        return (
            <button className={'btn ' + getMissionStatus(cell, 'button', 'btn-secondary')} style={{'color':'white'}}>
                {getMissionStatus(cell, 'txt')}
            </button>
        )
    }

    render = () => {
        return (
            <div>
                <h1>Nos super missions !</h1>
                <button className="btn btn-primary float-right" onClick={this.onAddModalOpen}>
                    <i className="fa fa-plus"/>
                </button>
                <BootstrapTable data={this.state.missions}
                                striped
                                hover
                                condensed
                                search
                                exportCSV
                                pagination
                                className={'mission'}
                >
                    <TableHeaderColumn isKey dataField='id' dataSort={ true } style={{'width':'30px !important'}}>#</TableHeaderColumn>
                    <TableHeaderColumn dataField='name' dataSort={ true }>Nom</TableHeaderColumn>
                    <TableHeaderColumn dataField='customer_name' dataSort={ true } filter={{ type: 'TextFilter', delay: 500, defaultValue: this.state.company_name }}>Client</TableHeaderColumn>
                    <TableHeaderColumn dataField='invoiced_amount' dataSort={ true } dataFormat={ this.invoiceFormatter }> Factures générées HT</TableHeaderColumn>
                    <TableHeaderColumn dataField='calculated_amount' dataSort={ true } dataFormat={ this.euroFormatter }> Montant Mission HT</TableHeaderColumn>
                    <TableHeaderColumn dataField='status' dataSort={ true } dataFormat={ this.statusFormatter } filter={{ type: 'SelectFilter', options: MISSION_STATUS_FILTER, delay: 500 }}>Statut</TableHeaderColumn>
                    <TableHeaderColumn dataField='id' dataSort={ true } dataFormat={ this.actionFormatter }>
                        Action
                    </TableHeaderColumn>
                </BootstrapTable>

                {this.state.showAddModal ? <MissionAddModal onClose={this.onAddModalClose} fromMissionPage={true}/> : null}
            </div>
        )
    }

    onAddModalOpen = () => {
        this.setState({showAddModal: true});
    };

    onAddModalClose = (updated) => {
        this.setState({showAddModal: false});
        if (updated) this.loadMissions()
    };

}

export default connect(({ missions }) => ({ missions }), {getMissions})(withRouter(MissionsPage));
