import React, {Component} from 'react';
import {connect} from 'react-redux';
import SelectSearch from "react-select";
import {getUsers} from "../data/users";
import {createUserHour, retrieveUserHours, checkHours} from "../data/userHours";
import {getMissionsofWeek} from "../data/missions";
import {withRouter} from "react-router-dom";
import Input from "../components/Input";
import moment from "moment";
import Select from "../components/Select";
import UserHourInput from "../components/timesquare/UserHourInput";


class TimesquarePage extends Component
{
  state = {
        current_employee: null,
        date: moment().format('YYYY-MM-DD'),
        week_index: 0,
        user_hours: [],
        loading: true,
        check_hours: [],
        missions: [],
        mission_type: { value: 'active_projects', label: 'Projets actifs' }
  }

  componentDidMount() {
      this.props.getUsers(true)
      this.props.getMissionsofWeek(0, true, 0, this.state.mission_type.value)
          .then(callback => {
              this.setState({ missions: callback.payload.data })
          });

      this.setState({ current_employee: this.props.auth.user.id })
      this.checkHours()
      this.updateUserHour()
  }

  updateMissions() {
      this.setState({ loading: true })
      this.props.getMissionsofWeek(0, true, 0, this.state.mission_type.value)
          .then(callback => {
              this.setState({
                  missions: callback.payload.data,
                  loading: false
              })
          })
  }

  checkHours = () => {
      this.props.checkHours(this.state.date)
          .then(callback => this.setState({
              check_hours: callback.payload.data
          }))
  }

  updateUserHour = () => {
      this.setState({ loading: true });
      this.props.retrieveUserHours(this.state.date)
          .then(callback => {
              this.setState({
                  user_hours: callback.payload.data,
                  loading: false
              })
          })
  }

  updateDate = (date) => {
        const week_index = moment(date).startOf('week').diff(moment().startOf('week'),'week')

        this.setState({
            date,
            week_index
        }, () => {
            this.checkHours();
            this.updateUserHour();
        });
  };

  renderEmployeeSelect(){
      const all_employees = this.props.users.index.map(user => ({ label: user.firstname + ' ' + user.lastname, value: user.id }))

      all_employees.unshift({ label: 'Tous les employés', value: 0 })

      return (
          <Select
              name="type"
              label=""
              required
              value={this.state.current_employee}
              options={all_employees}
              onChange={current_employee => this.setState({
                  current_employee: parseInt(current_employee)
              }, () => this.updateDate(this.state.date))}
          />
      )
  }

  renderEmployeeCheck(state){
      return (
          !state.loading
              ? (
                  <div>
                      {
                          this.state.check_hours && this.state.check_hours.map((check_hour) => (
                              <div>
                                  {check_hour.employee} : {check_hour.total / 100} jours comptabilisé
                              </div>
                          ))
                      }
                  </div>
              )
              : null
      )
  }


  renderWeekHeader(state) {
      const monday = moment(state.date).startOf('week').format('DD MMMM'),
           thuesday = moment(state.date).startOf('week').add(1,'day').format('DD MMMM'),
           wednesday = moment(state.date).startOf('week').add(2,'day').format('DD MMMM'),
           thursday = moment(state.date).startOf('week').add(3,'day').format('DD MMMM'),
           friday = moment(state.date).startOf('week').add(4,'day').format('DD MMMM');

      return (
          <tr>
              <th scope="col">#</th>
              <th scope="col"><i className={'fa fa-arrow-left'} style={{cursor:'pointer'}} onClick={ () => {this.updateDate(moment(state.date).startOf('week').add(-7,'day').format("YYYY-MM-DD"))} }/> Lundi {monday}</th>
              <th scope="col">Mardi {thuesday}</th>
              <th scope="col">Mercredi {wednesday}</th>
              <th scope="col">Jeudi {thursday}</th>
              <th scope="col">Vendredi {friday} <i className={'fa fa-arrow-right'}  style={{cursor:'pointer'}} onClick={ () => {this.updateDate(moment(state.date).startOf('week').add(7,'day').format("YYYY-MM-DD"))} }/> </th>
          </tr>
      )
  }

  renderWeekTable = (state) => {
      let users = this.props.users ? this.props.users.index : []

      if (state.current_employee !== '0')
          users = this.props.users.index.filter(user => parseInt(user.id) === state.current_employee)

      return (
          state.missions &&
          state.missions.map(mission => {
              return (
                  <tr>
                      <th scope="row">
                          <a className={'no-style'} href={'/missions/' + mission.id} target={'_blank'}>{ mission.name }</a>
                          <br/>
                          <a className={'no-style'} href={'/customers/' + mission.customer.id} target={'_blank'}>{ mission.customer.name }</a>
                      </th>
                      {
                          !state.loading && [0, 1, 2, 3, 4].map(day => (
                              <td>
                                  {
                                      users.map(user =>  (
                                          <div className={'mt-2'}>
                                              <label style={{width:80}}>{user.firstname}</label>
                                              <UserHourInput
                                                  date={state.date}
                                                  day={day}
                                                  user={user.id}
                                                  mission={mission.id}
                                                  initialValue={state.user_hours && state.user_hours[mission.id] && state.user_hours[mission.id][day] && state.user_hours[mission.id][day][user.id] ? state.user_hours[mission.id][day][user.id] : '' }
                                              />
                                          </div>
                                      ))
                                  }
                              </td>
                          ))
                      }
                  </tr>
              )
          })
      )
  }

  render() {
       return (
           <div>
               <h2>Timesquare</h2>
               <div className={'row'}>
                   <div className={'col col-4'}>
                       <Input type="date"
                              label={"Date"}
                              value={this.state.date}
                              onChange={this.updateDate}
                       />
                       <SelectSearch
                           name={'mission_type'}
                           options={[
                               { value: 'active_projects', label: 'Projets actifs' },
                               { value: 'recurrent_projects', label: 'Projets récurrents' },
                               { value: 'internal', label: 'Codes internes Bluesquare' },
                               { value: 'old_projects', label: 'Anciens projets' },
                               { value: 'pitch', label: 'Pitch' }
                           ]}
                           onChange={mission_type => this.setState({ mission_type }, () => this.updateMissions())}
                           value={this.state.mission_type}
                       />
                   </div>
                   <div className={'col col-4'}>
                       { this.props.users.index ? this.renderEmployeeSelect() : null}
                   </div>

                   <div className={'col col-4'}>
                       {this.renderEmployeeCheck(this.state)}
                   </div>
               </div>
               <table className="table mt-4">
                   <thead className="thead-dark">
                    { this.renderWeekHeader(this.state)}
                   </thead>
                   <tbody>
                        { this.renderWeekTable(this.state) }
                   </tbody>
               </table>
           </div>
       )
  }

}


export default connect(({ auth, users, missions }) => ({ auth, users, missions }), {getUsers, getMissionsofWeek, createUserHour,retrieveUserHours, checkHours})(withRouter(TimesquarePage))