import {request} from '../helpers'


// Fetch all notes

const NOTES_FETCH_ALL = 'NOTES_FETCH_ALL'
const NOTES_FETCH_ALL_SUCCESS = 'NOTES_FETCH_ALL_SUCCESS'
const NOTES_FETCH_ALL_FAIL = 'NOTES_FETCH_ALL_FAIL'

export function getNotes()
{
    return request(NOTES_FETCH_ALL, 'get', '/notes')

}


// Fetch customer's notes

const NOTES_FETCH_CUSTOMER = 'NOTES_FETCH_CUSTOMER'
const NOTES_FETCH_CUSTOMER_SUCCESS = 'NOTES_FETCH_CUSTOMER_SUCCESS'
const NOTES_FETCH_CUSTOMER_FAIL = 'NOTES_FETCH_CUSTOMER_FAIL'

export function getNotesForCustomer(id)
{
    return request(NOTES_FETCH_CUSTOMER, 'get', '/notes/customer/' + id)
}


// Fetch server's notes

const NOTES_FETCH_SERVER = 'NOTES_FETCH_SERVER'
const NOTES_FETCH_SERVER_SUCCESS = 'NOTES_FETCH_SERVER_SUCCESS'
const NOTES_FETCH_SERVER_FAIL = 'NOTES_FETCH_SERVER_FAIL'

export function getNotesForServer(id)
{
    return request(NOTES_FETCH_SERVER, 'get', '/notes/server/' + id)
}


// Fetch server's notes

const NOTES_FETCH_USER = 'NOTES_FETCH_USER'
const NOTES_FETCH_USER_SUCCESS = 'NOTES_FETCH_USER_SUCCESS'
const NOTES_FETCH_USER_FAIL = 'NOTES_FETCH_USER_FAIL'

export function getNotesForUser(id)
{
    return request(NOTES_FETCH_USER, 'get', '/notes/user/' + id)
}


// Create a note

export const NOTES_CREATE_NOTE = 'NOTES_CREATE_NOTE'
export const NOTES_CREATE_NOTE_SUCCESS = 'NOTES_CREATE_NOTE_SUCCESS'
export const NOTES_CREATE_NOTE_FAIL = 'NOTES_CREATE_NOTE_FAIL'

export function createNote(name, type, username, password, link, sticky, customer_id, user_id, server_id)
{
    const data = { name, type, username, password, link, sticky, customer_id, user_id, server_id }
    return request(NOTES_CREATE_NOTE, 'put', '/notes', data, { note: data })
}

// Delete a note

export const NOTES_DELETE_NOTE = 'NOTES_DELETE_NOTE'
export const NOTES_DELETE_NOTE_SUCCESS = 'NOTES_DELETE_NOTE_SUCCESS'
export const NOTES_DELETE_NOTE_FAIL = 'NOTES_DELETE_NOTE_FAIL'

export function deleteNote(id)
{
    return request(NOTES_DELETE_NOTE, 'delete', '/notes/' + id, {}, {id})
}

// Update a note

const NOTES_UPDATE_NOTE = 'NOTES_UPDATE_NOTE'
const NOTES_UPDATE_NOTE_SUCCESS = 'NOTES_UPDATE_NOTE_SUCCESS'
const NOTES_UPDATE_NOTE_FAIL = 'NOTES_UPDATE_NOTE_FAIL'

export function updateNote(id, name, username, password, link, sticky)
{
    const data = { id, name, username, password, link, sticky };
    return request(NOTES_UPDATE_NOTE, 'post', '/notes/' + id, data, {id})
}

// Store

const defaultState = {
    index: [],
    loading: false
};

// Reducer

export default function notesReducer(state = defaultState, action)
{
    let index;

    switch(action.type)
    {
        case NOTES_FETCH_CUSTOMER:
        case NOTES_FETCH_SERVER:
        case NOTES_FETCH_USER:
        case NOTES_CREATE_NOTE:
        case NOTES_UPDATE_NOTE:
        case NOTES_DELETE_NOTE:
        case NOTES_FETCH_ALL:
            return { ...state, loading: true };

        case NOTES_FETCH_CUSTOMER_FAIL:
        case NOTES_FETCH_SERVER_FAIL:
        case NOTES_FETCH_USER_FAIL:
        case NOTES_CREATE_NOTE_FAIL:
        case NOTES_UPDATE_NOTE_FAIL:
        case NOTES_DELETE_NOTE_FAIL:
        case NOTES_FETCH_ALL_FAIL:
            return { ...state, loading: false };

        case NOTES_FETCH_CUSTOMER_SUCCESS:
        case NOTES_FETCH_SERVER_SUCCESS:
        case NOTES_FETCH_USER_SUCCESS:
        case NOTES_FETCH_ALL_SUCCESS:
            index = [...state.index];
            action.payload.data.map((note) => {
                index = index.filter(n => n.id !== note.id);
                index.push(note);
                return null;
            });
            return {
                ...state,
                index,
                loading: false
            };

        // Create note
        case NOTES_CREATE_NOTE_SUCCESS:
            index = [...state.index];
            index.push(action.payload.data);
            return { ...state, index, loading: false };

        // Update a note
        case NOTES_UPDATE_NOTE_SUCCESS:
            index = state.index.filter(( note ) => note.id !== action.meta.previousAction.id);
            index.push(action.payload.data);
            return { ...state, index, loading: false };

        // Delete note
        case NOTES_DELETE_NOTE_SUCCESS:
            index = [...state.index].filter((note) => note.id !== action.meta.previousAction.id);
            return { ...state, index, loading: false }

        default:
            //console.log("Unknown action in notes.js: " + action.type);
    }
    return state;
}
