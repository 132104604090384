import React, {Component} from 'react';
import {ProgressBar} from "react-bootstrap";
import moment from 'moment';
moment.locale('fr');

export default class MissionAnaysis extends Component {

    renderMetric(value, reference){
        let icon = <i className="fad fa-shield-check kpi good-kpi"></i>
        if(value > reference)
            icon = <i className="fal fa-exclamation-circle kpi bad-kpi"></i>
        return (
            <div>
                {icon}
                <br/>
                <span> {value} / {reference}</span>
            </div>
        )

    }

    render() {
        const { mission } = this.props;

        return (
            <div className={'row row-mission-analysis align-items-center'}>
                <div className={'col-md-2 text-center mission-link'}>
                    <a href={'/missions/'+mission.id} target={'_blank'} className={'no-style'}>{mission.name}</a><br/>
                    <a href={'/customers/'+mission.customer.id} target={'_blank'} className={'no-style'}>({mission.customer.name})</a>
                </div>
                <div className={'col-md-2 text-center'}>
                    {mission.sold_days ?
                        this.renderMetric(mission.total_spent_days ,mission.sold_days)
                    :
                        'Nombre de jours vendu non renseigné'
                    }

                </div>
                <div className={'col-md-2 text-center'}>
                    { mission.estimated_days ?
                        this.renderMetric(mission.total_spent_days ,mission.estimated_days)
                        :
                        'Nombre de jours estimés au début de la mission non renseigné'
                    }
                </div>
                <div className={'col-md-2 text-center'}>
                    { mission.remaining_days ?
                        this.renderMetric(mission.remaining_days ,mission.remaining_days_planned)
                        :
                        'Nombre de jours estimés restants à date non renseigné'
                    }
                </div>
                <div className={'col-md-2 text-center'}>
                    { mission.last_day_planned ?
                        <div>
                            {mission.ending_at ?
                                this.renderMetric( moment(mission.last_day_planned.date).format('YYYY-MM-DD'), moment(mission.ending_at.date).format('YYYY-MM-DD'))
                                : 'Aucune deadline rentrée sur la mission'}
                        </div>
                        : 'Aucune heure rentrée dans Timesquare'
                    }
                </div>
                <div className={'col-md-2'}>

                </div>

            </div>
        )
    }
}
