import uniqid from 'uniqid';
import {request} from '../helpers'
import {saveProject} from "./bag";

const BAG_INIT_PROJECT = 'BAG_INIT_PROJECT'

export function initProject(id, projectData = {})
{
    return (dispatch, getState) => {

        const state = getState()
        let project

        if (state.bag.projects && state.bag.projects.length) {
            project = state.bag.projects.find((p) => p.id == id)
        }

        if (!project) {
            project = {
                id,
                name: "Unknown project",

                model: [],
                database: [],
                links: [],


                /*"tech": "symfony",
                "model": [
                    {
                        "type": "Controller",
                        "name": "AuthController",
                        "children": [],
                        "props": {},
                        "id": "5c5b36b0eeeba"
                    },
                    {
                        "type": "Controller",
                        "name": "BlogController",
                        "children": [],
                        "props": {},
                        "id": "5c5b36b0ef1a2"
                    },
                    {
                        "type": "Controller",
                        "name": "UserController",
                        "children": [],
                        "props": {},
                        "id": "5c5b36b0ef81a"
                    },
                    {
                        "type": "Controller",
                        "name": "ArticleController",
                        "children": [],
                        "props": {},
                        "id": "5c5b36b0efae4"
                    },
                    {
                        "type": "Controller",
                        "name": "TagController",
                        "children": [],
                        "props": {},
                        "id": "5c5b36b0efd2f"
                    }
                ],
                "database": [
                    {
                        "type": "Entity",
                        "name": "User",
                        "children": [
                            {
                                "type": "EntityAttribute",
                                "name": "username",
                                "children": [],
                                "props": {
                                    "type": "string",
                                    "nullable": false,
                                    "default": null
                                },
                                "id": "5c5b36b0ef3bd"
                            },
                            {
                                "type": "EntityAttribute",
                                "name": "password",
                                "children": [],
                                "props": {
                                    "type": "string",
                                    "nullable": false,
                                    "default": null
                                },
                                "id": "5c5b36b0ef560"
                            }
                        ],
                        "props": {},
                        "id": "5c5b36b0ef7ac"
                    },
                    {
                        "type": "Entity",
                        "name": "Article",
                        "children": [],
                        "props": {},
                        "id": "5c5b36b0efa81"
                    },
                    {
                        "type": "Entity",
                        "name": "Tag",
                        "children": [],
                        "props": {},
                        "id": "5c5b36b0efccc"
                    }
                ],
                "links": [
                    {
                        "type": "LinkedEntity",
                        "origin": "5c5b36b0ef81a",
                        "target": "5c5b36b0ef7ac",
                        "props": {},
                        "id": "5c5b36b0ef971"
                    },
                    {
                        "type": "LinkedEntity",
                        "origin": "5c5b36b0efae4",
                        "target": "5c5b36b0efa81",
                        "props": {},
                        "id": "5c5b36b0efbc0"
                    },
                    {
                        "type": "LinkedEntity",
                        "origin": "5c5b36b0efd2f",
                        "target": "5c5b36b0efccc",
                        "props": {},
                        "id": "5c5b36b0efe0e"
                    }
                ],*/
            }
        }

        project = {...project, ...projectData};

        let schema = initEditorElements({
            model: project.model,
            database: project.database,
            links: project.links
        });

        console.log('BAG: init project', project);
        console.log('BAG: init schema', schema);

        dispatch({
            type: BAG_INIT_PROJECT,
            project,
            schema
        })
    }
}

export function initEditorElements(schema)
{
    schema.model.map((child, i) => {
        schema.model[i] = initEditorElementsRecursive(child)
    })
    schema.database.map((child, i) => {
        schema.database[i] = initEditorElementsRecursive(child)
    })
    return schema
}

export function initEditorElementsRecursive(data)
{
    data._editor = {
        x: 100 + (Math.floor(Math.random() * 400) + 0),
        y: 100 + (Math.floor(Math.random() * 200) + 0),
        width: 200,
        height: 100,
        ...data._editor
    }

    data.children.map((child, i) => {
        data.children[i] = initEditorElementsRecursive(child)
    })

    return data
}

export function insertElement(board, parent_id, data, _editor, onCreate)
{
    return (dispatch, getState) => {

        const element = {
            id: uniqid(),
            ...data,
            _editor,
            children: [],
            props: {}
        }

        let specs = getState().bag_editor.specifications

        if (specs) {
            if (specs[element.type] && specs[element.type].props) {
                specs[element.type].props.map((prop) => {
                    element.props[prop.name] = null
                    if (typeof prop.default !== 'undefined') {
                        element.props[prop.name] = prop.default
                    }
                    else if (prop.type == 'select' && prop.options.length) {
                        element.props[prop.name] = prop.options[0]
                    }
                })
            }
        }

        let schema = getState().bag_editor.schema
        let board_data = schema[board]

        if (parent_id == null) {
            board_data.push(element)
            schema[board] = board_data
        }
        else {
            board_data = insertElementRecursive({ children: board_data }, parent_id, element)
            schema[board] = board_data.children
        }

        dispatch(updateSchema(schema))

        onCreate(element)
    }
}

function insertElementRecursive(data, parent_id, element)
{
    data.children.map((child, key) => {
        if (child.id == parent_id) {
            data.children[key].children.push(element)
        }
        else {
            data.children[key] = insertElementRecursive(data.children[key], parent_id, element)
        }
    })
    return data
}

export function updateElement(board, element)
{
    return (dispatch, getState) => {
        let schema = getState().bag_editor.schema

        let data = { children: schema[board] }
        updateElementRecursive(data, element)
        schema[board] = data.children
        return dispatch(updateSchema(schema))
    }
}

function updateElementRecursive(data, element) {
    data.children.map((child, i) => {
        if (child.id == element.id) {
            data.children[i] = {...child, ...element}
        }
        else updateElementRecursive(child, element)
    })
}

export function deleteElement(board, id)
{
    return (dispatch, getState) => {
        let schema = getState().bag_editor.schema
        let data = { children: schema[board] }

        if (board != 'links')
        {
            schema.links = schema.links.filter((l) => {
                return l.origin != id && l.target != id
            })
        }

        deleteElementRecursive(data, id)
        schema[board] = data.children

        return dispatch(updateSchema(schema))
    }
}

function deleteElementRecursive(data, id) {
    console.log(data, id)
    if (data.children) data.children.map((child, i) => {
        if (child.id == id) {
            data.children = data.children.filter((child) => child.id != id)
        }
        else deleteElementRecursive(child, id)
    })
}

const BAG_UPDATE_SCHEMA = 'BAG_UPDATE_SCHEMA'

export function updateSchema(schema)
{
    return {
        type: BAG_UPDATE_SCHEMA,
        schema
    }
}

const BAG_START_LINKING = 'BAG_START_LINKING'

export function startLinking(link)
{
    return {
        type: BAG_START_LINKING,
        link
    }
}

const BAG_STOP_LINKING = 'BAG_STOP_LINKING'

export function stopLinking()
{
    return {
        type: BAG_STOP_LINKING
    }
}

const BAG_CREATE_LINK = 'BAG_CREATE_LINK'

export function createLink(target)
{
    return (dispatch, getState) => {
        const state = getState().bag_editor

        let schema = {...state.schema}

        let link = {
                "type": state.linking.type,
                "origin": state.linking.originId,
                "target": target.id,
                "props": {},
                "id": uniqid()
        }

        schema.links.push(link)

        dispatch({
            type: BAG_CREATE_LINK,
            schema
        })
    }
}

const BAG_FETCH_SPECS = 'BAG_FETCH_SPECS'
const BAG_FETCH_SPECS_SUCCESS = 'BAG_FETCH_SPECS_SUCCESS'
const BAG_FETCH_SPECS_FAIL = 'BAG_FETCH_SPECS_FAIL'

export function fetchSpecs(tech)
{
    return request(BAG_FETCH_SPECS, 'post', `/bag/proxy?tool=tech&method=specs&tech=${encodeURIComponent(tech)}`, {})
}

const BAG_FETCH_TECHS = 'BAG_FETCH_TECHS'

export function fetchTechs()
{
    return request(BAG_FETCH_TECHS, 'post', '/bag/proxy?tool=tech&method=index')
}

const BAG_TRANSLATE_SCHEMA = 'BAG_TRANSLATE_SCHEMA'

export function translateSchema(tech, data)
{
    return request(BAG_TRANSLATE_SCHEMA, 'post', '/bag/proxy?tool=translator&method=translate&tech=' + encodeURIComponent(tech), data)
}

const BAG_GENERATE_CODE = 'BAG_GENERATE_CODE'

export function generateCode(tech, data)
{
    return request(BAG_GENERATE_CODE, 'post', '/bag/proxy?tool=transcriber&method=transcribe&tech=' + encodeURIComponent(tech), data)
}

// Store

const defaultState = {
    project: null,
    schema: null,
    specifications: null,

    linking: null
}

// Reducer

export default function bagEditorReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case BAG_INIT_PROJECT:
            return {...state, project: action.project, schema: action.schema, specifications: null}
        case BAG_UPDATE_SCHEMA:
            return {...state, schema: action.schema}
        case BAG_FETCH_SPECS_SUCCESS:
            return {...state, specifications: action.payload.data}
        case BAG_START_LINKING:
            return {...state, linking: action.link}
        case BAG_STOP_LINKING:
            return {...state, linking: null}
        case BAG_CREATE_LINK:
            return {...state, linking: null, schema: action.schema}
    }
    return state
}
