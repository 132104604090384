import FormProvider from "../FormProvider";
import Input from "../Input";
import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {createCustomer} from "../../data/customers";
import {getUsers, getContacts} from "../../data/users";
import Radios from "../Radios";
import Modal from '../Modal';
import SelectSearch from "react-select";
import Checkbox from "../Checkbox";
import {getCustomerCountryOptions, getCustomerCurrencyOptions} from "../../helpers";

class CustomerAddModal extends Component
{
    state = {
        type: 'company',
        company_name: '',
        company_phone: '',
        address: '',
        siret: '',
        firstname: '',
        lastname: '',
        gender: '',
        skype: '',
        phone: '',
        email: '',
        country: { label: 'France', value: 'France' },
        company_shape: '',
        social_capital: '',
        social_capital_currency: {},
        rcs_number: '',
        rcs_country: '',
        errors: null,
        creating: false,
        existingUser: false,
        user_id: null,
        user: null
    };

    componentDidMount()
    {
        this.props.getContacts()
            .then((res) => {
                this.setState({
                    users: res.payload.data
                });
            });
    }

    create = (e) => {
        e.preventDefault();

        this.setState({ creating: true });
        let { user_id, country, social_capital_currency } = this.state;

        this.props.createCustomer({
            ...this.state,
            user_id: user_id ? user_id.value : null,
            country: country.value,
            social_capital_currency: social_capital_currency.value
        })
            .then((data) => {
                this.props.onClose(true);
            })
            .catch((res) => {
                this.setState({ errors: res.error.response.data.errors , creating : false })
            });

        return false;
    };

    render = () => {
        return(
            <Modal title={"Add a customer"} onClose={() => this.props.onClose(false)}>
                {this.renderForm()}
            </Modal>
        )
    };

    renderForm() {
        return (
            <FormProvider onSubmit={this.create} errors={this.state.errors}>
                <Radios
                    value={this.state.type}
                    onChange={(type) => this.setState({ type })}
                    options={[
                        { label: 'Company', value: 'company' },
                        { label: 'Individual', value: 'individual' },
                    ]}
                    label="Type *"
                    name="Type"
                />
                {this.state.type === "company" ? (this.renderCompanyFields()) : "" }
                {this.renderIndividualFields()}
                <Input
                    type={"text"}
                    name={"address"}
                    id={"address"}
                    placeholder={"Address"}
                    value={this.state.address}
                    onChange={(address) => this.setState({address})}
                    label={"Address"}
                />
                <Input
                    type={"text"}
                    name={"siret"}
                    id={"siret"}
                    placeholder={"Siret"}
                    value={this.state.siret}
                    onChange={(siret) => this.setState({siret})}
                    label={"Siret"}
                />
                <div className="form-group">
                    <SelectSearch
                        name={"country"}
                        placeholder="Select a Country"
                        options={getCustomerCountryOptions().map(c => ({ label: c.nom_fr_fr, value: c.nom_fr_fr }))}
                        value={this.state.country}
                        onChange={country => this.setState({ country })}
                    />
                </div>
                <Input
                    type={"text"}
                    name={"company_shape"}
                    id={"company_shape"}
                    placeholder={"Company Shape"}
                    value={this.state.company_shape}
                    onChange={company_shape => this.setState({ company_shape })}
                    label={"Company Shape"}
                />
                <Input
                    type={"text"}
                    name={"social_capital"}
                    id={"social_capital"}
                    placeholder={"Social Capital"}
                    value={this.state.social_capital}
                    onChange={social_capital => this.setState({ social_capital })}
                    label={"Social Capital"}
                />
                <div className="form-group">
                    <SelectSearch
                        name={"social_capital_currency"}
                        placeholder="Select a Capital Currency"
                        options={getCustomerCurrencyOptions().map(c => ({ label: c, value: c }))}
                        value={this.state.social_capital_currency}
                        onChange={social_capital_currency => this.setState({ social_capital_currency })}
                    />
                </div>
                <Input
                    type={"text"}
                    name={"rcs_number"}
                    id={"rcs_number"}
                    placeholder={"RCS Number"}
                    value={this.state.rcs_number}
                    onChange={rcs_number => this.setState({ rcs_number })}
                    label={"RCS Number"}
                />
                <Input
                    type={"text"}
                    name={"rcs_country"}
                    id={"rcs_country"}
                    placeholder={"RCS Country"}
                    value={this.state.rcs_country}
                    onChange={rcs_country => this.setState({ rcs_country })}
                    label={"RCS Country"}
                />
                <button className="btn btn-primary btn-block" disabled={this.state.creating}>Add Customer</button>
            </FormProvider>
        )
    }

    renderCompanyFields = () => {
        return (
            <div>
                <Input required autoFocus
                       type={"text"}
                       name={"company_name"}
                       id={"company_name"}
                       placeholder={"Siret"}
                       value={this.state.company_name}
                       onChange={(company_name) => this.setState({company_name})}
                       label={"Company name"}
                />
                <Input
                    type={"text"}
                    name={"company_phone"}
                    id={"company_phone"}
                    placeholder={"Siret"}
                    value={this.state.company_phone}
                    onChange={(company_phone) => this.setState({company_phone})}
                    label={"Company phone"}
                />
            </div>
        )
    };

    renderIndividualFields = () => {
        return (
            <fieldset className="bordered">
                <legend>{this.state.type === "company" ? "Primary contact" : "Personal informations" }</legend>
                <Checkbox
                    value={this.state.existingUser}
                    onChange={(existingUser) => this.setState({existingUser, user_id: existingUser ? this.state.user_id : null}) }
                    label={"Create from existing user"}
                />
                <hr/>
                {
                    this.state.existingUser &&
                    this.state.users
                    ? (
                        <SelectSearch required
                                      name={"existingUser"}
                                      label={"Please choose a user *"}
                                      value={this.state.user_id}
                                      onChange={this.state.existingUser ? ((user_id) => this.setState({user_id})) : null}
                                      options={this.state.users.map((user) => ({label:user.email, value:user.id}) )}
                        />
                    )
                    : null
                }
                { this.state.existingUser ? null : this.renderFieldsUserNotExisting() }
            </fieldset>
        )
    }

    renderFieldsUserNotExisting = () => {
        return (
            <div>
                <Radios
                    value={this.state.gender}
                    onChange={(gender) => this.setState({ gender })}
                    options={[
                        { label: 'Male', value: 'M' },
                        { label: 'Female', value: 'F' },
                        { label: 'None', value: 'N' },
                    ]}
                    label="Gender *"
                    name="Gender"
                />
                <div className={"row"}>
                    <div className={"col"}>
                        <Input required
                               type={"text"}
                               name={"firstname"}
                               id={"firstname"}
                               placeholder={"Firstname *"}
                               value={this.state.firstname}
                               onChange={(firstname) => this.setState({firstname})}
                               label={"Firstname *"}
                        />
                    </div>
                    <div className={"col"}>
                        <Input required
                               type={"text"}
                               name={"lastname"}
                               id={"lastname"}
                               placeholder={"Lastname *"}
                               value={this.state.lastname}
                               onChange={(lastname) => this.setState({lastname})}
                               label={"Lastname *"}
                        />
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <Input required
                               type={"text"}
                               name={"email"}
                               id={"email"}
                               placeholder={"E-mail address *"}
                               value={this.state.email}
                               onChange={(email) => this.setState({email})}
                               label={"E-mail address *"}
                        />
                    </div>
                    <div className={"col"}>
                        <Input
                            type={"text"}
                            name={"skype"}
                            id={"skype"}
                            placeholder={"Skype"}
                            value={this.state.skype}
                            onChange={(skype) => this.setState({skype})}
                            label={"Skype"}
                        />
                    </div>
                </div>
                <Input
                    type={"text"}
                    name={"phone"}
                    id={"phone"}
                    placeholder={"Phone"}
                    value={this.state.phone}
                    onChange={(phone) => this.setState({phone})}
                    label={"Phone"}
                />
            </div>
        )
    }
}

CustomerAddModal.defaultProps = {
    onClose: () => {},
    // customerId: null
};

export default connect(({users}) => ({users}), { createCustomer, getUsers, getContacts })(CustomerAddModal)
