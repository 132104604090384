import uniqid from 'uniqid';

const localStorage = window.localStorage
let projects = localStorage.getItem('bag')

if (projects) {
    projects = JSON.parse(projects)
    if (!Array.isArray(projects)) {
        projects = []
        localStorage.setItem('bag', JSON.stringify([]))
    }
}
else {
    projects = []
}

// Actions

const BAG_CREATE_PROJECT = 'BAG_CREATE_PROJECT'

export function createProject(name)
{
    return (dispatch, getState) => {
        let project = { id: uniqid(), name, model: [], database: [], links: [] }
        let projects = [...getState().bag.projects]
        projects.push(project)
        localStorage.setItem('bag', JSON.stringify(projects))
        dispatch({ type: BAG_CREATE_PROJECT, project })
    }
}

const BAG_SAVE_PROJECT = 'BAG_SAVE_PROJECT';

export function saveProject(id, project, download = true)
{
    return (dispatch, getState) => {
        let projects = [...getState().bag.projects];

        const sEncodedProject = "data:application/json," + encodeURIComponent(JSON.stringify(project));
        const sDownloadFilename = project.name + "-" + (project.tech ? project.tech : "concept") + ".json";
        let found = false;

        projects.every((p, index, arr) => {
            if (p.id === project.id)
            {
                arr[index] = project;
                found = true;
                return false;
            }
            return true;
        });

        if (!found) {
            projects.push(project);
        }

        const sAllProjects = JSON.stringify(projects);

        // Save all projects in local storage
        localStorage.setItem('bag', sAllProjects);

        if (download)
        {
            // Trigger download of the single project we are working on
            let element = document.createElement('a');
            element.setAttribute('href', sEncodedProject);
            element.setAttribute('download', sDownloadFilename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }


        // Dispatch event
        dispatch({ type: BAG_SAVE_PROJECT, projects })
    }
}

// Store

const defaultState = {
    projects: projects,
    loading: false,
    lastUpdate: null
};

// Reducer

export default function bagReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case BAG_CREATE_PROJECT:
            return {...state, projects: [...state.projects, action.project]}
        case BAG_SAVE_PROJECT:
            return {...state, projects: action.projects}
    }
    return state
}
