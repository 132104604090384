import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from "../Modal";
import ContentLoader from "../ContentLoader";
import {getRolesIndex,getUsersIndex,getUsersList,getRolesList,grantTo,removePrivilege} from '../../data/privileges';
import Empty from "../Empty";
import Loader from "../Loader";
import Select from "../Select";

class PrivilegesModal extends Component {
    state = {
        users: null,
        roles: null,
        indexUsers: null,
        indexRoles: null,
        user: '',
        role: ''
    }

    componentDidMount()
    {
        this.props.getRolesIndex().then((res) => {
            this.setState({ indexRoles: res.payload.data }, () => {
                if (this.state.indexRoles.length) this.setState({ role: this.state.indexRoles[0].id })
            })
        })
        this.props.getUsersIndex().then((res) => {
            this.setState({ indexUsers: res.payload.data }, () => {
                if (this.state.indexUsers.length) this.setState({ user: this.state.indexUsers[0].id })
            })
        })
        this.props.getRolesList(this.props.type, this.props.id).then((res) =>{
            this.setState({ roles: res.payload.data })
        })
        this.props.getUsersList(this.props.type, this.props.id).then((res) => {
            this.setState({ users: res.payload.data })
        })
    }

    _addUser = () => {
        this.props.grantTo('User', this.state.user, this.props.type, this.props.id)
            .then((res) => {
                const users = this.state.users
                users.push({
                    entity_label: res.payload.data.label,
                    id: res.payload.data.id
                })
                this.setState({ users })
            })
    }

    _deleteUser = (id) => {
        this.props.removePrivilege(id)
            .then(() => this.setState({ users: this.state.users.filter((u) => u.id !== id) }))
    }

    _addRole = () => {
        this.props.grantTo('Role', this.state.role, this.props.type, this.props.id)
            .then((res) => {
                const roles = this.state.roles
                roles.push({
                    entity_label: res.payload.data.label,
                    id: res.payload.data.id
                })
                this.setState({ roles })
            })
    }

    _deleteRole = (id) => {
        this.props.removePrivilege(id)
            .then(() => this.setState({ roles: this.state.roles.filter((u) => u.id !== id) }))
    }

    render() {
        return (
            <Modal title="Privileges" onClose={() => this.props.onClose()}>
                <p style={{ fontSize: 14, color: 'red'}}>Note: pour l'instant ne pas se fier aux "inherit" + certaines personnes ou certains rôles peuvent certainement accéder à cette ressource s'ils ont les droits sur l'élément parent (exemple: droits sur un customer => droits sur ses serveurs)</p>
                    {this.props.id ? (
                        <p style={{ fontSize: 12 }}>The following roles and users will be allowed to access this particular {this.props.type} (id: {this.props.id}).</p>
                    ) : (
                        <p style={{ fontSize: 12 }}>The following roles and users will be allowed to access all {this.props.type} in this context.</p>
                    )}
                    <fieldset>
                        <legend>Roles</legend>
                        {this.renderRoles()}
                        {this.renderRolesForm()}
                    </fieldset>
                    <fieldset className="mt-2">
                        <legend>Users</legend>
                        {this.renderUsers()}
                        {this.renderUsersForm()}
                    </fieldset>
            </Modal>
        )
    }

    renderRoles() {
        if (!this.state.roles) return <ContentLoader/>
        if (!this.state.roles.length) return null
        return (
            <ul>
                {this.state.roles.map((role) => (
                    <li>
                        <span className={role.inherit ? 'text-muted' : ''}>{role.entity_label}</span>
                        {role.inherit ? (
                            <span className="text-muted">(inherit)</span>
                        ) : (
                            <span onClick={() => this._deleteRole(role.id)}><i className="fas fa-trash"/></span>
                        )}
                    </li>
                ))}
            </ul>
        )
    }

    renderRolesForm() {
        if (!this.state.indexRoles) return <div><Loader display/></div>

        return (
            <div className="mb-2">
                <Select
                    options={this.state.indexRoles.map((role) => ({
                        label: role.label,
                        value: role.id
                    }))}
                    label={"Grant a role"}
                    value={this.state.role}
                    onChange={(role) => this.setState({ role })}
                />
                <button onClick={() => this._addRole()} className="btn btn-primary">Grant {this.state.role}</button>
            </div>
        )
    }

    renderUsers() {
        if (!this.state.users) return <ContentLoader/>
        if (!this.state.users.length) return null
        return (
            <ul>
                {this.state.users.map((user) => (
                    <li>
                        <span className={user.inherit ? 'text-muted' : ''}>{user.entity_label}</span>
                        {user.inherit ? (
                            <span className="text-muted">(inherit)</span>
                        ) : (
                            <span onClick={() => this._deleteUser(user.id)}><i className="fas fa-trash"/></span>
                        )}
                    </li>
                ))}
            </ul>
        )
    }

    renderUsersForm() {
        if (!this.state.indexUsers) return <div><Loader display/></div>

        return (
            <div>
                <Select
                    options={this.state.indexUsers.map((user) => ({
                        label: user.label,
                        value: user.id
                    }))}
                    label={"Grant a user"}
                    value={this.state.user}
                    onChange={(user) => this.setState({ user })}
                />
                <button onClick={() => this._addUser()} className="btn btn-primary">Grant {this.state.user}</button>
            </div>
        )
    }
}

PrivilegesModal.defaultProps = {
    id: null,
    type: ''
}

export default connect(null, {getRolesIndex,getUsersIndex,getUsersList,getRolesList,grantTo,removePrivilege})(PrivilegesModal)
