import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getUsers} from "../data/users";
import {createUserHour, retrieveUserHours, checkHours, getStaffingHours,setStaffingHours,deleteThisStaffing} from "../data/userHours";
import {getMissionsofWeek, getMissions} from "../data/missions";
import {withRouter} from "react-router-dom";
import Input from "../components/Input";
import {ProgressBar} from "react-bootstrap";
import moment, {now} from "moment";
import Select from "../components/Select";
import SelectSearch from "react-select";
moment.locale('fr');


class StaffingPage extends Component
{
    state = {
        week_index: 0,
        loading: false,
        check_hours: [],
        missions: [],
        all_missions: [],
        months: [
            moment().add(-2, 'M').startOf('month'),
            moment().add(-1, 'M').startOf('month'),
            moment().startOf('month'),
            moment().add(1, 'M').startOf('month'),
            moment().add(2, 'M').startOf('month'),
        ],
        working_days: [],
        user_hours: null,
        action: 'create',
        user: 0,
        mission: {value: 0},
        begin: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),

    }


    updateUserHours = () => {
        this.props.getStaffingHours().then( (callback) => {
            this.setState({user_hours: callback.payload.data.hours})
            this.setState({working_days: callback.payload.data.working_days})
            this.setState({loading:false});
        })
    }

    componentDidMount() {
        this.props.getUsers(true)
        this.props.getMissionsofWeek(0,true).then( (callback) => {
            this.setState({missions: callback.payload.data})
        });
        this.props.getMissions().then((res) => {
            this.setState({
                all_missions: res.payload.data
            });
        });
        this.updateUserHours();

    }


    setStaffing = () => {
        this.setState({loading:true})
        this.props.setStaffingHours(this.state.user,this.state.mission.value,this.state.action,this.state.begin,this.state.end).then(() => {
            this.updateUserHours();
        })
    }

    deleteThisStaffing  = (userId, missionId, monthNumber) => {
        this.setState({loading:true})
        this.props.deleteThisStaffing(userId, missionId, monthNumber).then(() => {
            this.updateUserHours();
        });
    }

    formatStaffingCell = (user,monthNumber) => {
        let totalDays = 0
        return (
            <div>
                {
                    this.state.user_hours
                        ? this.state.all_missions.map(mission => {
                            let missionTime = (this.state.user_hours[mission.id][monthNumber][user.id] )
                            let progress = missionTime / this.state.working_days[monthNumber]
                            totalDays += Math.round(missionTime) / 100;

                            return (
                                <>
                                {
                                    missionTime > 0
                                        ? <div className={'mb-3'}>
                                            { mission.name } : {Math.round(missionTime) / 100 } j &nbsp;
                                            {
                                                monthNumber >= 0
                                                ? <i className={'fa fa-times'}
                                                     style={{color: 'darkred', cursor: 'pointer'}}
                                                     onClick={ () => {this.deleteThisStaffing( user.id, mission.id, monthNumber)}}
                                                    />
                                                : null
                                            }
                                            { this.state.user_hours && this.state.working_days ? <ProgressBar now={ progress } label={`${Math.round(progress)}%`} />  : null }
                                        </div>

                                    : null}
                                </>
                            )})
                        : null
                }

                Total : {totalDays} j
                {
                    this.state.working_days
                        ? (
                            <ProgressBar
                                variant="warning"
                                now={ totalDays / this.state.working_days[monthNumber] * 100 }
                                label={ `${Math.round(totalDays / this.state.working_days[monthNumber] * 100)}%`}
                            />
                        )
                        : null
                }
            </div>
        )
    }

    renderEmployeeStaffing(user){
        return (
            <tr style={{borderBottom: '1px solid'}}>
                <th scope="row">
                    {user.firstname} {user.lastname}
                </th>
                    {
                        this.state.months.map( (month,monthNumber) => (
                            <td>
                                {this.formatStaffingCell(user,monthNumber-2)}
                            </td>
                        ))
                    }
            </tr>
        )
    }

    renderMissionOptions(missions)
    {
        return missions.map(mission => ({ value : mission.id, label : mission.customer.name + " : " + mission.name}));
    }

    setStaffingHeader = () => {
        const all_employees = this.props.users.index.map( (user) => ({ label: user.firstname + ' ' + user.lastname, value: user.id }))
        all_employees.unshift({ label: 'Aucun employé sélectionné', value: 0 })

        return (
            <div className={'row'}>
                <div className={'col-md-4'}>
                    <Input type="date"
                           label={"Begin"}
                           value={this.state.begin}
                           onChange={(begin) => {this.setState({begin})}}
                    />
                    <Input type="date"
                           label={"End"}
                           value={this.state.end}
                           onChange={(end) => {this.setState({end})}}
                    />
                </div>
                <div className={'col-md-4'}>
                    <Select name="employee"
                            label=""
                            required
                            value={this.state.user}
                            options={
                                all_employees
                            }
                            onChange={(user) => this.setState({ user })}
                    />
                    <SelectSearch
                        name={"mission"}
                        value={this.state.mission}
                        options={this.renderMissionOptions(this.state.missions)}
                        placeholder="Select a Mission"
                        searchable={false}
                        onChange={(d) => {this.setState({mission: d})}}
                    />
                </div>
                <div className={'col-md-4'}>
                    <Select name="type"
                            label=""
                            required
                            value={this.state.action}
                            options={
                                [
                                    { label: 'Effacer', value:'delete'},
                                    { label: 'Ajouter', value:'create'},
                                ]
                            }
                            onChange={(action) => this.setState({ action })}
                    />
                    {
                        !this.state.loading
                            ? <button className={'btn btn-info'} onClick={this.setStaffing}> Go </button>
                            : null
                    }
                </div>
            </div>
        )
    }

    renderTable = () => {
        return (
            <tbody>
                { this.props.users && this.props.users.index.map(user => this.renderEmployeeStaffing(user)) }
            </tbody>
        )
    }

    render() {
        return (
            <div>
                <h2>Staffing</h2>
                {this.setStaffingHeader()}
                <table className={'table staffing'}>
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            {
                                this.state.months.map((month, key) => (
                                    <th scope="col">
                                        { month.format('MMMM') }
                                        <br/>
                                        { this.state.working_days[key-2] } j ouvrés
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    { !this.state.loading ? this.renderTable() : null }
                </table>
            </div>
        )
    }

}

export default connect(({ users, missions }) => ({ users, missions }), {getUsers, getMissions,getMissionsofWeek, createUserHour,retrieveUserHours, checkHours, getStaffingHours, setStaffingHours,deleteThisStaffing})(withRouter(StaffingPage))