import React, {Component} from 'react';
import {getContextPath} from "../../helpers";

export default class Menu extends Component
{

    render() {
        const path = getContextPath(this.props.context, this.props.board, this.props.data)

        return (
            <header className="toolbar">
                <ul className="breadcrumb pull-left">
                    {path.map((context, i) => {
                        if (path.length - 1 === i) {
                            return (
                                <li key={context.id}
                                    className="active">{this.renderBreadcrumbElementLabel(context)}</li>
                            )
                        }
                        return (
                            <li key={context.id}>
                                <a href="#"
                                   onClick={() => this.props.onClickContext(context.id)}>
                                    {this.renderBreadcrumbElementLabel(context)}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </header>
        )
    }

    renderBreadcrumbElementLabel = (context) => {
        if (context.name == context.type) {
            return (
                <strong>{context.name}</strong>
            )
        }
        return (
            <span>
				<strong>{context.type}</strong> {context.name}
			</span>
        )
    }
}