import React, {Component} from 'react';
import Modal from "../Modal";
import {deleteMissionCalendar} from '../../data/missionCalendars';
import {connect} from 'react-redux';

class MissionCalendarDeleteModal extends Component
{
    state = {
        deleting: false
    };

    delete() {
        this.setState({ deleting: true });

        this.props.deleteMissionCalendar(this.props.id)
            .then(() => {
                this.deleted();
            })
            .catch((e) => {
                this.setState({ deleting: false })
            })
    }

    deleted() {
        this.props.onClose(true);
    }

    render() {
        return(
            <Modal title="Delete the calendar" onClose={this.props.onClose}>
                <p className={"text-center mb-4"}>Are you sure you want to delete this calendar ?</p>
                <button className="btn btn-danger float-right" disabled={this.state.deleting} onClick={() => this.delete()}>Yes I'm sure</button>
                <button className="btn btn-primary" disabled={this.state.deleting} onClick={() => this.props.onClose()}>Please don't, I'm full of regrets</button>
            </Modal>
        )
    }
}

export default connect(null, {deleteMissionCalendar})(MissionCalendarDeleteModal);
