import React, {Component} from 'react';
import Modal from "../Modal";
import Input from "../Input";
import FormProvider from "../FormProvider";
import {connect} from "react-redux";
import {updateMission} from "../../data/missions";
import moment from "moment";
import Select from "../Select";
import {getMissionStatusOptions} from "../../helpers";
class MissionUpdateModal extends Component
{
    state = {
        id: this.props.data.id,
        name: this.props.data.name,
        status: this.props.data.status,
        sold_days: this.props.data.sold_days,
        estimated_days: this.props.data.estimated_days,
        remaining_days: this.props.data.remaining_days,
        amount: this.props.data.amount,
        starting_at: this.props.data.starting_at ? moment(this.props.data.starting_at.date).format('YYYY-MM-DD') : null,
        ending_at: this.props.data.ending_at ? moment(this.props.data.ending_at.date).format('YYYY-MM-DD') : null,
        additional_costs : this.props.data.additional_costs,
        cost_per_day : this.props.data.cost_per_day,
        hide_days: this.props.data.hide_days,
        errors: null,
        updating: false
    };

    update = (e) => {
        let {id, name, status, starting_at, ending_at, sold_days, amount, additional_costs, cost_per_day,hide_days, estimated_days, remaining_days} = this.state;

        e.preventDefault();
        this.setState({ updating: true });

        this.props.updateMission(id, name, status, starting_at, ending_at, sold_days, amount,additional_costs, cost_per_day,hide_days, estimated_days, remaining_days)
            .then(() => {
                this.props.onClose(true);
            })
            .catch((res) => {
                if (res.error) this.setState({ errors: res.error.response.data.errors });
                this.setState({ updating: false });
            });

        return false;
    };

    render = () => {
        return (
            <Modal title="Update a mission" onClose={() => this.props.onClose(false)}>
                {this.renderForm()}
            </Modal>
        )
    };

    renderForm = () => {
        return (
            <FormProvider onSubmit={this.update} errors={this.state.errors}>
                <div className="row">
                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"name"}
                               id={"name"}
                               placeholder={"Mission name *"}
                               value={this.state.name}
                               onChange={(name) => this.setState({name})}
                               label={"Mission name *"}/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <label htmlFor="modal-status">Status *</label>
                        <Select name="status"
                                label=""
                                required
                                value={this.state.status}
                                options={getMissionStatusOptions()}
                                onChange={(status) => this.setState({ status })} />
                    </div>

                    <div className="col">
                        <label htmlFor="modal-status">Cacher les jours sur le devis</label>
                        <Select name="status"
                                label=""
                                value={this.state.hide_days}
                                options={[
                                    { label: 'Montrer les jours', value: 0 },
                                    { label: 'Cacher les jours', value: 1 },
                                    { label: 'Uniquement prix global', value: 2 },
                                ]}
                                onChange={(hide_days) => this.setState({ hide_days })} />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"cost_per_day"}
                               id={"cost_per_day"}
                               placeholder={"Coûts en € par jour de travail"}
                               value={this.state.cost_per_day}
                               onChange={(cost_per_day) => this.setState({cost_per_day})}
                               label={"Coûts / j"}/>
                    </div>

                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"additional_costs"}
                               id={"additional_costs"}
                               placeholder={"Coût additionnels (design ...) en €"}
                               value={this.state.additional_costs}
                               onChange={(additional_costs) => this.setState({additional_costs})}
                               label={"Coûts additionnels"}/>
                    </div>
                </div>







                <div className="row mb-3">
                    <div className="col">
                        <label htmlFor="modal-status">Estimated days</label>
                        <input
                            name="estimated_days"
                            type="number"
                            placeholder="0"
                            className="form-control"
                            id="modal-status"
                            onChange={(a) => {this.setState({estimated_days: a.target.value})}}
                            value={this.state.estimated_days}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="modal-status">Sold days *</label>
                        <input required
                               name="sold_days"
                               type="number"
                               placeholder="0"
                               className="form-control"
                               id="modal-status"
                               onChange={(a) => {this.setState({sold_days: a.target.value})}}
                               value={this.state.sold_days}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="modal-status">Remaining days</label>
                        <input
                               name="remaining_days"
                               type="number"
                               placeholder="0"
                               className="form-control"
                               id="modal-status"
                               onChange={(a) => {this.setState({remaining_days: a.target.value})}}
                               value={this.state.remaining_days}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="modal-status">Amount *</label>
                        <input required
                               name="amount"
                               type="number"
                               placeholder="0"
                               className="form-control"
                               id="modal-status"
                               onChange={(a) => {this.setState({amount: a.target.value})}}
                               value={this.state.amount}
                        />
                    </div>
                </div>
                <div className={"row mb-4"}>
                    <div className="col">
                        <label htmlFor="modal-starting-at">Start date</label>
                        <input
                            name="starting_at"
                            type="date"
                            className="form-control"
                            id="modal-starting-at"
                            onChange={(d) => {this.setState({starting_at: moment(d.target.value).format('YYYY-MM-DD')})}}
                            value={this.state.starting_at}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="modal-ending-at">End date</label>
                        <input
                            name="ending_at"
                            type="date"
                            className="form-control"
                            id="modal-ending-at"
                            onChange={(d) => {this.setState({ending_at: moment(d.target.value).format('YYYY-MM-DD')})}}
                            value={this.state.ending_at}
                        />
                    </div>
                </div>
                <button className="btn btn-primary btn-block" disabled={this.state.updating}>Update</button>
            </FormProvider>
        )
    };

}

export default connect(null, { updateMission }) (MissionUpdateModal)
