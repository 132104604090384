import React, {Component} from 'react';
import PwGen from 'generate-password';
import {connect} from 'react-redux';
import Modal from "./Modal";

class ToolsPasswordGenerator extends Component {
    constructor(props) {
        super(props);
        this.passwordInputRef = React.createRef();
    }

    state = {
        password: 'Chargement..',
        length: 32,
        numbers: true,
        symbols: true,
        uppercase: true,
        excludeSimilarCharacters: true
    };

    componentDidMount() {
        this.generateAndSave();
    };

    generateAndSave = (override = null) => {
        if (!override)
        {
            this.setState({password: this.generatePassword(this.state)});
        }
        else
        {
            let s = {...this.state, ...override};
            s.password = this.generatePassword(s);
            this.setState(s);
        }
    };

    generatePassword = (options = {}) => {
        return PwGen.generate(options);
    };

    onLengthChange = (event) => {
        this.generateAndSave({length: event.target.value});
    };

    onNumbersChange = () => {
        this.generateAndSave({numbers: !this.state.numbers});
    };

    onSymbolsChange = () => {
        this.generateAndSave({symbols: !this.state.symbols});
    };

    onUppercaseChange = () => {
        this.generateAndSave({uppercase: !this.state.uppercase});
    };

    onSimilarCharsChange = () => {
        this.generateAndSave({excludeSimilarCharacters: !this.state.excludeSimilarCharacters});
    };

    copyPassword = () => {
        this.passwordInputRef.current.select();
        document.execCommand('copy');
    };

    _handlePassword = () => {
        if (this.props.onClose !== null && this.props.onClose !== false)
            this.props.onClose(this.state.password);
    };

    render() {
        return (
            <Modal title={"Password Generator"} onClose={this.props.onClose}>
                <div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-header">
                                    Generation options
                                </div>
                                <div className="card-body">
                                    <div className="mt-4">
                                        <label htmlFor="pw-length-slider">Password length in charaters: {this.state.length}</label>
                                        <input type="range" id="pw-length-slider" className="custom-range" min="3" max="32" onChange={this.onLengthChange}/>
                                    </div>

                                    <div className="form-check mt-4">
                                        <input className="form-check-input" type="checkbox" value="" id="numbers-check" defaultChecked={this.state.numbers} onChange={this.onNumbersChange}/>
                                        <label className="form-check-label" htmlFor="numbers-check">
                                            Insert digits
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="symbols-check" defaultChecked={this.state.symbols} onChange={this.onSymbolsChange}/>
                                        <label className="form-check-label" htmlFor="symbols-check">
                                            Insert special characters
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="uppercase-check" defaultChecked={this.state.uppercase} onChange={this.onUppercaseChange}/>
                                        <label className="form-check-label" htmlFor="uppercase-check">
                                            Insert uppercase characters
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="similar-check" defaultChecked={this.state.uppercase} onChange={this.onSimilarCharsChange}/>
                                        <label className="form-check-label" htmlFor="similar-check">
                                            Exclude similar characters
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-header">
                                    Result
                                </div>
                                <div className="card-body">
                                    <div className="justify-content-center h-100">
                                        <input className="form-control mr-1 w-100"
                                               value={this.state.password}
                                               readOnly={true}
                                               ref={this.passwordInputRef}/>
                                        <button type="button" className="btn btn-primary w-100 mt-2" onClick={this.copyPassword}><i className="fa fa-copy"></i> Copy</button>
                                        { this.props.hideValidate ? null : <button type="button" className="btn btn-primary w-100 mt-2" onClick={this._handlePassword}>Valider</button> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

ToolsPasswordGenerator.defaultProps = {
    onClose: false,
    title: null,
    hideValidate: false
};

export default connect(({bcli}) => ({bcli}), {})(ToolsPasswordGenerator);
