import React, {Component} from 'react'

export default class LoadingPage extends Component
{
  render() {
    return (
      <div className="LoadingPage">
        <h1><strong>Bluesquare</strong> Hub</h1>
        <i className="fas fa-circle-notch fa-spin text-white"/>
      </div>
    )
  }
}