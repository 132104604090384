import React, {Component} from 'react'
import {FormConsumer} from "./FormProvider";

export default class Select extends Component {
    componentWillReceiveProps(props) {
        if (props.value === null && props.options && props.options.length) {
            props.onChange(props.options[0].value)
        }
    }

    render() {
        return (
            <FormConsumer>
                {({errors}) => this.renderSelect(errors)}
            </FormConsumer>
        )
    }

    renderSelect(errors) {
        const error = errors && errors[this.props.name] ? errors[this.props.name] : null
        return (
            <div className="form-group">
                {this.props.label ? <label htmlFor={"select_" + this.props.name}>{this.props.label}</label> : null}
                <select
                    autoFocus={this.props.autoFocus}
                    className="form-control"
                    name={this.props.name}
                    required={this.props.required}
                    id={"select_" + this.props.name}
                    value={this.props.value}
                    onChange={(e) => this.props.onChange(e.target.value)}>{this.renderOptions()}</select>

                {error ? <div className="invalid-feedback">{error.key}</div> : null}
            </div>
        )
    }

    renderOptions() {
        return this.props.options.map((option) => (
            <option value={option.value} key={option.value}>{option.label}</option>
        ))
    }
}

Select.defaultProps = {
    name: 'input',
    value: null,
    onChange: () => {
    },
    required: false,
    label: "",
    options: [
        {label: "A", value: 'a'},
        {label: "B", value: 'b'},
        {label: "C", value: 'c'},
    ]
};
