import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {getInvoices, sendEmail, getUnpaidInvoices} from "../data/invoices";
import {withRouter} from "react-router-dom";
import EditInvoiceModal from '../components/invoice/EditInvoiceModal'
import DeleteInvoiceModal from "../components/invoice/DeleteInvoiceModal";
import AddInvoiceModal from '../components/invoice/AddInvoiceModal';
import {API_URL} from "../config";
import {getMissions} from "../data/missions";
import { getCustomers } from "../data/customers";
import moment from "moment";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

class InvoicesPage extends Component
{
    state = {
        showEditModal: false,
        showDeleteModal: false,
        showAddModal: false,
        rowData: {},
        sendingMail: false,
        invoices: []
    };

    componentDidMount() {
        this.props.getInvoices()
            .then(res => {
                if(this.props.match.params.mission)
                    this.setState({ invoices: res.payload.data.filter( invoice => invoice.mission.id == this.props.match.params.mission) })
                else
                    this.setState({ invoices: res.payload.data })
            });

        this.props.getMissions();
        this.props.getUnpaidInvoices();
        this.props.getCustomers();
    }

    amountToGet(invoices) {
        const sum = invoices.index.reduce((prev, curr) => (curr.payed_at === null || curr.payed_at === '' ? prev + curr.amount_ht : prev), 0)

        return Number(sum.toFixed(2));
    }

    render = () => {
        return (
            <div className="col-12">
                <div>
                    {this.state.showEditModal ? <EditInvoiceModal rowData={this.state.rowData} onClose={this.onEditModalClose}/> : null}
                    {this.state.showDeleteModal ? <DeleteInvoiceModal rowData={this.state.rowData} onClose={this.onDeleteModalClose}/> : null}
                    {this.state.showAddModal ? <AddInvoiceModal onClose={this.onAddModalClose} missions={this.props.missions}/> : null}
                </div>
                <div className={'row'}>
                    <div className="col-12">
                        <h2 style={{'display':'inline-block'}}>Les factures</h2>
                        <button className="btn btn-primary float-right" onClick={this.onAddModalOpen}>
                            <i className="fa fa-plus"/>
                        </button>
                        <div style={{'float':'right','marginRight':'15px'}}>
                            <span style={{'fontWeight':700, 'textDecoration':'underline'}}>{this.amountToGet(this.props.invoices)} € HT à récupérer</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'mt-4'}/>
                    <h3>À payer</h3>
                    <BootstrapTable data={this.props.invoices.unpaidInvoices}
                                    striped
                                    hover
                                    condensed
                                    className={'invoice'}
                    >
                        <TableHeaderColumn isKey dataField='mission' dataSort={ false } dataFormat={this.unpaidCustomerFormatter}>Client name</TableHeaderColumn>
                        <TableHeaderColumn dataField='amount' dataSort={true}>Montant</TableHeaderColumn>
                    </BootstrapTable>
                    <div className={'mt-5'}/>
                    {
                        (this.props.match.params.mission)
                            ? (
                                <div>
                                    <h3>Factures de la mission # {this.props.match.params.mission} (<a href={'/invoices'}>x</a>)</h3>
                                </div>
                            )
                            : <h3>Toutes les factures </h3>
                    }

                    <BootstrapTable data={this.state.invoices}
                                    striped
                                    hover
                                    condensed
                                    search
                                    exportCSV
                                    pagination
                                    className={'invoice'}
                    >
                        <TableHeaderColumn isKey dataField='reference' dataSort={ true } dataFormat={this.referenceFormatter} filter={ { type: 'TextFilter', delay: 500 }}>Reference</TableHeaderColumn>
                        <TableHeaderColumn dataField='client_name' dataSort={ true } dataFormat={ this.customerFormatter } filter={ { type: 'TextFilter', delay: 500 }}>Client</TableHeaderColumn>
                        <TableHeaderColumn dataField='amount_ht'  dataSort={ true } dataFormat={this.euroFormatter} filter={ { type: 'TextFilter', delay: 500 }}>Montant</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='has_payed' dataSort={ true }
                            dataFormat={ this.renderHasPayed }
                            filter={{
                                type: 'SelectFilter',
                                options: {
                                    "true": true,
                                    "false": false,
                                }
                            }}
                        >
                            Payé ?
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='id' dataSort={ true } dataFormat={ this.renderActions }>
                            Action
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        )
    }

    renderHasPayed = (has_payed) => {
        if(has_payed)
            return 'Oui'

        return 'Non';
    }

    renderColor = (invoice) =>{
        //La facture est payée
        if(invoice.payed_at)
            return 'green';

        //La facture est en retard : Pas payée et je suis après la date nécessaire
        let now = moment();
        let due_date = moment(invoice.due_date);

        if(now.diff(due_date) > 0 && !invoice.payed_at)
            return 'red';

        //Facture pas encore envoyée
        if( now.diff(due_date) < 0 && !invoice.emitted_at)
            return 'orange';

        //Par défaut : Facture envoyée, dans la zone d'attente
        return 'grey';
    }

    renderStatus = (cell,invoice) => {
        return (
            <span>
                <i className={' fa fa-plus'}/>&nbsp;&nbsp;{ invoice.created_at }
                { invoice.emitted_at && <span><br/><i className={'fa fa-envelope-open'}/>&nbsp;&nbsp;{invoice.emitted_at}</span> }
                { invoice.payed_at && <span><br/><i className={'fa fa-euro-sign'}/>&nbsp;&nbsp;{invoice.payed_at} </span> }
                <span><br/><i className={'fa fa-bell'}/>&nbsp;&nbsp;{invoice.due_date}</span>
            </span>
        )
    }

    renderActions = (cell, invoice) => {
        const customer_id = invoice.mission.customer.id;
        const hasLegalRepresentative = this.props.customers.index.filter(c => c.id === customer_id && c.contacts && c.contacts.filter(co => co.business_function === 'signatory_legal_representative').length > 0).length > 0

        return (hasLegalRepresentative ? this.renderAction(cell, invoice) : "Il n'y a pas de représentant légal rattaché au client rattaché à cette facture")
    }

    renderAction = (cell, invoice) => {
        return (
            <span>
                {
                    !invoice.emitted_at &&
                    <button className={'btn btn-primary'} onClick={e => this.props.sendEmail(invoice,"invoice")}>Envoyer</button>
                }
                {
                    (invoice.emitted_at && !invoice.payed_at) &&
                    <button className={'btn btn-primary'} onClick={e => this.props.sendEmail(invoice,"reminder")}>Rappel</button>
                }
                {
                    !invoice.url &&
                    <button className={'btn btn-warning'} onClick={e => window.open(API_URL + "/make-invoice/" + invoice.id + '?auth_token='+this.props.auth.token, '_blank')}>
                        <i className={'fa fa-file-pdf'}/>
                    </button>
                }
                {
                    invoice.url &&
                    <button className={'btn btn-secondary'} onClick={e => window.open(API_URL+ "/download/comptable/invoice/"+invoice.reference, '_blank')}>
                        <i className={'fa fa-file-pdf'}/>
                    </button>
                }
                <button className={'btn btn-secondary'} onClick={() => this.onEditModalOpen(invoice.id)}>Editer</button>
            </span>
        )
    }

    euroFormatter = (cell,row) => {
        return <span className=""><b>{row.amount_ttc}€ TTC</b><br/> ({row.amount_ht}€ HT)</span>
    }

    referenceFormatter = (cell,row) => {
        return (
            <span className="col-3">
                <div style={{'width':15,'height':15,'background': this.renderColor(row), 'borderRadius':'50%', 'display':'inline-block','marginRight':10  }}>
                </div>
                <a href={'/missions/'+ row.mission.id} className={'no-style'} target={'_blank'}>{row.mission.name}</a> <br/><em>{row.reference}</em>
            </span>
        )
    }

    customerFormatter = (cell, row) => {
        return <a href={'/customers/'+row.mission.customer.id} className={'no-style'} target={'_blank'}> {row.mission.customer.name}</a>
    }

    unpaidCustomerFormatter = (cell, row) => {
        return row.customer.name
    }

    // == Modals
    onEditModalClose = () => {
        this.setState({ showEditModal: false });
    };

    onEditModalOpen = (id) => {
        this.setState({
            rowData: this.props.invoices.index.filter(x => x.id === id)[0],
            showEditModal: true
        });
    };

    onDeleteModalClose = () => {
        this.setState({ showDeleteModal: false });
    };

    onDeleteModalOpen = (id) => {
        this.setState({
            rowData: this.props.invoices.index.filter(x => x.id === id)[0],
            showDeleteModal: true
        });
    };

    onAddModalOpen = () => {
        this.setState({ showAddModal: true });
    };

    onAddModalClose = () => {
        this.setState({ showAddModal: false });
    };
}

export default connect(({ auth, invoices, missions, customers }) => ({ auth, invoices, missions, customers }), {getInvoices, getMissions, sendEmail, getUnpaidInvoices, getCustomers})(withRouter(InvoicesPage));
