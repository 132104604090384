import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deleteNote} from '../../data/notes'
import NoteDeleteModal from './NoteDeleteModal'
import NoteUpdateModal from './NoteUpdateModal'
import {nl2br} from "../../helpers"
import PrivilegesButton from "../privileges/PrivilegesButton";
import CopyTextButton from "../CopyTextButton";
import '../../styles/generalpurpose/hoverdisplayproperties.scss';
import '../../styles/generalpurpose/pseudoclasscolors.scss';

class Note extends Component
{
    constructor(props) {
        super(props);
    }

    state = {
        showDeleteModal: false,
        showUpdateModal: false,
        showPassword: false
    };

    onDeleteModalOpen = () => this.setState({ showDeleteModal: true });
    onDeleteModalClose = (deleted) => {
        this.setState({ showDeleteModal: false });
        if (deleted) this.props.onDelete()
    };

    onUpdateModalOpen = () => this.setState({ showUpdateModal: true });
    onUpdateModalClose = () => this.setState({ showUpdateModal: false });

    render = () => {
        return (
            <div>
                {this.renderNote()}
                {this.state.showDeleteModal ? <NoteDeleteModal id={this.props.data.id} onClose={this.onDeleteModalClose} /> : null}
                {this.state.showUpdateModal ? <NoteUpdateModal data={this.props.data} onClose={this.onUpdateModalClose} /> : null}
            </div>
        )
    };

    renderNote()
    {
        const note = this.props.data;
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className={"card-title"}>{note.name}</h5>
                    {note.type === "credentials" ? this.renderCredentialFields(note) : null}
                    {note.type === "link" ? this.renderLinkFields(note) : null}
                    {note.type === "sticky" ? this.renderStickyFields(note) : null}
                    <div className={"mt-3"}>
                        <button className="btn btn-secondary mr-2" onClick={this.onUpdateModalOpen}>Edit</button>
                        <button className="btn btn-danger"
                                onClick={this.onDeleteModalOpen}>Delete</button>
                        <PrivilegesButton type="Note" id={note.id} />
                    </div>
                </div>
            </div>
        )
    }

    renderCredentialFields = (note) => {
        return (
            <div>
                <p className={"card-text"}>Username: <CopyTextButton copyLabel={note.username} wrapperClass="link-like d-inline-block" textToCopy={note.username}/></p>
                <p className={"card-text"}>Password: {this.renderPassword(note.password)}</p>
            </div>
        )
    };

    renderPassword(password) {
        if (!this.state.showPassword) {
            return <span style={{color: '#2a99e0', cursor: 'pointer'}} onClick={() => this.setState({ showPassword: true })}>(show)</span>
        }
        return <CopyTextButton copyLabel={password} wrapperClass="link-like d-inline-block" textToCopy={password}/>
    }

    renderLinkFields = (note) => {
        return (
            <div>
                <a className={"card-text"} href={note.link} target={"_blank"}>{note.link}</a>
            </div>
        )
    };

    renderStickyFields = (note) => {
        return (
            <div>
                <p className={"card-text"}>{nl2br(note.sticky)}</p>
            </div>
        )
    };
}

Note.defaultProps = {
    displayCustomer: false,
    onDelete: () => {}
};

export default connect(null, {deleteNote})(Note);
