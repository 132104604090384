import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";

class DeleteInvoiceModal extends Component {

    state = {
        id: this.props.rowData.id,
        name: this.props.rowData.name,
        deleting: false
    };

    delete = (e) => {
        this.setState({ deleting: true });
        e.preventDefault();
    };

    render() {
        return (
            <Modal title="Delete activity" onClose={this.props.onClose}>
                {this.renderForm()}
            </Modal>
        );
    }

    renderForm() {
        return (
            <div style={{textAlign: 'center'}}>
                <p>You are about to permanently delete <strong>{this.state.name}</strong>, are you sure you want to proceed?</p>
                <div className="mt-4">
                    <button onClick={() => this.props.onClose(true)} className="btn btn-primary mr-2"><i className="fa fa-times mr-1"></i> No</button>
                    <button onClick={this.delete} className="btn btn-danger"><i className="fa fa-trash mr-1"></i> Yes</button>
                </div>
            </div>
        )
    }
}

export default connect(null, {})(DeleteInvoiceModal)
