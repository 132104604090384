import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deleteElement, updateElement, updateSchema} from "../../data/bag_editor";
import Menu from "./Menu";
import Stage from "./Stage";

import './Board.scss'
import ElementTemplate from "../../pages/bag/ElementTemplate";
import {getDataRecursively} from "../../helpers";
import Sidebar from "./Sidebar";

class Board extends Component
{
    state = {
        context: null,
        selection: null,
        tool: 'default',
        width: 0,
        height: 0,
        dragging: false,

        elementOverlay: false,
        elementName: ''
    }

    getSelection = () => {
        return this.state.selection
    }

    componentDidMount() {
        window.addEventListener('resize', this._updateDimensions)
        this._updateDimensions()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._updateDimensions)
    }

    _updateDimensions = () => {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight - 60
        })
    }

    _getData()
    {
        return this.props.editor.schema[this.props.board]
    }

    //

    render()
    {
        return (
            <div className={"BAG-Board " + this.props.board + (this.props.active ? " active" : "")}>
                <Menu
                    data={this._getData()}
                    board={this.props.board}
                    context={this.state.context}
                    onClickContext={(context) => {
                        this._updateSelection(null)
                        this.setState({ context })
                    }}
                />
                <div className="concept-row">
                    <Stage
                        width={this.state.width}
                        height={this.state.height}
                        tool={this.state.tool}
                        data={this._getData()}
                        board={this.props.board}
                        selection={this.state.selection}
                        context={this.state.context}
                        onClickContext={(context) => {
                            this._updateSelection(context)
                            this.setState({ elementOverlay: false })
                        }}
                        onDblClickContext={(context) => {
                            this._updateSelection(null)
                            this.setState({ context })
                        }}
                        onDragStart={(selection) => {
                            this._updateSelection(selection)
                            this.setState({ dragging: true, elementOverlay: false })
                        }}
                        onDragEnd={() => this.setState({ dragging: false })}
                        onFocusElement={(id) => {
                            this._updateSelection(id)
                        }}
                        onElementCreated={(element) => this._editElement(element)}
                    />
                    {this.renderActions()}
                    {this.renderSidebar()}
                </div>
            </div>
        )
    }

    renderActions()
    {
        if (this.props.editor.linking !== null) return null
        if (this.state.selection === null || this.state.dragging) return null

        const element = getDataRecursively(this.state.selection, this._getData())
        if (!element) return null

        if (this.state.elementOverlay) {
            return (
                <div
                    style={{
                        left: element._editor.x,
                        top: element._editor.y,
                        width: element._editor.width,
                        height: element._editor.height,
                    }}
                    className="element-overlay">
                    <input
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                this.props.updateElement(this.props.board, { id: this.state.selection, name: this.state.elementName })
                                this.setState({ elementOverlay: false })
                            }
                        }}
                        onChange={(e) => this.setState({ elementName: e.target.value })}
                        value={this.state.elementName}
                        type="text"
                        autoFocus={true}
                        placeholder="Name" />
                </div>
            )
        }

        return (
            <div
                style={{
                    left: element._editor.x,
                    top: element._editor.y,
                }}
                className="element-actions">
                <button onClick={() => this._editElement(element)}><i className="fas fa-pen"/></button>
                <button onClick={this._deleteElement}><i className="fas fa-trash"/></button>
            </div>
        )
    }

    renderSidebar = () => (
        <Sidebar
            data={this._getData()}
            board={this.props.board}
            context={this.state.context}
            selection={this.state.selection}
        />
    )

    _editElement = (element) => {
        this.setState({
            elementOverlay: true,
            elementName: element.name
        })
    }

    _deleteElement = () => {
        this.props.deleteElement(this.props.board, this.state.selection)
        this._updateSelection(null)
    }

    _updateSelection(selection)
    {
        this.setState({ selection })
        this.props.onSelectionChange(selection)
    }
}

export default connect(({ bag_editor }) => ({ editor: bag_editor }), { updateSchema, updateElement, deleteElement })(Board)