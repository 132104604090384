import React, {Component} from 'react'
import {connect} from "react-redux";
import {getUsers} from "../../data/users";
import {getMissionsofWeek} from "../../data/missions";
import {createUserHour, retrieveUserHours} from "../../data/userHours";
import {withRouter} from "react-router-dom";


class UserHourInput extends Component {

    state = {
        value: ''
    }

    componentDidMount() {
    }

    render() {
        return (
                <input
                    value={this.state.value ? this.state.value : this.props.initialValue }
                    style={{maxWidth: 50, marginLeft: 10}}
                    className={'ml-2'}
                    type={"number"}
                    onChange={ (e) => this.setState({value: e.target.value})}
                    onBlur={ (e)=> {this.props.createUserHour(this.props.date, this.props.day, this.props.user, this.props.mission, e.target.value)} }
                />
        )
    }
}


export default connect(({ }) => ({ }), {createUserHour})(UserHourInput)