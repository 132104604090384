import {request} from '../helpers'


// Fetch all missions

const MISSIONS_FETCH_ALL = 'MISSIONS_FETCH_ALL'
const MISSIONS_FETCH_ALL_SUCCESS = 'MISSIONS_FETCH_ALL_SUCCESS'
const MISSIONS_FETCH_ALL_FAIL = 'MISSIONS_FETCH_ALL_FAIL'

export function getMissions(customer = 0, recurring = 'no-filter', withStats = 'false', status = 'no-filter', withoutBluesquare = 'false', withRecurring = 'true')
{
    return request(MISSIONS_FETCH_ALL, 'get', '/missions?customer='+customer+"&recurring="+recurring+"&withStats="+withStats +"&status="+status + "&withoutBluesquare=" + withoutBluesquare + '&withRecurring=' + withRecurring)
}

const MISSIONS_WEEK_FETCH_ALL = 'MISSIONS_WEEK_FETCH_ALL'
const MISSIONS_WEEK_FETCH_ALL_SUCCESS = 'MISSIONS_WEEK_FETCH_ALL_SUCCESS'
const MISSIONS_WEEK_FETCH_ALL_FAIL = 'MISSIONS_WEEK_FETCH_ALL_FAIL'

export function getMissionsofWeek(week_id, add_additional_active_missions = false, employee_id = 0, mission_type = '')
{
    return request(MISSIONS_WEEK_FETCH_ALL, 'get', '/missions-of-week/'+ week_id + '/' + add_additional_active_missions+ '/' + employee_id + '?mission_type=' + mission_type)
}

// Fetch mission
const MISSION_FETCH = 'MISSION_FETCH'
export function getMission(id)
{
    return request(MISSION_FETCH, 'get', '/mission/' + id)
}

export const ACCEPT_MISSION = 'ACCEPT_MISSION';
export const ACCEPT_MISSION_SUCCESS = 'ACCEPT_MISSION_SUCCESS';
export function acceptMission(id) {
    const data = {'id':id};
    return request(ACCEPT_MISSION, 'post','/accept-mission',data,{id});
}

export const REFUSE_MISSION = 'REFUSE_MISSION';
export const REFUSE_MISSION_SUCCESS = 'REFUSE_MISSION_SUCCESS';
export function refuseMission(id) {
    const data = {'id':id};
    return request(REFUSE_MISSION, 'post','/refuse-mission',data,{id});
}


export const SEND_QUOTATION = 'SEND_QUOTATION';
export const SEND_QUOTATION_SUCCESS = 'SEND_QUOTATION';
export function sendEmail(id) {
    const data = {'id':id};
    return request(REFUSE_MISSION, 'post','/mission/send-email',data,{id});
}



// Create a mission

export const MISSIONS_CREATE_MISSION = 'MISSIONS_CREATE_MISSION'
export const MISSIONS_CREATE_MISSION_SUCCESS = 'MISSIONS_CREATE_MISSION_SUCCESS'
export const MISSIONS_CREATE_MISSION_FAIL = 'MISSIONS_CREATE_MISSION_FAIL'
export function createMission(name, starting_at, ending_at, customer_id, sold_days, amount)
{
    const data = { name, starting_at, ending_at, customer_id, sold_days, amount }
    return request(MISSIONS_CREATE_MISSION, 'put', '/missions', data)
}

export const CLONE_MISSION = 'CLONE_MISSION';
export const CLONE_MISSION_SUCCESS = 'CLONE_MISSION_SUCCESS';
export const CLONE_MISSION_FAIL = 'CLONE_MISSION_FAIL';
export function cloneMission(mission, customer) {
    const data = {'mission': mission, 'customer': customer};
    return request(CLONE_MISSION, 'post','/clone-mission',data);
}


// Update a mission

const MISSIONS_UPDATE_MISSION = 'MISSIONS_UPDATE_MISSION'
const MISSIONS_UPDATE_MISSION_SUCCESS = 'MISSIONS_UPDATE_MISSION_SUCCESS'
const MISSIONS_UPDATE_MISSION_FAIL = 'MISSIONS_UPDATE_MISSION_FAIL'

export function updateMission(id, name, status, starting_at, ending_at, sold_days = 0, amount = 0, additional_costs = 0, cost_per_day = 0,hide_days = 0, estimated_days = 0, remaining_days = 0)
{
    const data = { id, name, status, starting_at, ending_at, sold_days, amount, additional_costs, cost_per_day, hide_days, estimated_days, remaining_days };
    return request(MISSIONS_UPDATE_MISSION, 'post', '/missions/' + id, data, {id})
}

export function noRemainingDays(id)
{
    const data = { id };
    return request(MISSIONS_UPDATE_MISSION, 'post', '/mission/no-remaining-days', data, {id})
}


// Delete a mission

export const MISSIONS_DELETE_MISSION = 'MISSIONS_DELETE_MISSION'
export const MISSIONS_DELETE_MISSION_SUCCESS = 'MISSIONS_DELETE_MISSION_SUCCESS'
export const MISSIONS_DELETE_MISSION_FAIL = 'MISSIONS_DELETE_MISSION_FAIL'

export function deleteMission(id)
{
    return request(MISSIONS_DELETE_MISSION, 'delete', '/missions/' + id, {}, {id})
}

export const QUOTATION_OP = "QUOTATION_OP";

export function getMissionQuotationIntro(mission_id) {
    return request(QUOTATION_OP, 'get', '/quotation/' + mission_id + '/get-introduction');
}

export function getMissionQuotationOutro(mission_id) {
    return request(QUOTATION_OP, 'get', '/quotation/' + mission_id + '/get-conclusion');
}

export function setMissionQuotationIntro(mission_id, html) {
    return request(QUOTATION_OP, 'POST', '/quotation/update-introduction', {id: mission_id, html});
}

export function setMissionQuotationOutro(mission_id, html) {
    return request(QUOTATION_OP, 'POST', '/quotation/update-conclusion', {id: mission_id, html});
}

export const MISSIONS_GET_STATISTIQUES = "MISSIONS_GET_STATISTIQUES";

export function getMissionsStatistiques(start, end, tags)
{
    return request(MISSIONS_GET_STATISTIQUES, 'get', '/missions/statistiques?start=' + start + "&end=" + end + tags);
}

export const MISSIONS_GET_CSV_DOWNLOAD = "MISSIONS_GET_CSV_DOWNLOAD";

export function getCsvDownload(start, end, tags)
{
    return request(MISSIONS_GET_CSV_DOWNLOAD, 'get', '/missions/statistiques/download?start=' + start + "&end=" + end + tags);
}

// Store

const defaultState = {
    index: [],
    loading: false
};





// Reducer

export default function missionsReducer(state = defaultState, action)
{
    let index;

    switch(action.type)
    {
        case MISSION_FETCH:
        case MISSIONS_CREATE_MISSION:
        case CLONE_MISSION:
        case MISSIONS_UPDATE_MISSION:
        case MISSIONS_DELETE_MISSION:
        case MISSIONS_FETCH_ALL:
        case MISSIONS_WEEK_FETCH_ALL:
            return { ...state, loading: true };

        case MISSIONS_CREATE_MISSION_FAIL:
        case CLONE_MISSION_FAIL:
        case MISSIONS_UPDATE_MISSION_FAIL:
        case MISSIONS_DELETE_MISSION_FAIL:
        case MISSIONS_FETCH_ALL_FAIL:
        case MISSIONS_WEEK_FETCH_ALL_FAIL:
            return { ...state, loading: false };

        case MISSIONS_FETCH_ALL_SUCCESS:
        case MISSIONS_WEEK_FETCH_ALL_SUCCESS:
            index = [...state.index];
            action.payload.data.map((mission) => {
                index = index.filter((n) => n.id !== mission.id);
                index.push(mission);
                return null;
            });
            return {
                ...state,
                index,
                loading: false
            };


        case ACCEPT_MISSION_SUCCESS:
            index = state.index.filter(( mission ) => mission.id !== action.meta.previousAction.id)
            index.push(action.payload.data);
            return { ...state, index, loading: false }


        case SEND_QUOTATION_SUCCESS:
            index = state.index.filter(( mission ) => mission.id !== action.meta.previousAction.id)
            index.push(action.payload.data);
            return { ...state, index, loading: false }

        case REFUSE_MISSION_SUCCESS:
            index = state.index.filter(( mission ) => mission.id !== action.meta.previousAction.id)
            index.push(action.payload.data);
            return { ...state, index, loading: false }


        case MISSIONS_CREATE_MISSION_SUCCESS:
        case CLONE_MISSION_SUCCESS:
            index = [...state.index]
            index.push(action.payload.data.mission)
            return { ...state, index, loading: false }

        case MISSIONS_UPDATE_MISSION_SUCCESS:
            index = state.index.filter(( mission ) => mission.id !== action.meta.previousAction.id)
            index.push(action.payload.data.mission);
            return { ...state, index, loading: false }

        case MISSIONS_DELETE_MISSION_SUCCESS:
            index = [...state.index].filter((mission) => mission.id !== action.meta.previousAction.id)
            return { ...state, index, loading: false }
        case MISSIONS_GET_STATISTIQUES:
        case MISSIONS_GET_CSV_DOWNLOAD:
    }

    return state;
}
