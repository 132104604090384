import React, {Component} from 'react'
import {FormConsumer} from "./FormProvider";

export default class Checkbox extends Component
{
  render()
  {
    return (
      <FormConsumer>
        {({ errors }) => this.renderCheckbox(errors)}
      </FormConsumer>
    )
  }
  renderCheckbox(errors)
  {
    const error = errors && errors[this.props.name] ? errors[this.props.name] : null
    return (
      <div className="form-group">
        <div className="form-check">
          <input type="checkbox"
                 name={this.props.name}
                 id={"checkbox_"+this.props.name}
                 checked={this.props.value}
                 onChange={(e) => this.props.onChange(e.target.checked)}/>
            {this.props.label !== null ? (
                <label className="form-check-label ml-2"
                       htmlFor={"checkbox_"+this.props.name}>{this.props.label}</label>
            ) : null}
        </div>
        {error ? <div className="invalid-feedback">{error.key}</div> : null}
      </div>
    )
  }
}

Checkbox.defaultProps = {
  name: 'input',
  value: false,
  onChange: () => {},
  required: false,
  label: null
}
