import React, {Component} from 'react';
import Modal from "../Modal";
import Input from "../Input";
import FormProvider from "../FormProvider";
import {connect} from "react-redux";
import {updateTask} from "../../data/tasks";
import Textarea from "../Textarea";



class TaskUpdateModal extends Component
{
    state = {
        id: this.props.data.id,
        name: this.props.data.name,
        description: this.props.data.description,
        duration: this.props.data.duration,
        price: this.props.data.price,
        errors: null,
        updating: false
    };

    update = (e) => {
        let {id, name, description, duration, price} = this.state;

        e.preventDefault();
        this.setState({ updating: true });

        this.props.updateTask(id, name, description, duration, price)
            .then(() => {
                this.props.onClose(true);
            })
            .catch((res) => {
                if (res.error) this.setState({ errors: res.error.response.data.errors });
                this.setState({ updating: false });
            });

        return false;
    };

    render = () => {
        return (
            <Modal title="Update a task" onClose={() => this.props.onClose(false)}>
                {this.renderForm()}
            </Modal>
        )
    };

    renderForm = () => {
        return (
            <FormProvider onSubmit={this.update} errors={this.state.errors}>
                <div className="row">
                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"name"}
                               id={"name"}
                               placeholder={"Module name *"}
                               value={this.state.name}
                               onChange={(name) => this.setState({name})}
                               label={"Module name *"}/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Textarea
                            name={"description"}
                            id={"description"}
                            onChange={(description) => this.setState({description})}
                            label={"Description"}
                            value={this.state.description}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Input required
                               type={"number"}
                               name={"duration"}
                               id={"duration"}
                               onChange={(duration) => this.setState({duration})}
                               label={"Duration *"}
                               value={this.state.duration}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Input
                            type={"number"}
                            name={"price"}
                            id={"price"}
                            onChange={(price) => this.setState({price})}
                            label={"Price"}
                            value={this.state.price}
                        />
                    </div>
                </div>
                <button className="btn btn-primary btn-block" disabled={this.state.updating}>Update</button>
            </FormProvider>
        )
    };
}

export default connect(null, { updateTask }) (TaskUpdateModal)
