import React, {Component} from 'react';
import Modal from '../Modal'
import {deleteTask} from '../../data/tasks'
import {connect} from 'react-redux'

class TaskDeleteModal extends Component
{
    state = {
        deleting: false
    }

    deleteModule = () => {
        this.setState({ deleting: true })
        this.props.deleteTask(this.props.id)
            .then(() => {
                this.props.onClose(true)
            })
            .catch((e) => {
                this.setState({ deleting: false })
            })
    }

    render = () => {
        return (
            <Modal title="Delete">
                <p className={"text-center mb-4"}>Are you sure you want to delete this task ?</p>
                <button className="btn btn-danger float-right" disabled={this.state.deleting} onClick={() => this.deleteModule()}>Yes I'm sure</button>
                <button className="btn btn-primary" disabled={this.state.deleting} onClick={() => this.props.onClose(false)}>Please don't, I'm full of regrets</button>
            </Modal>
        )
    }
}

TaskDeleteModal.defaultProps = {
    id: null
}

export default connect(null, {deleteTask})(TaskDeleteModal);
