import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {createProject} from "../../data/bag";
import '../../styles/generalpurpose/hoverdisplayproperties.scss'

class HomePage extends Component {
    state = {
        name: ''
    }

    render() {
        return (
            <div className="BAG-HomePage">
                <header className="mb-3">
                    <h2>BAG</h2>
                </header>
                <div className="bag-projects">
                    <h5>Projects</h5>
                    {this.renderProjects()}
                </div>
                <hr/>
                <div>
                    <div className="mb-3">
                        <h6>Import project</h6>
                        <input
                            type="file"
                            onChange={(e) => this.import(e.target.files[0])}
                        />
                    </div>

                    <div>
                        <h6>New project</h6>
                        <div className="form-group">
                            <input placeholder="Name" type="text" value={this.state.name}
                                   onChange={(e) => this.setState({name: e.target.value})} className="form-control"/>
                        </div>
                        <button className="btn btn-secondary" onClick={() => {
                            this.props.createProject(this.state.name);
                            this.setState({name: ''})
                        }}>Create
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    renderProjects() {
        if (!this.props.bag.projects.length) return <p>No projects created</p>

        return this.props.bag.projects.map((project) => this.renderProject(project))
    }

    renderProject(project) {
        return (
            <div key={project.id} className="mb-2">
                <h6>
                    <Link to={"/bag/" + project.id}>{project.name} ({project.tech ? project.tech : 'concept'})</Link>
                    <i className="ml-2 fa fa-times hover-cursor-pointer" onClick={(e) => this.delete(project.id, e.target)}></i>
                </h6>
            </div>
        )
    }

    delete = (id, dom = null) => {
        let projects = window.localStorage.getItem('bag');
        projects = JSON.parse(projects);

        projects.every((p, i, a) => {
           if (p.id === id)
           {
               projects.splice(i, 1);
               return false;
           }

           return true;
        });

        // Pas beau mais les projets sont chargés de props.bag, on ne va pas modifier un prop et il faut garder
        // une seule source de vérité donc pas de copie en state
        if (dom)
            dom.parentNode.parentNode.remove();

        projects = JSON.stringify(projects);
        window.localStorage.setItem('bag', projects);
    };

    import = (file) => {
        console.log(file);
        let reader = new FileReader();

        if (file.type !== 'application/json') {
            alert("Please import a file in the JSON format");
            return;
        }

        reader.readAsText(file, "UTF-8");
        reader.onload = (e) => {
            const text = e.target.result;
            let data = null;
            try {
                data = JSON.parse(text);
            } catch (e) {
                alert("Sorry, this file is unreadable or corrupted");
                console.log(e.message);
                console.log(text);
            }

            if (!data) return;

            if (!data.name || !data.id) // TODO more validation?
            {
                alert("The file you imported is not a BAG export");
                return;
            }

            // The file uploaded is valid.
            // We want to see if this is an existing project (we replace it)
            // If not we append it

            let projects = this.props.bag.projects;
            let found = false;
            projects.every((p, index, arr) => {
                if (p.id === data.id) {
                    // Le projet existe déjà, alors on le remplace
                    // eslint-disable-next-line no-restricted-globals
                    if (confirm("The project already exists. Overwrite it?"))
                    {
                        arr[index] = data;
                        found = true;
                        return false;
                    }
                }

                return true;
            });

            if (!found)
                projects.push(data);

            window.localStorage.setItem('bag', JSON.stringify(projects));
            console.log('New save: ', projects);

            window.location.reload();
        };

        reader.onerror = function (e) {
            alert("An error occurred while reading the file");
        };
    }
}

export default connect(({bag}) => ({bag}), {createProject})(HomePage)
