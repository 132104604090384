import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {getContacts} from "../data/users";
import {withRouter} from "react-router-dom";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

const BUSINESS_FUNCTION_2_STR = {
    signatory_legal_representative: 'Signataire / Représentant légal',
    billing_contact: 'Contact de facturation',
    other: 'Autre'
}

class ContactsPage extends Component
{
    state = {
        contacts: [],
        showAddModal: false
    };

    loadContacts = () => {
        this.props.getContacts().then((res) => {
            this.setState({
                contacts: res.payload.data
            });
        });
    }

    businessFunctionFormatter = (cell, row) => (BUSINESS_FUNCTION_2_STR[cell])

    actionFormatter(cell, row) {
        return <button className={'btn btn-primary'} onClick={ () => this.props.history.push('/contacts/' + cell) }>
            Voir
        </button>;
    }

    componentDidMount() {
        this.loadContacts();
        this.actionFormatter = this.actionFormatter.bind(this);
    }

    render = () => {
        return (
            <div>
                <h1>Nos contacts !</h1>
                <BootstrapTable data={this.state.contacts}
                                striped
                                hover
                                condensed
                                search
                                exportCSV
                                pagination
                                className={'mission'}
                >
                    <TableHeaderColumn isKey dataField='id' dataSort={ true } style={{'width':'30px !important'}}>#</TableHeaderColumn>
                    <TableHeaderColumn dataField='firstname' dataSort={ true }>Prénom</TableHeaderColumn>
                    <TableHeaderColumn dataField='lastname' dataSort={ true }>Nom</TableHeaderColumn>
                    <TableHeaderColumn dataField='email' dataSort={ true } filter={ { type: 'TextFilter', delay: 500 }}>Email</TableHeaderColumn>
                    <TableHeaderColumn dataField={"business_function"} dataFormat={this.businessFunctionFormatter}>Fonction Business</TableHeaderColumn>
                    <TableHeaderColumn dataField='id' dataSort={ true } dataFormat={this.actionFormatter}>
                        Action
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }
}

export default connect(({  }) => ({  }), {getContacts})(withRouter(ContactsPage));
