import React, {Component} from 'react'

import './AuthLayout.css';

export default class AuthLayout extends Component
{
  render()
  {
    return (
      <div className="AuthLayout bg-dark">
        {this.props.children}
      </div>
    )
  }
}