import React, {Component} from 'react';

export default class EditableProperty extends Component {
    render() {
        const prop = this.props.data
        const context = this.props.context

        let input = null;
        const value = typeof context.props[prop.name] !== 'undefined' ? context.props[prop.name] : ''

        if (Array.isArray(context.props)) context.props = {}

        if (prop.type === 'text' || prop.type === 'number' || prop.type === 'string') {
            return (
                <div key={prop.name} className="property">
                    <label>{prop.name}</label>
                    <input type={prop.type === 'string' ? 'text' : prop.type}
                           className="form-control"
                           value={value}
                           key={`${context.id}-${prop.name}`}
                           onChange={(e) => {
                               context.props[prop.name] = e.target.value;
                               this.props.onChange(context)
                           }}/>
                </div>
            )
        }
        else if (prop.type === 'select') {
            return (
                <div key={prop.name} className="property">
                    <label>{prop.name}</label>
                    <select className="form-control"
                            value={value}
                            key={`${context.id}-${prop.name}`}
                            onChange={(e) => {
                                context.props[prop.name] = e.target.value;
                                this.props.onChange(context)
                            }}>
                        {prop.options.map((opt) => <option key={opt} value={opt}>{opt}</option>)}
                    </select>
                </div>
            )
        }
        else if (prop.type === 'boolean') {
            return (
                <div key={prop.name} className="property">
                    <input type="checkbox"
                           checked={!!value}
                           key={`${context.id}-${prop.name}`}
                           onChange={(e) => {
                               context.props[prop.name] = e.target.checked;
                               this.props.onChange(context)
                           }} />
                    <label>{prop.name}</label>
                </div>
            )
        }
        else {
            return <p>Unsupported property type {prop.type}</p>
        }
    }
}