import FormProvider from "../FormProvider";
import Input from "../Input";
import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";
import {createTask} from "../../data/tasks";
import Textarea from "../Textarea";

class TaskAddModal extends Component
{
    state = {
        name: '',
        description: '',
        duration: '',
        price: '',
        errors: null,
        creating: false
    };

    create = (e) => {
        let { name, description, duration, price } = this.state;
        let missionModule_id = this.props.id;

        e.preventDefault();
        this.setState({ creating: true });

        this.props.createTask(name, description, duration, price, missionModule_id)
            .then((data) => {
                this.setState({ creating: false });
                this.props.onClose(true);
            })
            .catch((res) => {
                this.setState({ creating: false });
                if (res.error) this.setState({ errors: res.error.response.data.errors })
            });

        return false;
    };

    render()
    {
        return (
            <Modal title="Add a task" onClose={() => this.props.onClose(false)}>
                {this.renderForm()}
            </Modal>
        )
    }

    renderForm()
    {
        return (
            <FormProvider onSubmit={this.create} errors={this.state.errors}>
                <div className="row">
                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"name"}
                               id={"name"}
                               placeholder={"Module name *"}
                               value={this.state.name}
                               onChange={(name) => this.setState({name})}
                               label={"Module name *"}/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Textarea
                            name={"description"}
                            id={"description"}
                            onChange={(description) => this.setState({description})}
                            label={"Description"}
                            value={this.state.description}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Input required
                               type={"number"}
                               name={"duration"}
                               id={"duration"}
                               onChange={(duration) => this.setState({duration})}
                               label={"Duration *"}
                               value={this.state.duration}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Input
                            type={"number"}
                            name={"price"}
                            id={"price"}
                            onChange={(price) => this.setState({price})}
                            label={"Price"}
                            value={this.state.price}
                        />
                    </div>
                </div>
                <button className="btn btn-primary btn-block" disabled={this.state.creating}>Add</button>
            </FormProvider>
        )
    }

}

TaskAddModal.defaultProps = {
    onClose: () => {}
};

export default connect(null, { createTask })(TaskAddModal)
