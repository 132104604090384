import React, {Component} from 'react';
import Modal from "../Modal";
import {connect} from "react-redux";
import {createUserEvents} from "../../data/userEvents";
import FormProvider from "../FormProvider";
import Select from "../Select";
import Input from "../Input";

class UserEventsAddModal extends Component {
    state = {
        errors: null,
        event: 1,
        starting_at: "",
        ending_at: "",
        informations: ""
    };

    _handleSubmit = (e) => {
        e.preventDefault();

        const { event, starting_at, ending_at, informations } = this.state;

        this.props.createUserEvents(this.props.userId, event, starting_at, ending_at, informations)
            .then(res => {
                this.props.onClose(res.payload.data);
            })
            .catch(err => console.log(err));
    };

    render() {
        return (
            <Modal title={"Add an event"} onClose={() => this.props.onClose(null)}>
                <FormProvider onSubmit={this._handleSubmit} errors={this.state.errors}>
                    <div className="row">
                        <div className="col">
                            <Select
                                required
                                name={"Event"}
                                id={"event"}
                                value={this.state.event}
                                options={this.props.events}
                                onChange={(event) => {
                                    this.setState({ event })
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Input
                                type={"date"}
                                name={"starting_at"}
                                onChange={(starting_at) => this.setState({ starting_at })}
                                value={this.state.starting_at}
                                label={"Du : "}
                            />
                        </div>
                        <div className="col">
                            <Input
                                type={"date"}
                                name={"ending_at"}
                                onChange={(ending_at) => this.setState({ ending_at })}
                                value={this.state.ending_at}
                                label={"Au : "}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Input
                                type={"text"}
                                name={"informations"}
                                onChange={(informations) => this.setState({ informations })}
                                value={this.state.informations}
                                label={"Informations supplémentaires : "}
                            />
                        </div>
                    </div>
                    <button className="btn btn-primary btn-block">Add</button>
                </FormProvider>
            </Modal>
        );
    }
}

export default connect(null, { createUserEvents })(UserEventsAddModal)
