import {request} from '../helpers'
import {
    CUSTOMER_GET_FAIL,
    CUSTOMERS_CREATE_FAIL,
    CUSTOMERS_DELETE_FAIL,
    CUSTOMERS_GET_FAIL,
    CUSTOMERS_GET_TIMESQUARE_FAIL
} from "./customers";


// Fetch all missions

const HOURS_CREATE_HOUR = 'HOURS_CREATE_HOUR'
const HOURS_CREATE_HOUR_SUCCESS = 'HOURS_CREATE_HOUR_SUCCESS'
const HOURS_CREATE_HOUR_FAIL = 'HOURS_CREATE_HOUR_FAIL'

export function createUserHours(client_id, quart, hours, day)
{
    const data = { client_id, quart, hours, day };
    return request(HOURS_CREATE_HOUR, 'post', '/timesquare/add-hours', data)
}


const USER_HOUR_CREATE = 'USER_HOUR_CREATE'
const USER_HOUR_CREATE_SUCCESS = 'USER_HOUR_CREATE_SUCCESS'
const USER_HOUR_CREATE_FAIL = 'USER_HOUR_CREATE_FAIL'

export function createUserHour(date,day,employee,mission,value)
{
    const data = { date, day, employee, mission, value };

    return request(USER_HOUR_CREATE, 'post', '/new-timesquare/add-hours', data)
}

const USER_HOUR_RETRIEVE = 'USER_HOUR_RETRIEVE'
const USER_HOUR_RETRIEVE_SUCCESS = 'USER_HOUR_RETRIEVE_SUCCESS'
const USER_HOUR_RETRIEVE_FAIL = 'USER_HOUR_RETRIEVE_FAIL'
export function retrieveUserHours(date)
{
    const data = { date };
    return request(USER_HOUR_CREATE, 'post', '/new-timesquare/retrieve', data)
}

const CHECK_HOURS = 'CHECK_HOURS'
export function checkHours(date)
{
    const data = { date };
    return request(USER_HOUR_CREATE, 'post', '/new-timesquare/check-hours',data)
}

const GET_STAFFING_HOURS = 'GET_STAFFING_HOURS'
export function getStaffingHours()
{
    const data = {  };
    return request(USER_HOUR_CREATE, 'post', '/new-timesquare/get-staffing-hours',data)
}


const SET_STAFFING_HOURS = 'SET_STAFFING_HOURS'
export function setStaffingHours(user,mission,action,begin,end)
{
    const data = { user,mission,action,begin,end };
    return request(USER_HOUR_CREATE, 'post', '/new-timesquare/set-staffing-hours',data)
}

const DELETE_THIS_STAFFING = 'DELETE_THIS_STAFFING'
export function deleteThisStaffing(user,mission,monthNumber)
{
    const data = { user,mission,monthNumber };
    return request(USER_HOUR_CREATE, 'post', '/new-timesquare/delete-this-staffing',data)
}



const HOURS_DELETE_HOUR = 'HOURS_DELETE_HOUR'
const HOURS_DELETE_HOUR_SUCCESS = 'HOURS_DELETE_HOUR_SUCCESS'
const HOURS_DELETE_HOUR_FAIL = 'HOURS_DELETE_HOUR_FAIL'

export function deleteUserHours(client_id, day)
{
    const data = { client_id, day };
    return request(HOURS_DELETE_HOUR, 'post', '/timesquare/delete-hours', data)
}


// Store
const defaultState = {
    index: [],
    loading: false
};


// Reducer
export default function userHoursReducer(state = defaultState, action)
{
    let index;

    switch(action.type)
    {
        case HOURS_CREATE_HOUR_SUCCESS:
        case HOURS_DELETE_HOUR_SUCCESS:
            return { ...state, loading: true };

        case HOURS_CREATE_HOUR_FAIL:
        case HOURS_DELETE_HOUR_FAIL:
            return { ...state, loading: false};

    }

    return state;
}

