import React, {Component} from 'react'
import {FormConsumer} from "./FormProvider";

export default class Radios extends Component
{

  render()
  {
    return (
      <FormConsumer>
        {({ errors }) => this.renderInput(errors)}
      </FormConsumer>
    )
  }

  renderInput(errors)
  {
    const error = errors && errors[this.props.name] ? errors[this.props.name] : null
    return (
      <div className="form-group">
        <label className="mr-2">{this.props.label}</label>
        {this.props.options.map((opt, key) => this.renderRadio(opt.value, opt.label, key))}
        {error ? <div className="invalid-feedback">{error.key}</div> : null}
      </div>
    )
  }

  renderRadio(value, label, key)
  {
    return (
      <div className="form-check form-check-inline" key={key}>
        <input
          onChange={(e) => this.props.onChange(e.target.value)}
          checked={this.props.value === value}
          className="form-check-input"
          type="radio" name={this.props.name}
          id={this.props.name + "_radio_" + value}
          value={value} />
        <label className="form-check-label" htmlFor={this.props.name + "_radio_" + value}>{label}</label>
      </div>
    )
  }
}

Radios.defaultProps = {
  name: 'input',
  value: '',
  onChange: () => {},
  label: 'Options',
  options: {}
}
