import React, {Component} from 'react';
import {login, authorize} from "../../data/auth";
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import Input from "../../components/Input";
import FormProvider from "../../components/FormProvider";
import PasswordResetModal from "../../components/auth/PasswordResetModal";

import logo from '../../images/bluesquare.png'

class LoginPage extends Component
{
    state = {
        email: '',
        password: '',
        errors: null,
        showResetPasswordModal: false
    };

    onOpenShowResetPasswordModal = () => this.setState({showResetPasswordModal: true});
    onCloseShowResetPasswordModal = () => this.setState({showResetPasswordModal: false});

    login = (e) => {
        e.preventDefault();
        this.props.login(this.state.email, this.state.password)
            .then(() => {
                this.props.history.push('/');
            })
            .catch((res) => {
                this.setState({ errors: res.error.response.data.errors })
            });

        return false;
    };

    authorize = (e) => {
        this.props.authorize()
            .then(({ payload }) => {
                window.location.href = payload.data.url
            })
            .catch(err => {
                console.error(err)
            })
    }


  render = () => {
    return (
      <div className="container">
        <div className="card card-login mx-auto mt-5">
          <div className="card-header text-center">
            <img src={logo} alt="Bluesquare" style={{width: 120}}/>
          </div>
          <div className="card-body">
            {this.renderForm()}
              <button className="btn btn-secondary w-100 mb-2 d-flex align-items-center" onClick={() => this.authorize()}>
                  <img src={logo} style={{ width: 40, marginRight: '1rem'}} alt={""}/>
                  Sign in with Bluesquare Connect
              </button>
            {this.renderLinks()}
          </div>
        </div>
    {this.state.showResetPasswordModal ? <PasswordResetModal onClose={this.onCloseShowResetPasswordModal}/> : null}
    </div>
    )
  }

  renderForm = () => {
    return (
      <FormProvider onSubmit={this.login} errors={this.state.errors}>
        <Input required autoFocus
               name={"email"}
               label={"Email address"}
               type={"email"}
               value={this.state.email}
               onChange={(email) => this.setState({ email })} />
        <Input required
               name={"password"}
               label={"Password"}
               type={"password"}
               value={this.state.password}
               onChange={(password) => this.setState({ password })} />
        <button className="btn btn-primary btn-block mb-3">Sign in to Hub</button>
      </FormProvider>
    )
  }

    renderLinks = () => {
        return (

            <div className="text-center">
                <Link to={"/register"} className={"d-block small"}>Register an Account</Link>
                <a href="#" className={"d-block small"} onClick={this.onOpenShowResetPasswordModal}>Forgot Password?</a>
            </div>
        )
    }
}

export default withRouter(connect(null, { login, authorize })(LoginPage))
