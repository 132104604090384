import React, { Component } from "react";
import {connect} from 'react-redux';
import { loginFromCookie } from "../../data/auth";
import LoadingPage from "../LoadingPage";
import Cookies from "universal-cookie";

class ConnectPage extends Component
{
    componentDidMount() {
        const cookies = new Cookies();
        const token = this.props.match.params.token;

        cookies.set('hub_token', token);

        this.props.loginFromCookie()
    }

    render() {
        console.log('allo !?')
        return (
            <LoadingPage {...this.props}/>
        )
    }
}

export default connect(({ auth }) => ({ auth }), {loginFromCookie})(ConnectPage);