import React, {Component} from 'react';
import {connect} from 'react-redux';
import Item from "../Item";

class User extends Component
{

    render = () => {
        return (
            <div>
                {this.renderUser()}
            </div>
        )
    };

    renderUser()
    {
        const user = this.props.data;

/*        let informations = [];
        if (user.email) informations.push({ label: 'Mailbox', value: user.email });
        if (user.phone) informations.push({ label: 'Phone', value: user.phone });
        if (user.skype) informations.push({ label: 'Skype', value: user.skype });*/

        return (
            <div>
                <Item to={"/users/" + user.id} title={user.firstname + ' ' + user.lastname}/>
            </div>
        )
    }
}


export default connect(null)(User);
