import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {getInvoice, getInvoices} from "../data/invoices";
import {withRouter} from "react-router-dom";


import {getModules} from "../data/modules";

class InvoicePage extends Component
{



}

export default connect(({invoices, modules}) => ({invoices, modules}), {
    getInvoice,
    getInvoices,

})(withRouter(InvoicePage));
