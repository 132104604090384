import React, {Component} from 'react';
import Modal from '../Modal'
import {connect} from 'react-redux'
import FormProvider from "../FormProvider";
import {getEveryUsers} from '../../data/users';
import {createContact}  from "../../data/customers";
import Select from "../Select";
import Input from "../Input";
import SelectSearch from "react-select";

class CustomerAddContactModal extends Component
{
    state = {
        users: [],
        selectedUser: { label: 'Aucuns', value: null },
        errors: null,
        firstname: "",
        lastname: "",
        email: "",
        gender: 'M',
        phone: "",
        skype: "",
        title: '',
        responsibility: '',
        business_function: ''
    };

    constructor(props) {
        super(props);

        this.handleNewContact = this.handleNewContact.bind(this);
    }

    getUsers = () => {
        if (!this.state.users || this.state.users.length === 0)
            return []

        const formatted = this.state.users.map(user => ({ label: user.email, value: user.id }))
        formatted.unshift({ label: "Aucuns", value: null })

        return formatted;
    };

    componentDidMount()
    {
        this.props.getEveryUsers()
            .then(({ payload }) => {
                this.setState({
                    users: payload.data
                })
            });
    }

    handleNewContact(event) {
        event.preventDefault();

        console.log(this.state.selectedUser);

        if (this.state.selectedUser && this.state.selectedUser.value !== null)
            this.props.createContact({
                'user_id': this.state.selectedUser.value,
                'customer_id': this.props.id
            }).then((res) => {
                this.props.onClose(true);
            });
        else
            this.props.createContact({
                'user_id': -1,
                'customer_id': this.props.id,
                'firstname': this.state.firstname,
                'lastname': this.state.lastname,
                'email': this.state.email,
                'gender': this.state.gender,
                'phone': this.state.phone,
                'skype': this.state.skype,
                'title': this.state.title,
                'responsibility': this.state.responsibility,
                'business_function': this.state.business_function
            }).then((res) => {
                this.props.onClose(true);
            });
    }

    render = () => {
        return (
            <Modal title="Add contact" onClose={this.props.onClose}>
                <FormProvider>
                    <div className="form-group">
                        <label htmlFor="selectedUser">Ajouter un contact existant déjà sur le Hub</label>
                        <SelectSearch
                            id={'selectedUser'}
                            name={'type'}
                            placeholder={'Ajouter un contact existant déjà sur le Hub'}
                            value={this.state.selectedUser}
                            onChange={user => this.setState({ selectedUser: user })}
                            options={this.getUsers()}
                        />
                    </div>
                    <h5>Or create new user</h5>
                    <div className="form-group">
                        <Input type="text"
                               id={"firstname"}
                               name={"Firstname"}
                               label={"Firstname"}
                               placeholder={"Firstname"}
                               className="form-control"
                               value={this.state.firstname}
                               onChange={(firstname) => this.setState({firstname})}
                        />
                    </div>
                    <div className="form-group">
                        <Input type="text"
                               id={"lastname"}
                               name={"Lastname"}
                               label={"Lastname"}
                               placeholder={"Lastname"}
                               className="form-control"
                               value={this.state.lastname}
                               onChange={(lastname) => this.setState({lastname})}
                        />
                    </div>
                    <div className="form-group">
                        <Input type="email"
                               id={"email"}
                               name={"Email"}
                               label={"Email"}
                               placeholder={"Email"}
                               className="form-control"
                               value={this.state.email}
                               onChange={(email) => this.setState({email})}
                        />
                    </div>
                    <div className="form-group">
                        <Select name={"gender"}
                                id={"gender"}
                                label={"Gender"}
                                value={this.state.gender}
                                options={[{label: 'Male', value: 'M'}, {label: 'Female', value: 'F'}, {label: 'None', value: 'N'}]}
                                onChange={(gender) => this.setState({ gender: gender })}
                        />
                    </div>
                    <div className="form-group">
                        <Input type="phone"
                               id={"phone"}
                               className={"form-control"}
                               value={this.state.phone}
                               onChange={(phone) => this.setState({phone})}
                               name={"Phone"}
                               label={"Phone"}
                               placeholder={"Phone"}
                        />
                    </div>
                    <div className="form-group">
                        <Input type="text"
                               id={"skype"}
                               name={"Skype"}
                               label={"Skype"}
                               placeholder={"Skype"}
                               className={"form-control"}
                               value={this.state.skype}
                               onChange={(skype) => this.setState({skype})}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            type={"text"}
                            id={"title"}
                            name={"Titre"}
                            label={"Titre"}
                            placeholder={"Titre"}
                            className={"form-control"}
                            value={this.state.title}
                            onChange={title => this.setState({ title })}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            type={"text"}
                            id={"responsibility"}
                            name={"Responsabilité"}
                            label={"Responsabilité"}
                            placeholder={"Responsabilité"}
                            className={"form-control"}
                            value={this.state.responsibility}
                            onChange={responsibility => this.setState({ responsibility })}
                        />
                    </div>
                    <div className="form-group">
                        <Select name={"business_function"}
                                id={"business_function"}
                                label={"Fonction d'entreprise"}
                                value={this.state.business_function}
                                options={[
                                    {label: 'Signataire / Représentant légal', value: 'signatory_legal_representative'},
                                    {label: 'Contact de facturation', value: 'billing_contact'},
                                    {label: 'Autre', value: 'other'}
                                ]}
                                onChange={business_function => this.setState({ business_function })}
                        />
                    </div>
                    <button className="btn btn-primary" onClick={this.handleNewContact}>Nouveau contact</button>
                </FormProvider>
            </Modal>
        )
    }
}

CustomerAddContactModal.defaultProps = {
    id: null,
    onClose: false
};

export default connect(({ users }) => ({ users }), {getEveryUsers, createContact})(CustomerAddContactModal);
