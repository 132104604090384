import React, {Component} from 'react';

import './Tabs.scss'

export default class Tabs extends Component {
    render() {
        return (
            <div className="Tabs mb-3">
                {this.props.tabs.map((o) => (
                    <div
                        onClick={() => this.props.onChange(o.key)}
                        className={"tab " + (o.key === this.props.active ? 'active' : '')} key={o.key}>{o.label}</div>
                ))}
            </div>
        )
    }
}
