import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";

class DeleteDriveFilesModal extends Component {
    state = {};

    render() {
        return (
            <Modal title="Delete selection?" onClose={this.props.onClose}>
                {this.renderForm()}
            </Modal>
        );
    }

    renderForm() {
        return (
            <div style={{textAlign: 'center'}}>
                <h5>You are about to permanently delete the files you have selected.</h5>
                <h6>This action cannot be undone, are you sure you want to proceed?</h6>

                <div className="mt-4">
                    <button onClick={this.props.onConfirm} className="btn btn-danger mr-2"><i className="fa fa-trash mr-1"></i> Yes, permanently delete</button>
                    <button onClick={this.props.onClose} className="btn btn-primary"><i className="fa fa-times mr-1"></i> No, that was a close one!</button>
                </div>
            </div>
        )
    }
}

export default connect(null, {})(DeleteDriveFilesModal)
