import React, {Component} from "react";
import {getCustomer, unlinkContact} from '../data/customers';

import {getNotesForCustomer} from '../data/notes';
import {connect} from 'react-redux';
import CustomerAddContactModal from "../components/customers/CustomerAddContactModal";
import Note from '../components/notes/Note'
import NoteAddModal from '../components/notes/NoteAddModal';
import CustomerDeleteModal from "../components/customers/CustomerDeleteModal";
import CustomerUpdateModal from "../components/customers/CustomerUpdateModal";
import {withRouter} from "react-router-dom";
import Loader from "../components/Loader";
import Empty from "../components/Empty";
import ContentLoader from "../components/ContentLoader";
import Item from "../components/Item";
import Informations from "../components/Informations";
import PrivilegesButton from "../components/privileges/PrivilegesButton";
import {getMissions} from "../data/missions";
import MissionAddModal from "../components/missions/MissionAddModal";
import Tabs from "../components/Tabs";
import Drive from "../components/drive/Drive";
import {Link} from 'react-router-dom';

const BUSINESS_FUNCTION_TO_USER = {
    'signatory_legal_representative': 'Signataire / Représentant légal',
    'billing_contact': 'Contact de facturation',
    'other': 'Autre'
}

class CustomerPage extends Component
{
    state = {
        id: parseInt(this.props.match.params.id),
        data: null,
        notes: null,
        missions: null,
        showNoteAddModal : false,
        showDeleteModal: false,
        showUpdateModal: false,
        showCreateContactModal: false,
        tab: 'informations'
    };

    componentDidMount()
    {
        const customer = this.props.customers.index.find((customer) => customer.id === this.state.id);
        if (!customer || !customer.fullInformations) this.loadCustomer();
        else this.setState({ data: customer });
        this.getNotes();
        this.getMissions(0, 'true', 'false', 'false', 'true');
    }

    componentWillReceiveProps (props) {
        const customer = props.customers.index.find((customer) => customer.id === this.state.id);
        if (customer && customer.fullInformations) this.setState({ data: customer });

        const notes = props.notes.index.filter((note) => note.customer_id == this.state.id)
        const missions = props.missions.index.filter((mission) => mission.customer && mission.customer.id == this.state.id)

        this.setState({ notes, missions })
    }

    // Data

    loadCustomer = () => {
        this.props.getCustomer(this.state.id)
            .then((res) => {
                this.setState({ data : res.payload.request.response })
            })
    };

    getNotes = () => {
        this.props.getNotesForCustomer(this.state.id);
    };

    getMissions = () => {
        this.props.getMissions();
    };

    unlink = (contact_id) => {
        const isLegalRepresentative = this.state.data.contacts.filter(c => c.id === contact_id && c.business_function === 'signatory_legal_representative').length >= 1;
        const preventLegalRepresentative = this.state.data.contacts.filter(c => c.business_function === 'signatory_legal_representative').length === 1;
        
        if (isLegalRepresentative && preventLegalRepresentative && !window.confirm("Attention, ce client n'a plus de contact de type \"Signataire / Représentant Légal\" attaché. La création de nouvelles missions et l'envoi de factures ou relances est bloquée jusqu'a l'ajout d'un nouveau contact de type Signataire / Représentant Légal"))
            return;

        this.props.unlinkContact(this.state.id, contact_id)
            .then(() => (this.props.getCustomer(this.state.id)))
            .then((res) => this.setState({ data: res.payload.request.response }))
    }

    // Render

    render = () => {
        if (!this.state.data) return <ContentLoader/>;
        return (
            <div>
                {this.renderCustomer()}
                {this.state.showNoteAddModal ? <NoteAddModal customerId={this.state.id} onClose={this.onCloseNoteAddModal} /> : null}
                {this.state.showDeleteModal ? <CustomerDeleteModal id={this.state.id} onClose={this.onDeleteModalClose} /> : null}
                {this.state.showMissionAddModal ? <MissionAddModal customerId={this.state.id} onClose={this.onCloseMissionAddModal} /> : null}
                {this.state.showUpdateModal ? <CustomerUpdateModal id={this.state.id} onClose={this.onUpdateModalClose}/> : null }
                {this.state.showCreateContactModal ? <CustomerAddContactModal id={this.state.id} onClose={this.onCreateContactModalClose}/> : null }
            </div>
        )
    };

    renderCustomer = () => {
        return (
            <div className={"customer"}>
                <header>
                    <h2 className="mb-3"><span>{this.state.data.name}</span><PrivilegesButton type="Customer" id={this.state.data.id} /></h2>
                </header>
                <Tabs
                    tabs={[
                        { key: 'informations', label: 'Informations' },
                        { key: 'drive', label: 'Drive' },
                        { key: 'notes', label: 'Notes' },
                        { key: 'missions', label: 'Missions' },
                    ]}
                    onChange={(tab) => this.setState({ tab })}
                    active={this.state.tab}
                />
                {this.renderInformationsTab()}
                {this.renderDriveTab()}
                {this.renderNotesTab()}
                {this.renderMissionsTab()}
            </div>
        )
    };

    renderInformationsTab = () => {
        if (this.state.tab !== 'informations') return null

        let informations = [];
        let stats = [];

        [
            {key: 'type', label: 'Type'},
            {key: 'siret', label: 'SIRET'},
            {key: 'address', label: 'Address'},
            {key: 'country', label: 'Pays'},
            {key: 'company_shape', label: 'Forme de l\'entreprise'},
            {key: 'social_capital', label: 'Capital Social'},
            {key: 'social_capital_currency', label: 'Devise'},
            {key: 'rcs_number', label: 'Numéro RCS'},
            {key: 'rcs_country', label: 'Ville RCS'}
        ].map((resource) => (this.state.data[resource.key] ? informations.push({ label: resource.label, value: this.state.data[resource.key]}) : null))

        return (
            <div>
                {this.renderStats(stats,informations)}
                <div className="row">
                    <div className="col-2"/>
                    <div className="col-4">
                        <button className="btn btn-info w-100" onClick={this.onUpdateModalOpen}>Update customer</button>
                    </div>
                    <div className="col-4">
                        <button className="btn btn-danger w-100" onClick={this.onDeleteModalOpen}>Delete customer</button>
                    </div>
                    <div className="col-2"/>
                </div>
                <div className="contacts" style={{'marginTop':100}}>
                    <div className="row mb-2">
                        <div className="col">
                            <h3>{this.state.data.type === "company" ? "Contacts" : "Contact" }</h3>
                        </div>
                        <div className="col text-right">
                            <button className="btn btn-info"
                                    onClick={this.onCreateContactModalOpen}
                            >
                                Add a contact
                            </button>
                        </div>
                    </div>
                    { this.state.data.contacts.map((contact) => this.renderContact(contact)) }
                </div>
            </div>
        )
    };

    renderDriveTab = () => {
        if (this.state.tab !== 'drive') return null

        return (
            <div className="drive mb-5">
                <Drive hash={"Customer-" + this.state.id} router={false}/>
            </div>
        )
    };

    renderNotesTab = () => {
        if (this.state.tab !== 'notes') return null

        return (
            <div className="notes mb-5">
                <div className="row mb-2">
                    <div className="col">
                        <h3>Notes</h3>
                    </div>
                    <div className="col text-right">
                        <Loader display={this.props.notes.loading}/>
                        <button className={"btn btn-primary"} onClick={this.onOpenNoteAddModal}>Add a note</button>
                    </div>
                </div>
                {this.renderNotes()}
            </div>
        )
    };

    renderMissionsTab = () => {
        if (this.state.tab !== 'missions') return null

        return (
            <div className="missions mb-5">
                <div className="row mb-2">
                    <div className="col">
                        <h3>Missions</h3>
                    </div>
                    <div className="col text-right">
                        <Loader display={this.props.missions.loading}/>
                        <button className="btn btn-primary" onClick={this.onOpenMissionAddModal}>Add a mission</button>
                    </div>
                </div>
                {this.renderMissions()}
            </div>
        )
    }

    renderNotes() {
        if (!this.state.notes) return <ContentLoader/>;
        if (!this.state.notes.length) return <Empty text={"No notes added"}/>;

        return this.state.notes.map((note) => this.renderNote(note))
    }

    renderNote = (note) => {
        return (
            <div className="mb-2">
                <Note key={note.id} data={note} onDelete={this.getNotes} />
            </div>
        )
    };

    renderStats = (stats, informations) =>{
        return (
            <div className="card mb-2" >
                <div className="card-body">
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <div className={'jumbotron'} style={{'paddingTop':25}}>
                                <h5>Informations</h5>
                                <Informations data={informations} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderContact = (contact) => {
        return (
            <div className="card mb-2" key={contact.id}>
                <div className="card-body">
                    <button onClick={() => this.unlink(contact.id)} className={'float-right btn btn-danger mr-2'}>
                        Unlink contact
                    </button>
                    <Link to={'/contacts/' + contact.id} className="float-right">
                        <button className="btn btn-info mr-2">See user page</button>
                    </Link>
                    <h5 className="card-title">{contact.firstname} {contact.lastname}</h5>
                    <p className="card-text">Mail: {contact.email ? contact.email : "N/A"}</p>
                    <p className="card-text">Phone: {contact.phone ? contact.phone : "N/A"}</p>
                    <p className="card-text">Skype: {contact.skype ? contact.skype : "N/A"}</p>
                    <p className="card-text">Fonction d'entreprise : {contact.business_function ? BUSINESS_FUNCTION_TO_USER[contact.business_function] : "N/A"}</p>
                </div>
            </div>
        )
    };

    renderMissions = () => {
        if (!this.state.missions) return <ContentLoader/>
        if (!this.state.missions.length) return <Empty text={"No missions added"}/>

        return this.state.missions.map((mission) => this.renderMission(mission))
    }

    renderMission = (mission) => {
        return (
            <div className={"mb-2"}>
                <Item to={"/missions/" + mission.id} title={mission.name} />
            </div>
        )
    }

    // Events
    onDeleteModalOpen = () => this.setState({ showDeleteModal: true });
    onDeleteModalClose = (deleted) => {
        this.setState({ showDeleteModal: false });
        if (deleted) this.props.history.push('/customers')
    }

    onUpdateModalOpen = () => this.setState({ showUpdateModal: true });

    onUpdateModalClose = (update) => {
        if (update)
            this.loadCustomer();
        this.setState({ showUpdateModal: false });
    };

    onOpenNoteAddModal = () => this.setState({ showNoteAddModal: true });

    onCreateContactModalOpen = () => this.setState({ showCreateContactModal: true });

    onCreateContactModalClose = (update) => {
        if (update)
            this.loadCustomer();
        this.setState({ showCreateContactModal: false })
    };

    onCloseNoteAddModal = (updateNeeded) => {
        this.setState({ showNoteAddModal: false }, () => {
            if (updateNeeded) this.getNotes()
        });
    }

    onOpenMissionAddModal = () => this.setState({ showMissionAddModal : true });

    onCloseMissionAddModal = () => this.setState({ showMissionAddModal : false })

}

export default connect(({customers,notes,missions}) => ({customers,notes,missions}), {getCustomer,getNotesForCustomer,getMissions, unlinkContact})(withRouter(CustomerPage));
