import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {getUsers} from "../data/users";
import {getCustomers} from "../data/customers";
import moment from 'moment';
import 'moment/locale/fr';
import {getMissions, getMissionsofWeek} from "../data/missions";
import MissionAnaysis from "../components/statistiques/MissionAnaysis";
import SelectSearch from "react-select";

moment.locale('fr');

class StatsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            missions: [],
            customer: {value: 0},
            recurring: false,
            all_customers: []
        };
    }

    renderCustomersOptions(customers) {
        return customers.map(customer => ({ value : customer.id, label : customer.name }))
    }

    loadCustomers = () => {
        this.props.getCustomers().then((callback) => {
            this.setState({all_customers: callback.payload.data});
        })
    }

    changeRecurringFilterStatus = () => {
        this.setState({recurring: !this.state.recurring}, () => {
            this.loadMissions()
        })
    }

    loadMissions = () => {
        this.props.getMissions(this.state.customer.value, this.state.recurring, true, 'only-ongoing', 'true').then( (callback) => {
            this.setState({missions: callback.payload.data})
        });
    }

    componentDidMount()
    {
        this.props.getUsers(true)
        this.loadMissions()
        this.loadCustomers()
    }

    render() {
        return (
            <div>
                <h1>
                    Analyse des missions
                </h1>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <p style={{cursor: 'pointer',display:'inline-block'}} onClick={ () => { this.changeRecurringFilterStatus() } }><em>Montrer les missions {this.state.recurring ? 'classiques' : 'récurrentes' }</em></p>
                        <p style={{cursor: 'pointer',display:'inline-block', marginLeft: '10px'}}>
                            <i className={'fa fa-sync'} style={{color: 'darkred'}} onClick={() => this.setState({recurring:false, customer: {value: 0} }, this.loadMissions)}/>
                        </p>
                    </div>
                </div>


                <div className={'row kpi-header'}>
                    <div className={'col-md-2 text-center'}>
                        <SelectSearch
                            name={"Client"}
                            value={this.state.customer}
                            options={this.renderCustomersOptions(this.state.all_customers)}
                            placeholder="Select a Customer"
                            searchable={false}
                            onChange={(d) => {this.setState({customer: d},this.loadMissions)}}
                            style={{float:'right'}}
                        />
                    </div>
                    <div className={'col-md-2 text-center'}>
                        <i className="fal fa-euro-sign"/>
                        <br/>
                        Suis-je rentable ?
                        <br/>
                        <em>j.passés / j.vendus</em>
                    </div>
                    <div className={'col-md-2 text-center'}>
                        <i className="fad fa-rabbit-fast"/>
                        <br/>
                        Suis-je en retard ?
                        <br/>
                        <em>j.passés / j.prévus</em>
                    </div>
                    <div className={'col-md-2 text-center'}>
                        <i className="fad fa-users-class"/>
                        <br/>
                        Suis-je suffisamment staffé ?
                        <br/>
                        <em>j. de travail restant estimé / j.restants plannifiés  </em>
                    </div>
                    <div className={'col-md-2 text-center'}>
                        <i className="fad fa-alarm-exclamation"/>
                        <br/>
                        Deadline OK ?
                        <br/>
                        <em>Dernier j. plannifié / Deadline</em>
                    </div>
                    <div className={'col-md-2 text-center'}>
                        Action
                    </div>
                </div>

                {this.state.missions ?
                    this.state.missions.map( (mission) => {
                        return (
                                <div>
                                    {
                                        mission.customer.name != "Bluesquare" ?
                                            <MissionAnaysis mission={mission} key={mission.id} />
                                        :
                                            null
                                    }
                                </div>
                        )
                    })
                    :
                    null
                }
            </div>
        )
    }
}

export default connect(({ users }) => ({ users }), {getUsers, getMissions, getMissionsofWeek, getCustomers})(withRouter(StatsPage));
