import React, {Component} from 'react';
import Modal from "./Modal";
import Loader from "./Loader";
import PlainCheckbox from "./PlainCheckbox";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class TextEditorModal extends Component {
    state = {
        editorText: "",
        editor: null,
        loaded: false,
        reset: false
    };

    componentDidMount() {
        if (this.props.dataFunc === null) {
            this.setState({
                loaded: true,
                editorText: ""
            });
        }
        else {
            this.props.dataFunc()
                .then((r) => {
                    let body = r.payload.data.body;
                    if(body == null)
                        body = " ";

                    this.setState({
                        editorText: body,
                        loaded: true
                    });
                });
        }
    }

    render() {
        return (
            <Modal title="Edit text" onClose={() => this.props.onClose()}>
                { this.renderBody() }
            </Modal>
        )
    }

    renderBody = () => {
        if (this.state.loaded)
        {
            return <div>
                <CKEditor
                    editor={ClassicEditor}
                    data={this.state.editorText}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        this.setState({editor});
                    } }
                />
                <div className="mt-3">
                    <button className="btn btn-info" onClick={this.sendToDatabase}>Confirm</button>
                    <div className="float-right">
                        { this.props.showEmptyText ? <PlainCheckbox label="Reset to default" value={this.state.reset} onChange={(boolval) => this.setState({reset: boolval})}/> : '' }
                    </div>
                </div>
            </div>
        }
        else
        {
            return <Loader display={true}/>;
        }
    };

    sendToDatabase = () => {
        let body = this.state.editor.getData();
        if (this.state.reset)
            body = null;


        if (this.props.uploadFunc === null)
            return this.props.onClose(body);

        this.setState({loaded: false});
        this.props.uploadFunc(body).then((r) => {
            this.props.onClose(body);
        });
    }
}

TextEditorModal.defaultProps = {
    onClose: () => {},
    dataFunc: null,
    uploadFunc: null,
    showEmptyText: false
};
