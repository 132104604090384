import React, {Component} from 'react';
import ContentLoader from "../ContentLoader";
import MissionCalendarPhaseUpdateModal from "../missionCalendarPhase/MissionCalendarPhaseUpdateModal";
import {getMissionCalendarPhase} from "../../data/missionCalendarPhases";
import {connect} from 'react-redux';
import MissionCalendarPhaseDeleteModal from "./MissionCalendarPhaseDeleteModal";

class MissionCalendarPhase extends Component
{
    state = {
        id: this.props.id,
        phase: null,
        showDeleteModal: false,
        showUpdateModal: false,
        showAddPhaseModal: false,
    };

    componentDidMount() {
        this.getMissionCalendarPhase();
    }

    getMissionCalendarPhase = () => {

        this.props.getMissionCalendarPhase(this.state.id)
            .then((res) => {
                this.setState({phase : res.payload.request.response})
            })
    };

    render() {

        if (!this.state.phase)
            return <ContentLoader/>;

        const cursorPointer = {
            cursor: 'pointer'
        };
        const cardHeader = {
            background: "#B4B4BB"
        };

        const { phase } = this.state;

        return (
            <div className="card mb-3">
                <div className={"card-header"} style={cardHeader}>
                    <div className={"row"}>
                        <div className={"col-md-8"}>
                            <h5 className={"card-title d-inline-block mt-2"}>{phase.name}</h5>
                        </div>
                        <div className={"d-inline-block col-md-2"}>
                            <div className={"float-right mt-2"}>
                                <span style={cursorPointer} onClick={this.movePositionUp}>
                                    <i className={"fas fa-angle-up mr-3"}></i>
                                </span>
                                <span style={cursorPointer} onClick={this.movePositionDown}>
                                    <i className={"fas fa-angle-down"}></i>
                                </span>
                            </div>
                        </div>
                        <div className={"col-md-2 d-inline-block"}>
                            <div className={"float-right"}>
                                <button onClick={this.toggleDeleteModal} className="btn btn-danger mr-1">
                                    <i className="fa fa-trash"></i>
                                </button>
                                <button onClick={this.toggleUpdateModal} className="btn btn-secondary">
                                    <i className="fa fa-edit"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <p>{phase.description}</p>
                    {/*<pre>{phase.content}</pre>*/}
                </div>
                {this.state.showUpdateModal ?
                    <MissionCalendarPhaseUpdateModal
                        id={this.props.id}
                        name={phase.name}
                        description={phase.description}
                        content={phase.content}
                        starting_at={phase.starting_at ? phase.starting_at.date : null}
                        ending_at={phase.ending_at ? phase.ending_at.date : null}
                        onClose={(updated) => this.toggleUpdateModal(updated)}
                    />
                    : null}
                {this.state.showDeleteModal ?
                    <MissionCalendarPhaseDeleteModal
                        id={this.props.id}
                        onClose={(deleted) => this.toggleDeleteModal(deleted)}
                    />
                    : null
                }
            </div>
        )
    }

    movePositionUp = () => {
        // this.props.modulePosition(this.props.data.id, this.props.data.position - 1, this.props.missionId)
        //     .then(() => {
        //         this.props.onMoved()
        //     })
    };

    movePositionDown = () => {
        // this.props.modulePosition(this.props.data.id, this.props.data.position + 1, this.props.missionId)
        //     .then(() => {
        //         this.props.onMoved()
        //     })
    };

    toggleDeleteModal = (deleted) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal });
        if (deleted) this.props.onDelete()
    };

    toggleUpdateModal = (updated) => {
        this.setState({ showUpdateModal: !this.state.showUpdateModal });
        if (updated) this.getMissionCalendarPhase();
    };
}

export default connect(null, {getMissionCalendarPhase})(MissionCalendarPhase);

