import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getCustomers} from '../data/customers';
import CustomerAddModal from "../components/customers/CustomerAddModal";
import Loader from "../components/Loader";
import PrivilegesButton from "../components/privileges/PrivilegesButton";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {Link} from "react-router-dom";

class CustomersPage extends Component
{
    state = {
        showAddModal: false,
        search: ''
    };

    COLOR_STATUS = {
        pitch: 'btn-info',
        recurring: 'btn-warning',
        ongoing: 'btn-warning',
        closed: 'btn-success'
    }

    componentDidMount() {
        this.props.getCustomers();
        this.actionFormatter = this.actionFormatter.bind(this);
    };

    actionFormatter(cell, row) {
        return (
            <button className={'btn btn-primary'} onClick={() => this.props.history.push('/customers/' + cell)}>
                Voir
            </button>
        );
    }

    formatName(cell, row) {
        return (
            <Link to={'/missions?company_name=' + cell} target={"_blank"}>
                {cell}
            </Link>
        )
    }

    renderColorStatus(status){
        return this.COLOR_STATUS[status] ? this.COLOR_STATUS[status] : 'btn-secondary';
    }

    statusFormatter = (cell) => {
        return <button className={'btn '+this.renderColorStatus(cell) }>{cell}</button>
    }

    render = () => {
        return(
            <div>
                { this.renderLinks() }
                { this.state.showAddModal ? <CustomerAddModal onClose={this._onAddModalClose}/> : null }
                {
                    this.props.customers &&
                    this.props.customers.index
                    ? (
                        <BootstrapTable data={this.props.customers.index}
                                        striped
                                        hover
                                        condensed
                                        search
                                        exportCSV
                                        pagination
                                        className={"customer"}
                        >
                            <TableHeaderColumn isKey dataField='id' dataSort={true}>#</TableHeaderColumn>
                            <TableHeaderColumn dataField='name' dataSort={true} dataFormat={this.formatName}>Nom</TableHeaderColumn>
                            <TableHeaderColumn dataField='email' dataSort={true}>Email</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' dataSort={true} dataFormat={this.actionFormatter}>Action</TableHeaderColumn>
                        </BootstrapTable>
                    )
                    : null
                }
            </div>
        )
    };

    renderLinks = () => {
        return (
            <div className={"row"}>
                <div className="col">
                    <h2><span>Customers</span> <PrivilegesButton type="Customer" /></h2>
                </div>
                <div className="col text-right">
                    <Loader display={this.props.customers.loading}/>
                    <button className={"btn btn-primary float-right"} onClick={this._onAddModalOpen}>Add customer</button>
                </div>
            </div>
        )
    };

    _onAddModalOpen = () => this.setState({ showAddModal: true });

    _onAddModalClose = (created) => {
        this.setState({ showAddModal: false });
        if (created) this.props.getCustomers()
    };
}

export default connect(({customers}) => ({customers}), {getCustomers})(CustomersPage)
