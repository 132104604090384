import {request} from '../helpers'




// Fetch all invoices

export const INVOICES_GET = 'INVOICES_GET';
export const INVOICES_GET_SUCCESS = 'INVOICES_GET_SUCCESS';
export const INVOICES_GET_FAIL = 'INVOICES_GET_FAIL';
export function getInvoices()
{
    return request(INVOICES_GET, 'get', '/invoices/');
}


// Fetch invoice

const MISSION_FETCH = 'MISSION_FETCH'

export function getInvoice(id)
{
    return request(MISSION_FETCH, 'get', '/invoice/' + id)
}


// Fetch unpaid invoices

const UNPAID_INVOICES_GET = 'UNPAID_INVOICES_GET'
export const UNPAID_INVOICES_GET_SUCCESS = 'UNPAID_INVOICES_GET_SUCCESS';
export const UNPAID_INVOICES_GET_FAIL = 'UNPAID_INVOICES_GET_FAIL';

export function getUnpaidInvoices()
{
    return request(UNPAID_INVOICES_GET, 'get', '/unpaid-invoices')
}


// Update a Invoice

export const INVOICES_UPDATE = 'INVOICES_UPDATE';
export const INVOICES_UPDATE_SUCCESS = 'INVOICES_UPDATE_SUCCESS';
export const INVOICES_UPDATE_FAIL = 'INVOICES_UPDATE_FAIL';

export function updateInvoice(id, created_at, emitted_at, due_date, payed_at, amount_ht, tax, description,reference, amount_ttc, randomString, file, email)
{
    const data = {id, created_at, emitted_at, due_date, payed_at, amount_ht, tax, description,reference, amount_ttc, randomString, file, email };
    return request(INVOICES_UPDATE, 'post', '/invoices/' + id, data, {id})
}

// Create a Invoice
export const INVOICES_CREATE = 'INVOICES_CREATE';
export const INVOICES_CREATE_SUCCESS = 'INVOICES_CREATE_SUCCESS';
export const INVOICES_CREATE_FAIL = 'INVOICES_CREATE_FAIL';
export function createInvoice(mission, amount_ttc,description,file)
{
    const data = {mission, amount_ttc, description,file};
    return request(INVOICES_CREATE, 'put', '/invoices', data);
}

//Send Email
export const MAIL_SENT = 'MAIL_SENT';
export const MAIL_SENT_SUCCESS = 'MAIL_SENT_SUCCESS';
export function sendEmail(invoice,templateId){
    var id = invoice.id
    const data = {'id':id,'templateId':templateId};
    return request(MAIL_SENT, 'post','/send-email',data,{id});
}

// Get associate email from invoice
export const INVOICES_ASSOCIATE_EMAILS = 'INVOICES_ASSOCIATE_EMAILS';
export function getInvoiceAssociateEmails(invoice_id) {
    return request(INVOICES_ASSOCIATE_EMAILS, 'get', `/associate-email/${invoice_id}`);
}

export const INVOICES_DELETE_SUCCESS = 'INVOICES_DELETE_SUCCESS';

const defaultState = {
    index: [],
    unpaidInvoices: [],
    loading: false,
    lastUpdate : null
};
// Reducer

export default function invoicesReducer(state = defaultState, action)
{
    let index;
    let invoiceKey;

    switch(action.type)
    {

        case INVOICES_CREATE:
        case INVOICES_UPDATE:
            return { ...state, loading: true};

        case INVOICES_UPDATE_FAIL:
            return { ...state, loading: false};


        case INVOICES_GET_SUCCESS:
            return { ...state, index: action.payload.data, loading: false, lastUpdate: new Date() };

        case UNPAID_INVOICES_GET_SUCCESS:
            return { ...state, unpaidInvoices: action.payload.data, loading: false, lastUpdate: new Date() };

        case INVOICES_CREATE_SUCCESS:
            index = state.index;
            index.push(action.payload.data.invoice);
            return { ...state, index, loading: false, lastUpdate: new Date() };

        case INVOICES_UPDATE_SUCCESS:
            invoiceKey = state.index.findIndex( (invoice) => invoice.id == action.meta.previousAction.id);
            index = state.index;
            index[invoiceKey] = action.payload.data.invoice;
            return { ...state, index, loading: false, lastUpdate: new Date() };


        case INVOICES_DELETE_SUCCESS:
            index = state.index.filter((invoice) => invoice.id !== action.meta.previousAction.id);
            return { ...state, index, loading: false };


        case MAIL_SENT_SUCCESS:
            invoiceKey = state.index.findIndex( (invoice) => invoice.id == action.payload.data.invoice.id);
            index = state.index;
            index[invoiceKey] = action.payload.data.invoice;
            return { ...state, loading: false, lastUpdate: new Date() };

        default:

    }


    return state;
}
