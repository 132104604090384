import React, {Component} from "react";
import {getUser} from '../data/users';
import {getNotesForUser} from '../data/notes';
import {getUserEvents, deleteUserEvents} from '../data/userEvents';
import {connect} from 'react-redux';
import Note from '../components/notes/Note'
import NoteAddModal from '../components/notes/NoteAddModal';
import {withRouter} from "react-router-dom";
import {chunks} from "../helpers";
import Loader from "../components/Loader";
import Empty from "../components/Empty";
import ContentLoader from "../components/ContentLoader";
import PrivilegesButton from "../components/privileges/PrivilegesButton";
import Drive from "../components/drive/Drive";
import Informations from "../components/Informations";
import Item from "../components/Item";
import moment from "moment";
import UserUpdateModal from "../components/users/UserUpdateModal";
import UserEventsAddModal from "../components/userEvents/UserEventsAddModal";
import {Timeline, TimelineBlip} from "react-event-timeline";

class UserPage extends Component
{
    state = {
        id: parseInt(this.props.match.params.id),
        data: null,
        notes: null,
        userEvents: [],
        events: [],
        showNoteAddModal : false,
        showUpdateModal : false,
        showUserEventsAddModal: false
    };

    componentDidMount()
    {
        this.loadUser();

        this.getNotes();

        this.getUserEvents();
    }

    // Data

    loadUser = () => {
        this.props.getUser(this.state.id)
            .then((res) => {
                this.setState({ data : res.payload.data })
            })
            .catch((data) => {
                console.log(data)
            });
    };

    getUserEvents = () => {
        this.props.getUserEvents(this.state.id)
            .then(res => {
                const { events, userEvents } = res.payload.data;

                this.setState({ events, userEvents });
            })
            .catch(err => console.log(err));
    };

    getNotes = () => {
        this.props.getNotesForUser(this.state.id).then( (callback) => {
            this.setState({notes: callback.payload.data})
        });
    };

    _handleDeleteUserEvents = (userEvent_id) => {
        const { userEvents } = this.state;

        if (window.confirm("Etes vous sur de vouloir supprimer cet evenement ?"))
            this.props.deleteUserEvents(userEvent_id)
                .then(res => {
                    console.log("res delete > ", res);

                    const newUserEvents = userEvents.filter(userEvent => userEvent_id !== userEvent.id);

                    this.setState({
                        userEvents: newUserEvents
                    })
                })
                .catch(err => console.log(err));
    };

    // Render

    render = () => {
        if (!this.state.data) return <ContentLoader/>
        return (
            <div>
                {this.renderUser()}
                {this.state.showNoteAddModal ? <NoteAddModal userId={this.state.id} onClose={this.onCloseNoteAddModal} /> : null}
                {this.state.showUpdateModal ? <UserUpdateModal data={this.state.data} onClose={this.onUpdateModalClose} onUpdated={this.loadUser}/> : null}
                {this.state.showUserEventsAddModal ? <UserEventsAddModal userId={this.state.id} onClose={this.onCloseUserEventsModal} events={this.state.events}/> : null}
            </div>
        )
    };

    renderUser = () => {

        return (
            <div className={"user"}>
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="mb-3"><span>{this.state.data.firstname}</span> <PrivilegesButton type="User" id={this.state.data.id} /></h2>
                        {this.state.data ?
                            <Item title={"General"}>
                                <Informations data={[
                                    { label: 'Email', value: this.state.data.email },
                                    { label: 'Téléphone', value: this.state.data.phone },
                                    { label: 'Skype', value: this.state.data.skype },
                                    { label: 'Adresse', value: this.state.data.address ? this.state.data.address : null },
                                    { label: 'Code postal', value: this.state.data.zipcode ? this.state.data.zipcode : null },
                                    { label: 'Ville', value: this.state.data.city ? this.state.data.city : null },
                                    { label: 'Né.e le', value: this.state.data.birthdate ? moment(this.state.data.birthdate.date).format('DD-MM-YYYY') : null },
                                    { label: 'Sécurité sociale', value: this.state.data.social_security_number ? this.state.data.social_security_number : null },
                                    { label: 'Nom marital', value: this.state.data.marital_name ? this.state.data.marital_name : null },
                                    { label: 'Nationalité', value: this.state.data.nationality ? this.state.data.nationality : null },
                                ]} />
                            </Item>
                            : null
                        }

                        <button className="btn btn-secondary w-100" onClick={this.onUpdateModalOpen}>Edit user</button>
                        {this.renderWarnings()}
                        <div className="row mt-5">
                            <div className="col">
                                <h3>Events</h3>
                            </div>
                            <div className="col text-right">
                                <Loader display={this.state.userEvents && this.state.userEvents.loading}/>
                                <button className="btn btn-primary" onClick={this.onOpenUserEventsModal}>Add an event</button>
                            </div>
                        </div>
                        { this.renderUserEvents() }
                    </div>
                    <div className="col-md-8">
                        <div className="notes mb-5">
                            <div className="row mb-2">
                                <div className="col">
                                    <h3>Notes</h3>
                                </div>
                                <div className="col text-right">
                                    <Loader display={this.state.notes && this.state.notes.loading}/>
                                    <button className={"btn btn-primary"} onClick={this.onOpenNoteAddModal}>Add a note</button>
                                </div>
                            </div>
                            {this.renderNotes()}
                        </div>
                        <div className="drive mb-5">
                            <h3>Drive</h3>
                            <Drive hash={"User-" + this.state.id} router={false}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    //

    renderNotes() {
        if (!this.state.notes) return <ContentLoader/>
        if (!this.state.notes.length) return <Empty text={"No notes added"}/>

        return chunks(this.state.notes, 2).map((chunk) => this.renderNoteRow(chunk))
    }

    renderNoteRow = (notes) => {
        return notes.map((note) => this.renderNote(note))
    };

    renderNote = (note) => {
        return (
            <div className="mb-2">
                <Note key={note.id} data={note} onDelete={this.getNotes} />
            </div>
        )
    };

    renderUserEvents() {
        if (!this.state.userEvents) return <ContentLoader/>;
        if (!this.state.userEvents.length) return <Empty text={"No events added"}/>

        return (
            <Timeline>
                {
                    this.state.userEvents.map(userEvent => {
                        console.log("userEvent > ", userEvent);

                        return this.renderUserEvent(userEvent)
                    })
                }
            </Timeline>
        );
    }

    renderUserEvent(userEvent) {
        const title = ((userEvent.informations) ? userEvent.informations + " " : "") + userEvent.event.name + " du "+ userEvent.starting_at + " au " + userEvent.ending_at;
        return (
            <TimelineBlip key={'ue-' + userEvent.id}
                          icon={<i className={"fas fa-" + userEvent.event.icon}/>}
                          title={title}
                          created_at={userEvent.created_at}
                          iconColor={"#007bff"}
                          style={{ cursor: 'pointer'}}
                          onClick={() => this._handleDeleteUserEvents(userEvent.id)}
            />
        )
    }

    renderWarnings = () => {
        let warnings = this.state.data.warnings;
        if (warnings)
            return (
                <div className={"mt-3"}>
                    {warnings.map((warning) => this.renderWarning(warning))}
                </div>
            );
        return null;
    };

    renderWarning = (warning) => {
        const stylesWarning = {
            color: 'red',
        };
        return (
            <div className={"text-center"}>
                <span style={stylesWarning}>{warning.message}</span>
            </div>
        )
    };

    // Events

    onOpenNoteAddModal = () => this.setState({ showNoteAddModal: true });

    onCloseNoteAddModal = (updateNeeded) => {
        this.setState({ showNoteAddModal: false }, () => {
            if (updateNeeded) this.getNotes()
        });
    };

    onUpdateModalOpen = () => {
        this.setState({ showUpdateModal: true })
    };

    onUpdateModalClose = () => {
        this.setState({ showUpdateModal: false })
    };

    onOpenUserEventsModal = () => this.setState({ showUserEventsAddModal: true });

    onCloseUserEventsModal = (userEvent) => {
        const { userEvents } = this.state;

        if (userEvent)
            userEvents.push(userEvent);

        this.setState({
            showUserEventsAddModal: false,
            userEvents
        });
    };
}

export default connect(({}) => ({}), {getUser, getNotesForUser, getUserEvents, deleteUserEvents})(withRouter(UserPage));
