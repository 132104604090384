import {request} from '../helpers'


// Get all employees users
export const USERS_GET = 'USERS_GET';
export const USERS_GET_FAIL = 'USERS_GET_FAIL';
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';

export function getUsers(only_active)
{
    return request(USERS_GET, 'get', '/users/active/' + only_active)
}

// Get every users
export const USERS_EVERY_GET = 'USER_EVERY_GET'
export const USERS_EVERY_GET_SUCCESS = 'USERS_EVERY_GET_SUCCESS'

export function getEveryUsers()
{
    return request(USERS_EVERY_GET, 'get', '/users');
}

// Get one user
export const USER_GET = "USER_GET";
export const USER_GET_FAIL = "USER_GET_FAIL";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export function getUser(id)
{
    return request(USER_GET, 'get', '/users/' + id, {}, { id })
}


// Get all contacts
export const CONTACTS_GET = 'CONTACTS_GET';
export const CONTACTS_GET_FAIL = 'CONTACTS_GET_FAIL';
export const CONTACTS_GET_SUCCESS = 'CONTACTS_GET_SUCCESS';

export function getContacts()
{
    return request(CONTACTS_GET, 'get', '/contacts')
}

// Get one user
export const CONTACT_GET = "CONTACT_GET";
export const CONTACT_GET_FAIL = "CONTACT_GET_FAIL";
export const CONTACT_GET_SUCCESS = "CONTACT_GET_SUCCESS";
export function getContact(id)
{
    return request(CONTACT_GET, 'get', '/contacts/' + id, {}, { id })
}

// Update a user
const USERS_UPDATE_USER = 'USERS_UPDATE_USER'
const USERS_UPDATE_USER_SUCCESS = 'USERS_UPDATE_USER_SUCCESS'
const USERS_UPDATE_USER_FAIL = 'USERS_UPDATE_USER_FAIL'

export function updateUser(id, firstname, lastname, birthdate, social_security_number, address, zipcode, city, marital_name, nationality, phone, skype, gender, email, is_active)
{
    const data = { id, firstname, lastname, birthdate, social_security_number, address, zipcode, city, marital_name, nationality, phone, skype, gender, email,is_active };

    return request(USERS_UPDATE_USER, 'post', '/users/' + id, data, {id})
}


// Update a contact
const CONTACT_UPDATE = 'CONTACT_UPDATE'
export function updateContact(contact)
{
    return request(CONTACT_UPDATE, 'post', '/contacts/' + contact.id, {...contact})
}

// DELETE a contact
const CONTACT_DELETE = 'CONTACT_DELETE'
export function deleteContact(id)
{
    return request(CONTACT_UPDATE, 'delete', '/contacts/delete/' + id)
}


// Remove customer from contact
const CUSTOMER_REMOVE = 'CUSTOMER_REMOVE'
export function removeCustomerFromContact(customerId, contactId)
{
    return request(CUSTOMER_REMOVE, 'post', '/contacts/remove-customer/' + customerId + '/' +contactId)
}

// Add customer to contact
const CUSTOMER_ADD = 'CUSTOMER_ADD'
export function addCustomerToContact(customerId, contactId)
{
    return request(CUSTOMER_ADD, 'post', '/contacts/add-customer/' + customerId + '/' +contactId)
}

// Store

const defaultState = {
    index: [],
    lastUpdate: null,
    loading: false
};

// Reducer

export default function usersReducer(state = defaultState, action)
{
    let index;

    switch(action.type)
    {
        case USERS_GET:
        case USER_GET:
        case USERS_UPDATE_USER:
            return {...state, loading: true};

        case USERS_GET_FAIL:
        case USER_GET_FAIL:
        case USERS_UPDATE_USER_FAIL:
            return {...state, loading: false};

        // Get all employee users
        case USERS_GET_SUCCESS:
            return {...state, index: action.payload.data, lastUpdate: new Date(), loading: false};

        // Get all users
        case USERS_EVERY_GET_SUCCESS:
            return {...state, index: action.payload.data, lastUpdate: new Date(), loading: false};

        // Get specific user
        case USER_GET_SUCCESS:
            index = [...state.index];

            if (index.find((user) => user.id === action.meta.previousAction.id)) {
                index.map((user) => {
                    if (user.id === action.meta.previousAction.id) {
                        user = action.payload.data;
                        user.fullInformations = true
                    }
                    return user
                })
            }

            return {
                ...state,
                index,
                loading: false
            };

        // Update a user
        case USERS_UPDATE_USER_SUCCESS:
            index = state.index.filter(( user ) => user.id !== action.meta.previousAction.id);
            index.push(action.payload.data.user);
            console.log('action', action)
            return { ...state, index, loading: false };

        default:
        //console.log("Unknown action in users.js: " + action.type);
    }
    return state
}
