import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {getContact,updateUser,updateContact,deleteContact,removeCustomerFromContact,addCustomerToContact} from "../data/users";
import {withRouter} from "react-router-dom";
import Cookies from "universal-cookie/cjs/Cookies";
import Select from "../components/Select";
import Input from "../components/Input";
import FormProvider from "../components/FormProvider";
import Drive from "../components/drive/Drive";
import SelectSearch from "react-select";
import {getCustomers} from "../data/customers";

class ContactPage extends Component
{
    state = {
        id: parseInt(this.props.match.params.id),
        auth_token: (new Cookies()).get('hub_token'),
        contact: null,
        customer: null,
        customers: [],
        loading: false
    }

    loadContact = () => {
        this.setState({loading: true});
        this.props.getContact(this.state.id).then((res) => {
            this.setState({
                contact: res.payload.data
            });
            this.setState({loading: false});
        });
    }


    loadCustomers = () => {
        this.props.getCustomers()
            .then((callback) => {
                this.setState({
                    customers: callback.payload.data
                })
            });
    }

    componentDidMount()
    {
        this.loadContact();
        this.loadCustomers();
    }


    _handleSubmit = (e) => {
        e.preventDefault();
        this.props.updateContact(this.state.contact);
    }

    renderCustomerOptions(customers){
        return customers.map(customer => ({
            value: customer.id,
            label: customer.name
        }));
    }

    removeContact = () => {
        this.props.deleteContact(this.state.contact.id)
            .then(() => {
                this.props.history.push('/contacts');
            });
    }


    removeCustomer = (customerId) => {
        this.setState({ loading: true });
        this.props.removeCustomerFromContact(customerId, this.state.contact.id)
            .then(() => {
                this.loadContact();
            })
    }

    addCustomer = () => {
        this.setState({ loading: true });
        this.props.addCustomerToContact(this.state.customer.value, this.state.contact.id)
            .then(() => {
                this.loadContact();
            })
    }


    render = () => {
        return (
            <div>
                {
                    this.state.contact ?
                        <div>
                            <h1>Fiche de {this.state.contact.firstname} {this.state.contact.lastname}</h1>
                            <div className={'row'}>
                                <div className={'col-md-4'}>
                                    <h2>Information</h2>
                                    <FormProvider onSubmit={this._handleSubmit} >
                                        <div className="form-group">
                                            <Input type="text"
                                                   id={"firstname"}
                                                   name={"Firstname"}
                                                   label={"Firstname"}
                                                   placeholder={"Firstname"}
                                                   className="form-control"
                                                   value={this.state.contact.firstname}
                                                   onChange={(firstname) => this.setState({contact: {...this.state.contact, firstname}})}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Input type="text"
                                                   id={"lastname"}
                                                   name={"Lastname"}
                                                   label={"Lastname"}
                                                   placeholder={"Lastname"}
                                                   className="form-control"
                                                   value={this.state.contact.lastname}
                                                   onChange={(lastname) => this.setState({contact: {...this.state.contact, lastname}})}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Input type="email"
                                                   id={"email"}
                                                   name={"Email"}
                                                   label={"Email"}
                                                   placeholder={"Email"}
                                                   className="form-control"
                                                   value={this.state.contact.email}
                                                   onChange={(email) => this.setState({email: {...this.state.contact, email}})}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Select name={"gender"}
                                                    id={"gender"}
                                                    label={"Gender"}
                                                    value={this.state.contact.gender}
                                                    options={[{label: 'Male', value: 'M'}, {label: 'Female', value: 'F'}, {label: 'None', value: 'N'}]}
                                                    onChange={(gender) => this.setState({contact: {...this.state.contact, gender}})}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Input type="phone"
                                                   id={"phone"}
                                                   className={"form-control"}
                                                   value={this.state.contact.phone}
                                                   onChange={(phone) => this.setState({contact: {...this.state.contact, phone}})}
                                                   name={"Phone"}
                                                   label={"Phone"}
                                                   placeholder={"Phone"}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Input type={'text'}
                                                   id={'title'}
                                                   className={'form-control'}
                                                   value={this.state.contact.title}
                                                   onChange={title => this.setState({ contact: { ...this.state.contact, title }})}
                                                   name={'Titre'}
                                                   label={'Titre'}
                                                   placeholder={'Titre'}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Input type={'text'}
                                                   id={'responsibility'}
                                                   className={'form-control'}
                                                   value={this.state.contact.responsibility}
                                                   onChange={responsibility => this.setState({ contact: { ...this.state.contact, responsibility }})}
                                                   name={'Responsabilité'}
                                                   label={'Responsabilité'}
                                                   placeholder={'Responsabilité'}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Select name={"business_function"}
                                                    id={"business_function"}
                                                    label={"Fonction d'entreprise"}
                                                    value={this.state.contact.business_function}
                                                    options={[
                                                        {label: 'Signataire / Représentant légal', value: 'signatory_legal_representative'},
                                                        {label: 'Contact de facturation', value: 'billing_contact'},
                                                        {label: 'Autre', value: 'other'}
                                                    ]}
                                                    onChange={business_function => this.setState({contact: {...this.state.contact, business_function}})}
                                            />
                                        </div>
                                        <button className="btn btn-primary btn-block">Update</button>
                                    </FormProvider>
                                    <button className="btn btn-danger btn-block mt-3" onClick={() => {this.removeContact()}}>Delete</button>
                                </div>
                                <div className={'col-md-4'}>
                                    <div className="mb-5">
                                        <h3>Customers</h3>
                                        {
                                            this.state.loading
                                            ? <i className={'fa fa-spin fa-spinner fa-2x'}/>
                                            : <div>
                                                    {
                                                        this.state.contact.customers.map(customer => (
                                                            <div>
                                                                {customer.name} <i className={'fa fa-times'} style={{color: 'darkred', cursor: 'pointer'}} onClick={() => {this.removeCustomer(customer.id)}}/>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                        }
                                        <SelectSearch
                                            name={"customer"}
                                            value={this.state.customer}
                                            options={this.renderCustomerOptions(this.state.customers)}
                                            placeholder="Select a Customer"
                                            searchable={false}
                                            onChange={(d) => {this.setState({customer: d})}}
                                        />
                                        <button className="btn btn-primary btn-block mt-3" onClick={ () => { this.addCustomer() }}>
                                            Associate Customer
                                        </button>
                                    </div>
                                </div>
                                <div className={'col-md-4'}>
                                    <div className="drive mb-5">
                                        <h3>Drive</h3>
                                        <Drive hash={"User-" + this.state.id} router={false}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : null
                }
            </div>
        )
    }

}

export default connect(({  }) => ({  }), {getCustomers,getContact,updateUser,updateContact,deleteContact,removeCustomerFromContact,addCustomerToContact})(withRouter(ContactPage));
