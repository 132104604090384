import {request} from '../helpers';


// Get root folder

export const DRIVE_GET_ROOT = 'DRIVE_GET_ROOT';

export function getFolder(uid = null, files = null)
{
    let append = '';

    if (uid)
        append += '/' + uid;

    if (files)
        append += '/true';

    return request(DRIVE_GET_ROOT, 'get', '/drive/folder/list' + append);
}

export function createFolder(currentFolderUid, name = null)
{
    return request(DRIVE_GET_ROOT, 'post', '/drive/folder/create', {folder_uid: currentFolderUid, folder_name: name});
}

export function renameFolder(currentFolderUid, name)
{
    return request(DRIVE_GET_ROOT, 'post', '/drive/folder/rename', {folder_uid: currentFolderUid, folder_name: name});
}

export function renameFile(file_uid, name)
{
    return request(DRIVE_GET_ROOT, 'post', '/drive/file/rename', {file_uid, file_name: name});
}

export function deleteResources(nodes)
{
    if (!Array.isArray(nodes))
    {
        console.warn("Attempted to delete resources but data type is not array: ", nodes);
        return;
    }

    let resources = {ressources: []};
    nodes.forEach((n) => {
        resources.ressources.push({type: n.mime, uid: n.uid})
    });

    // I know this is misspelled but tell this to the backend devs :)
    return request(DRIVE_GET_ROOT, 'delete', '/drive/ressources/delete', resources);
}

export function moveResources(nodes, target_dir_uid)
{
    let uids = [];
    nodes.forEach((n) => {
        uids.push({uid: n.uid, type: n.mime});
    });
    const resources = {ressources: uids, folder_uid: target_dir_uid};
    return request(DRIVE_GET_ROOT, 'POST', '/drive/ressources/move', resources);
}

export function uploadFiles(fileList, folder_uid)
{
    return request(DRIVE_GET_ROOT, 'POST', '/drive/upload', {folder_uid, files: fileList});
}

export function downloadFiles(nodes)
{
    let data = [];
    nodes.forEach((n) => {
        data.push({type: n.mime, uid: n.uid});
    });

    data = {ressources: data};

    return request(DRIVE_GET_ROOT, 'POST', '/drive/compress', data);
}
