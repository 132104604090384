import React, {Component} from 'react'
import {FormConsumer} from "./FormProvider";

export default class Textarea extends Component
{

    render()
    {
        return (
            <FormConsumer>
                {({ errors }) => this.renderTextarea(errors)}
            </FormConsumer>
        )
    }

    // A compléter, ne fonctionne pas très bien !
    renderTextarea(errors)
    {
        const error = errors && errors[this.props.name] ? errors[this.props.name] : null
        return (
            <div className="form-group">
                <label htmlFor={this.props.id ? this.props.id : 'textarea_'+this.props.name}>{this.props.label}</label>
                <textarea
                    id={this.props.id ? this.props.id : 'textarea_'+this.props.name}
                    className={"form-control" + (error ? ' is-invalid' : '')}
                    placeholder={this.props.placeholder ? this.props.placeholder : this.props.label}
                    value={this.props.value}
                    onChange={(e) => this.props.onChange(e.target.value)}
                    required={this.props.required}
                    rows={this.props.rows}
                    cols={this.props.cols}
                    autoFocus={this.props.autoFocus}
                    readOnly={this.props.readonly}
                />
                {error ? <div className="invalid-feedback">{error.key}</div> : null}
            </div>
        )
    }
}

Textarea.defaultProps = {
    name: 'input',
    id: null,
    placeholder: null,
    value: '',
    onChange: () => {},
    autoFocus: false,
    required: false,
    label: 'Textarea',
    rows: 5,
    cols: "100",
    readonly: false
}
