import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";
import DirectoryPicker from "./DirectoryPicker";
import {getFolder} from "../../data/drive";

class MoveSelectionModal extends Component {
    state = {};
    selected = null;

    requestDirContents = (node) => {
        if (node && node.loaded) {
            return;
        }

        node.loading = true;
        this.props.getFolder(node.uid)
            .then((r) => {
                let receivedData = r.payload.data.folders;

                receivedData.forEach((e) => {
                    node.children.push({
                        name: e.name,
                        uid: e.uid,
                        toggled: false,
                        children: []
                    });
                });

                node.loading = false;
                node.loaded = true;
                this.forceUpdate();
            });

    };

    render() {
        return (
            <Modal title="Move selection" onClose={this.props.onClose}>
                {this.renderForm()}
            </Modal>
        );
    }

    onToggle = (node, toggled) => {
        if (this.state.cursor) {
            let nc = this.state.cursor;
            nc.active = false;
            this.setState({cursor: nc});
        }
        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }
        this.setState({cursor: node});
        this.selected = node;

        this.requestDirContents(node);
    };

    renderForm() {
        return (
            <div className="tree-folders">
                <DirectoryPicker data={this.props.files} forbidden={this.props.forbidden} onToggle={this.onToggle}/>
                <div className="text-center mt-2">
                    <button className="btn btn-primary" onClick={() => {
                        this.props.onConfirm(this.selected)
                    }}>
                        <i className="fa fa-check"></i> Select
                    </button>
                </div>
            </div>
        )
    }
}

export default connect(null, {getFolder})(MoveSelectionModal)
