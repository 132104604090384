import {request} from '../helpers'


// Fetch all calendars

const MISSION_CALENDARS_FETCH_ALL = 'MISSION_CALENDARS_FETCH_ALL';
const MISSION_CALENDARS_FETCH_ALL_SUCCESS = 'MISSION_CALENDARS_FETCH_ALL_SUCCESS';
const MISSION_CALENDARS_FETCH_ALL_FAIL = 'MISSION_CALENDARS_FETCH_ALL_FAIL';

export function getMissionCalendars(mission_id)
{
    return request(MISSION_CALENDARS_FETCH_ALL, 'get', '/mission_calendars/mission/' + mission_id)
}


// Fetch calendar

const MISSION_CALENDAR_FETCH = 'MISSION_CALENDAR_FETCH';
export function getMissionCalendar(id)
{
    return request(MISSION_CALENDAR_FETCH, 'get', '/mission_calendars/' + id)
}



// Create a calendar

export const MISSIONS_CREATE_CALENDAR = 'MISSIONS_CREATE_CALENDAR';
export const MISSIONS_CREATE_CALENDAR_SUCCESS = 'MISSIONS_CREATE_CALENDAR_SUCCESS';
export const MISSIONS_CREATE_CALENDAR_FAIL = 'MISSIONS_CREATE_CALENDAR_FAIL';

export function createMissionCalendar(title, description, mission_id)
{
    const data = { title, description, mission_id };
    return request(MISSIONS_CREATE_CALENDAR, 'put', '/mission_calendars', data)
}


// Update a calendar

const MISSIONS_UPDATE_CALENDAR = 'MISSIONS_UPDATE_CALENDAR';
const MISSIONS_UPDATE_CALENDAR_SUCCESS = 'MISSIONS_UPDATE_CALENDAR_SUCCESS';
const MISSIONS_UPDATE_CALENDAR_FAIL = 'MISSIONS_UPDATE_CALENDAR_FAIL';

export function updateMissionCalendar(id, title, description)
{
    const data = { id, title, description };
    return request(MISSIONS_UPDATE_CALENDAR, 'post', '/mission_calendars/' + id, data, {id})
}


// Delete a calendar

export const MISSIONS_DELETE_CALENDAR = 'MISSIONS_DELETE_CALENDAR';
export const MISSIONS_DELETE_CALENDAR_SUCCESS = 'MISSIONS_DELETE_CALENDAR_SUCCESS';
export const MISSIONS_DELETE_CALENDAR_FAIL = 'MISSIONS_DELETE_CALENDAR_FAIL';

export function deleteMissionCalendar(id)
{
    return request(MISSIONS_DELETE_CALENDAR, 'delete', '/mission_calendars/' + id, {}, {id})
}


// Store

const defaultState = {
    index: [],
    loading: false
};


// Reducer

export default function missionCalendarsReducer(state = defaultState, action)
{
    let index;

    switch(action.type)
    {
        case MISSION_CALENDAR_FETCH:
        case MISSIONS_CREATE_CALENDAR:
        case MISSIONS_UPDATE_CALENDAR:
        case MISSIONS_DELETE_CALENDAR:
        case MISSION_CALENDARS_FETCH_ALL:
            return { ...state, loading: true };

        case MISSIONS_CREATE_CALENDAR_FAIL:
        case MISSIONS_UPDATE_CALENDAR_FAIL:
        case MISSIONS_DELETE_CALENDAR_FAIL:
        case MISSION_CALENDARS_FETCH_ALL_FAIL:
            return { ...state, loading: false };

        case MISSION_CALENDARS_FETCH_ALL_SUCCESS:
            index = [...state.index];
            action.payload.data.map((mission) => {
                index = index.filter((n) => n.id !== mission.id);
                index.push(mission);
                return null;
            });
            return {
                ...state,
                index,
                loading: false
            };

        case MISSIONS_CREATE_CALENDAR_SUCCESS:
            index = [...state.index]
            index.push(action.payload.data.mission_calendar)
            return { ...state, index, loading: false }

        case MISSIONS_UPDATE_CALENDAR_SUCCESS:
            index = state.index.filter(( mission_calendar ) => mission_calendar.id !== action.meta.previousAction.id)
            index.push(action.payload.data.mission_calendar);
            return { ...state, index, loading: false }

        case MISSIONS_DELETE_CALENDAR_SUCCESS:
            index = [...state.index].filter((mission) => mission.id !== action.meta.previousAction.id)
            return { ...state, index, loading: false }
    }

    return state;
}
