import React, {Component} from 'react';
import Empty from "../components/Empty";
import MissionCalendar from "../components/missionCalendar/MissionCalendar";
import {getMissionCalendars, createMissionCalendar} from "../data/missionCalendars";
import {connect} from 'react-redux';
import MissionCalendarCreateModal from "../components/missionCalendar/MissionCalendarCreateModal";
import {API_URL} from "../config";
import Cookies from "universal-cookie/cjs";


class MissionCalendarPage extends Component
{
    state = {
        data: null,
        showCreateModal: false,
        auth_token: (new Cookies()).get('hub_token')
    };

    componentDidMount() {
        this.getCalendar();
    }

    getCalendar = () => {
        this.props.getMissionCalendars(this.props.mission_id)
            .then((res) => {
                this.setState({data: Object.keys(res.payload.request.response).length !== 0 ? res.payload.request.response : null});
            })
    };

    render() {

        const get_query = (new URLSearchParams({
                auth_token: this.state.auth_token
            }))
                .toString()
        ;

        return(
            <div className={'mission-calendar mb-5'}>
                <div className="row" style={{marginTop:100}}>
                    <div className="col-md-4">
                        <h3 className={"d-inline-block mb-3"}>Calendrier opérationnel</h3>
                    </div>
                    <div className="col-md-8">
                        {!this.state.data ?
                            <button
                                className={"btn btn-primary float-right"}
                                onClick={() => this.toggleCreateModal()}>
                                Créer un calendrier
                            </button>
                            : null
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        {this.state.data ?
                            <>
                        <div className="btn-group w-100">
                            <a className="btn btn-info w-100 mb-1"
                               target="_blank"
                               rel="noopener noreferrer"
                               href={API_URL + "/mission/" + this.props.mission_id + "/make-document/timeline?test=1&" + get_query}
                            >
                                <i className="fa fa-file-invoice mr-2"></i> See calendar
                            </a>
                        </div>

                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <a className="btn btn-info "
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   href={API_URL + "/mission/" + this.props.mission_id + "/make-document/timeline?test=0&" + get_query}
                                   style={{'width':'100%'}}
                                >
                                    <i className="fa fa-file-invoice mr-2"></i> Create calendar
                                </a>
                            </div>
                        </div>
                        </>: null}

                        <div className={'row'} style={{marginTop:20}}>
                            <div className={'col-md-12'}>
                                <h4>Calendriers générés</h4>
                                {this.props.mission && this.props.mission.calendar_files ?
                                    this.props.mission.calendar_files.map((file)=>{
                                    return this.renderCalendarFile(file);
                                }) : null}
                            </div>
                        </div>

                    </div>
                    <div className="col-md-8">
                        {this.renderCalendar()}
                    </div>
                </div>
                {this.state.showCreateModal ? <MissionCalendarCreateModal onClose={(created) => this.toggleCreateModal(created)} mission_id={this.props.mission_id} /> : null}
            </div>
        )
    }

    renderCalendar() {

        const mission_calendar = this.state.data;

        if (!mission_calendar)
            return <Empty/>;

        return (
            <div>
                <MissionCalendar id={mission_calendar.id} mission_calendar={mission_calendar} onDelete={() => this.getCalendar()} onUpdate={() => this.getCalendar()} key={mission_calendar.id}/>
            </div>
        )
    };

    renderCalendarFile = (calendar_file) => {

        return (
            <div key={calendar_file.id} style={{background: '#343a40', padding:'10px',marginBottom:'15px'}}>
                <a target="_blank" href={calendar_file.url} style={{color:'white'}}>
                    <i className={'fa fa-file'}></i>
                    <div style={{width:20,height:20, background: '#343a40', borderRadius:'50%',display:'inline-block'}} >
                    </div>
                    {calendar_file.reference}
                </a>
            </div>
        )
    };

    toggleCreateModal = (created) => {
        this.setState({ showCreateModal: !this.state.showCreateModal });
        if (created) this.getCalendar();
    };
}

export default connect(null, {getMissionCalendars, createMissionCalendar})(MissionCalendarPage);
