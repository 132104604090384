import {request} from "../helpers";

const USER_EVENTS_USER = "USER_EVENTS_USER";
const USER_EVENTS_USER_SUCCESS = "USER_EVENTS_USER_SUCCESS";
const USER_EVENTS_USER_FAIL = "USER_EVENTS_USER_FAIL";

export function getUserEvents(user_id) {
    return request(USER_EVENTS_USER, "get", "/user-events/" + user_id);
}

const USER_EVENTS_CREATE = "USER_EVENTS_CREATE";
const USER_EVENTS_CREATE_SUCCESS = "USER_EVENTS_CREATE_SUCCESS";
const USER_EVENTS_CREATE_FAIL = "USER_EVENTS_CREATE_FAIL";

export function createUserEvents(user_id, event_id, starting_at, ending_at, informations ) {
    const data = {user_id, event_id, starting_at, ending_at, informations};

    return request(USER_EVENTS_CREATE, "post", "/user-events/create", data);
}

const USER_EVENTS_DELETE = "USER_EVENTS_DELETE";
const USER_EVENTS_DELETE_SUCCESS = "USER_EVENTS_DELETE_SUCCESS";
const USER_EVENTS_DELETE_FAIL = "USER_EVENTS_DELETE_FAIL";

export function deleteUserEvents(userEvent_id) {
    return request (USER_EVENTS_DELETE, "post", "/user-events/delete", { userEvent_id });
}

const defaultState = {
    index: [],
    loading: false
};

export default function userEventsReducer(state = defaultState, action)
{
    switch (action.type) {
        case USER_EVENTS_USER_SUCCESS:
            return {...state, loading: false};
        case USER_EVENTS_USER_FAIL:
        case USER_EVENTS_USER:
            return {...state, loading: true};
        case USER_EVENTS_CREATE:
        case USER_EVENTS_CREATE_SUCCESS:
        case USER_EVENTS_CREATE_FAIL:
        case USER_EVENTS_DELETE:
        case USER_EVENTS_DELETE_SUCCESS:
        case USER_EVENTS_DELETE_FAIL:
    }

    return state;
}
