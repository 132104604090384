import React, {Component} from 'react';
import {connect} from 'react-redux';
import Konva, { Group, Layer, Rect, Text} from 'react-konva'
import {insertElement, updateElement} from "../../data/bag_editor";
import {getDataRecursively} from "../../helpers";

class Stage extends Component
{
    state = {
        mouse: null
    }

    render()
    {
        return this.renderContent()
    }

    renderContent = () => {
        const data = this._getData()

        if (data.error) {
            this.props.onDblClickContext(null)
            return null
        }

        return (
            <div onMouseMove={this._trackMouse}
                 onDragOver={(e) => e.preventDefault()}
                 onDrop={(e) => this._onDropElement(e)}>
                <Konva.Stage
                    width={this.props.width}
                    height={this.props.height}>
                    <Layer>
                        {this.renderLinks()}
                        {this.renderChildren(data.children)}
                        {this.renderArrow()}
                    </Layer>
                </Konva.Stage>
            </div>
        )
    }

    renderChildren(children)
    {
        return children.map((child) => this.renderElement(child))
    }

    renderElement(element)
    {
        return (
            <Group
                key={element.id}
                width={element._editor.width}
                height={element._editor.height}
                x={element._editor.x}
                y={element._editor.y}
                zIndex={this.props.selection === element.id ? 1000 : null}
                onClick={() => this.props.onClickContext(element.id)}
                onDblClick={() => this.props.onDblClickContext(element.id)}
                draggable={this.props.tool === 'default'}
                onDragStart={(event) => this._onDragStartElement(element, event)}
                onDragEnd={(event) => this._onDragEndElement(element, event)}
                onMouseDown={(event) => this._onMouseDownElement(element, event)}
                // onMouseUp={() => this.onMouseUpElement(element)}
            >
                <Rect
                    fill="white"
                    stroke={this.props.selection === element.id ? '#b7365d' : '#333333'}
                    strokeWidth={this.props.selection === element.id ? 2 : 1}
                    width={element._editor.width}
                    height={element._editor.height}
                />
                <Text
                    text={element.type}
                    fontSize={16}
                    fontStyle="bold"
                    x={10}
                    y={10}
                />
                <Text
                    text={element.name}
                    fontSize={14}
                    x={10}
                    y={30}
                />
            </Group>
        )
    }

    renderLinks() {
        return null
    }

    renderArrow() {
        return null
    }

    //

    _getData = () => {
        const data = this.props.data
        const context = this.props.context
        //console.log('_getData', data, context)

        if (context === null)
        {
            //console.log('stage = null')
            return {
                id: null,
                children: data,
                links: []
            }
        }

        let stage = getDataRecursively(context, data)

        console.log('stage =', stage)

        if (stage === null)
        {
            console.log('STAGE ERROOOOOOOOOOR')
            return {
                id: null,
                children: [],
                error: true
            }
        }

        return stage
    }

    _trackMouse = (e) => {
        return null; // Au besoin

        const mouse = {
            x: e.pageX,
            y: e.pageY
        };

        setTimeout(() => this.setState({ mouse }), 10)
    }

    _onMouseDownElement = (element, e) => {
        this.mouseOffsetX = e.evt.clientX - element._editor.x
        this.mouseOffsetY = e.evt.clientY - element._editor.y
    }

    _onDragStartElement = (data, e) => {
        this.props.onDragStart(data.id)
    }

    _onDragEndElement = (data, e) => {
        data._editor.x = e.evt.clientX - this.mouseOffsetX
        data._editor.y = e.evt.clientY - this.mouseOffsetY
        this.props.updateElement(this.props.board, { data })

        this.props.onDragEnd()
    };

    _onDropElement = (e) => {
        e.preventDefault()

        const data = JSON.parse(e.dataTransfer.getData('text'))

        if (data.nature === 'ElementTemplate')
        {
            const element = {
                name: data.type,
                type: data.type,
            };

            const attrs = { x: e.clientX - 100, y: e.clientY - 120, width: 200, height: 100 }

            this.props.insertElement(this.props.board, this._getData().id, element, attrs, (element) => {
                this.props.onFocusElement(element.id)
                this.props.onElementCreated(element)
            })
        }
    }
}

export default connect(null, {insertElement, updateElement})(Stage)
