import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deleteTask, taskPosition} from '../../data/tasks'
import TaskDeleteModal from './TaskDeleteModal'
import TaskUpdateModal from './TaskUpdateModal'
import {nl2br} from "../../helpers"


class Task extends Component
{
    state = {
        showDeleteModal: false,
        showUpdateModal: false
    };

    render = () => {
        return (
            <div>
                {this.renderTask()}
                {this.state.showDeleteModal ? <TaskDeleteModal id={this.props.data.id} onClose={this.onDeleteModalClose} /> : null}
                {this.state.showUpdateModal ? <TaskUpdateModal data={this.props.data} onClose={this.onUpdateModalClose} /> : null}
            </div>
        )
    }

    renderTask()
    {
        const task = this.props.data
        const cursorPointer = {
            cursor: 'pointer'
        };
        return (
            <div className="card">
                <div className={"card-header"}>
                    <div className={"row"}>
                        <div className={"col-md-7"}>
                            <h5 className={"card-title d-inline-block mt-2"}>{task.name}</h5>
                        </div>
                        <div className={"d-inline-block col-md-2"}>
                            <div className={"float-right mt-2"}>
                                <span style={cursorPointer} onClick={this.movePositionUp}>
                                    <i className={"fas fa-angle-up mr-3"}></i>
                                </span>
                                <span style={cursorPointer} onClick={this.movePositionDown}>
                                    <i className={"fas fa-angle-down"}></i>
                                </span>
                            </div>
                        </div>
                        <div className={"col-md-3 d-inline-block"}>
                            <div className={"float-right"}>
                                <button onClick={this.onUpdateModalOpen} className="btn btn-secondary mr-1">
                                    <i className="fa fa-edit"></i>
                                </button>
                                <button onClick={this.onDeleteModalOpen} className="btn btn-danger">
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <p className={"card-text"}>{task.description ? nl2br(task.description) : null}</p>
                    <p>Duration : {task.duration}</p>
                    <p>Price : {task.price ? task.price + '€' : 'undefined'}</p>
                </div>
            </div>
        )
    }

    movePositionUp = () => {
        this.props.taskPosition(this.props.data.id, this.props.data.position - 1, this.props.moduleId)
            .then(() => {
                this.props.onMoved()
            })
    }

    movePositionDown = () => {
        this.props.taskPosition(this.props.data.id, this.props.data.position + 1, this.props.moduleId)
            .then(() => {
                this.props.onMoved()
            })
    }

    onDeleteModalOpen = () => this.setState({ showDeleteModal: true })
    onDeleteModalClose = (deleted) => {
        this.setState({ showDeleteModal: false })
        if (deleted) this.props.onDelete()
    }

    onUpdateModalOpen = () => this.setState({ showUpdateModal: true })
    onUpdateModalClose = () => this.setState({ showUpdateModal: false })

}

Task.defaultProps = {
    onDelete: () => {},
    onMoved: () => {},
}

export default connect(null, {deleteTask, taskPosition})(Task);
