import {request} from '../helpers'



// Fetch all taskss

const TASKS_FETCH_ALL = 'TASKS_FETCH_ALL'
const TASKS_FETCH_ALL_SUCCESS = 'TASKS_FETCH_ALL_SUCCESS'
const TASKS_FETCH_ALL_FAIL = 'TASKS_FETCH_ALL_FAIL'

export function getTasks(missionModule_id)
{
    return request(TASKS_FETCH_ALL, 'get', '/missionModuleTasks/missionModule/' + missionModule_id)
}


// Create a task

export const TASKS_CREATE_TASK = 'TASKS_CREATE_TASK'
export const TASKS_CREATE_TASK_SUCCESS = 'TASKS_CREATE_TASK_SUCCESS'
export const TASKS_CREATE_TASK_FAIL = 'TASKS_CREATE_TASK_FAIL'

export function createTask(name, description, duration, price, missionModule_id)
{
    const data = { name, description, duration, price, missionModule_id };
    return request(TASKS_CREATE_TASK, 'put', '/missionModuleTasks', data);
}


// Update a task

const TASKS_UPDATE_TASK = 'TASKS_UPDATE_TASK'
const TASKS_UPDATE_TASK_SUCCESS = 'TASKS_UPDATE_TASK_SUCCESS'
const TASKS_UPDATE_TASK_FAIL = 'TASKS_UPDATE_TASK_FAIL'

export function updateTask(id, name, description, duration, price)
{
    const data = { id, name, description, duration, price };
    return request(TASKS_UPDATE_TASK, 'post', '/missionModuleTasks/' + id, data, {id})
}


// Delete a task

export const TASKS_DELETE_TASK = 'TASKS_DELETE_TASK'
export const TASKS_DELETE_TASK_SUCCESS = 'TASKS_DELETE_TASK_SUCCESS'
export const TASKS_DELETE_TASK_FAIL = 'TASKS_DELETE_TASK_FAIL'

export function deleteTask(id)
{
    return request(TASKS_DELETE_TASK, 'delete', '/missionModuleTasks/' + id, {}, {id})
}


// Position up

export const TASK_POSITION = 'TASK_POSITION'

export function taskPosition(id, position, missionModule_id)
{
    const data = { id, position, missionModule_id }
    return request(TASK_POSITION, 'post', 'missionModuleTasks/position/' + id, data, {id})
}


// Store

const defaultState = {
    index: [],
    loading: false
};

// Reducer

export default function tasksReducer(state = defaultState, action)
{
    let index;

    switch(action.type)
    {
        case TASKS_CREATE_TASK:
        case TASKS_FETCH_ALL:
        case TASKS_DELETE_TASK:
        case TASKS_UPDATE_TASK:
            return { ...state, loading: true }

        case TASKS_FETCH_ALL_FAIL:
        case TASKS_DELETE_TASK_FAIL:
        case TASKS_CREATE_TASK_FAIL:
        case TASKS_UPDATE_TASK_FAIL:
            return { ...state, loading: false }

        case TASKS_FETCH_ALL_SUCCESS:
            index = [...state.index]
            action.payload.data.map((task) => {
                index = index.filter((n) => n.id != task.id)
                index.push(task)
            })
            return {
                ...state,
                index,
                loading: false
            }

        case TASKS_CREATE_TASK_SUCCESS:
            index = [...state.index]
            index.push(action.payload.data.missionModuleTask)
            return { ...state, index, loading: false }

        case TASKS_UPDATE_TASK_SUCCESS:
            index = state.index.filter(( missionModuleTask ) => missionModuleTask.id !== action.meta.previousAction.id)
            index.push(action.payload.data.missionModuleTask)
            return { ...state, index, loading: false }

        case TASKS_DELETE_TASK_SUCCESS:
            index = [...state.index].filter((task) => task.id !== action.meta.previousAction.id)
            return { ...state, index, loading: false }
    }

    return state;
}
