import React, {Component} from 'react';
import Modal from "../Modal";
import Input from "../Input";
import FormProvider from "../FormProvider";
import {connect} from "react-redux";
import {updateModule} from "../../data/modules";
import Textarea from "../Textarea";
import Select from "../Select";



class ModuleUpdateModal extends Component
{
    state = {
        id: this.props.data.id,
        name: this.props.data.name,
        description: this.props.data.description,
        isReference: this.props.data.isReference,
        errors: null,
        updating: false,
    };

    update = (e) => {
        let {id, name, description, isReference} = this.state;


        e.preventDefault();
        this.setState({ updating: true });

        this.props.updateModule(id, name, description, isReference)
            .then(() => {
                this.props.onClose(true);
            })
            .catch((res) => {
                if (res.error) this.setState({ errors: res.error.response.data.errors });
                this.setState({ updating: false });
            });

        return false;
    };

    render = () => {
        return (
            <Modal title="Update a module" onClose={() => this.props.onClose(false)}>
                {this.renderForm()}
            </Modal>
        )
    };

    renderForm = () => {
        return (
            <FormProvider onSubmit={this.update} errors={this.state.errors}>
                <div className="row">
                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"name"}
                               id={"name"}
                               placeholder={"Module name *"}
                               value={this.state.name}
                               onChange={(name) => this.setState({name})}
                               label={"Module name *"}/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Textarea
                            name={"description"}
                            id={"description"}
                            onChange={(description) => this.setState({description})}
                            label={"Description"}
                            value={this.state.description}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Select
                            required
                            label={"Module de référence ?"}
                            name={"isReference"}
                            id={"isReference"}
                            value={this.state.isReference}
                            options={[{ value: 0, label: 'Non'},{value: 1, label: 'Oui'}]}
                            onChange={(isReference) => {
                                this.setState({ isReference })
                            }}
                        />
                    </div>
                </div>
                <button className="btn btn-primary btn-block" disabled={this.state.updating}>Update</button>
            </FormProvider>
        )
    };
}

export default connect(null, { updateModule }) (ModuleUpdateModal)
