import React, {Component} from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import MissionDeleteModal from "../components/missions/MissionDeleteModal";
import {
    getMission,
    getMissionQuotationIntro,
    getMissionQuotationOutro,
    setMissionQuotationIntro,
    setMissionQuotationOutro,
    acceptMission,
    refuseMission,
    sendEmail,
    noRemainingDays
} from "../data/missions";
import { getCustomer } from "../data/customers";
import {getModules} from "../data/modules";
import MissionUpdateModal from "../components/missions/MissionUpdateModal";
import ModuleAddModal from "../components/modules/ModuleAddModal";
import Empty from "../components/Empty";
import ContentLoader from "../components/ContentLoader";
import Informations from "../components/Informations";
import PrivilegesButton from "../components/privileges/PrivilegesButton";
import moment from "moment";
import Module from "../components/modules/Module";
import {API_URL} from "../config";
import Cookies from "universal-cookie/cjs/Cookies";
import TextEditorModal from "../components/TextEditorModal";
import {Link} from 'react-router-dom';
import MissionCalendarPage from "./MissionCalendarPage";
import {getMissionCalendars} from "../data/missionCalendars";
import {getMissionStatus} from "../helpers";

class MissionPage extends Component {
    state = {
        id: parseInt(this.props.match.params.id),
        data: null,
        modules: null,
        showUpdateModal: false,
        showDeleteModal: false,
        showAddModuleModal: false,
        showEditIntroModal: false,
        showEditOutroModal: false,
        auth_token: (new Cookies()).get('hub_token'),
        customer: null
    };

    componentDidMount() {
        this.loadMission()
        this.getModules();
    }

    componentWillReceiveProps(props) {
        const mission = props.missions.index.find((mission) => mission.id == this.state.id);
        if (mission) this.setState({data: mission});

        const modules = props.modules.index.filter((module) => module.mission_id == this.state.id);

        this.setState({modules})
    }

    // Data
    loadMission = () => {
        this.props.getMission(this.state.id)
            .then(res => res.payload.request.response)
            .then(mission => {
                this.props.getCustomer(mission.customer.id)
                    .then(res => {
                        this.setState({
                            data: mission,
                            customer: res.payload.request.response
                        })
                    })
            })
    };

    getModules = () => {
        this.props.getModules(this.state.id)
            .then((res) => {
                this.setState({modules: res.payload.request.response})
            })
    };

    noRemainingDays = () => {
        this.props.noRemainingDays(this.state.id);
    }

    render = () => {
        if (!this.state.data) return <ContentLoader/>;
        return (
            <div>
                {this.renderMission()}

                <MissionCalendarPage mission_id={this.state.id} mission={this.state.data}/>

                {this.state.showUpdateModal ?
                    <MissionUpdateModal data={this.state.data} onClose={this._onUpdateModalClose}/> : null}

                {this.state.showDeleteModal ?
                    <MissionDeleteModal id={this.state.id} onClose={this._onDeleteModalClose}/> : null}

                {this.state.showAddModuleModal ?
                    <ModuleAddModal id={this.state.id} onClose={this._onAddModuleModalClose}/> : null}

                {this.state.showEditIntroModal ? <TextEditorModal onClose={this._onEditIntroClose} dataFunc={() => { return this.props.getMissionQuotationIntro(this.state.id); }} uploadFunc={(body) => {return this.props.setMissionQuotationIntro(this.state.id, body); }} showEmptyText={true}/> : null}

                {this.state.showEditOutroModal ? <TextEditorModal onClose={this._onEditOutroClose} dataFunc={() => { return this.props.getMissionQuotationOutro(this.state.id); }} uploadFunc={(body) => {return this.props.setMissionQuotationOutro(this.state.id, body); }} showEmptyText={true}/> : null}
            </div>
        )
    };

    renderStats = (stats, informations) =>{
        return (
            <div className="card mb-2" >
                <div className="card-body">
                    <h5 className="card-title">Informations</h5>
                    <div className={'row'}>
                        <div className={'col-md-5'}>
                            <div className={'jumbotron'} style={{'paddingTop':25}}>
                                <h5>Informations</h5>
                                <Informations data={informations} />
                                <div style={{'marginTop':20}}>
                                    <button className="btn btn-info w-100 mb-1"
                                            onClick={this.noRemainingDays}>Mettre à 0 le nombre de jours
                                    </button>
                                    <button className="btn btn-info w-100 mb-1"
                                            onClick={this._onUpdateModalOpen}>Edit mission
                                    </button>
                                    <button className="btn btn-danger w-100 mb-1"
                                            onClick={this._onDeleteModalOpen}>Delete mission
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    hasLegalRepresentative() {
        return this.state.customer && this.state.customer.contacts && this.state.customer.contacts.filter(c => c.business_function === 'signatory_legal_representative').length > 0
    }

    getQuotationColor = (quotation) => {
        if(quotation.status == "ongoing")
            return '#343a40';

        if(quotation.status == "declined")
            return '#dc3545';

        if(quotation.status == "accepted")
            return '#28a745';

        return 'blue';
    }

    renderQuotation = (quotation) => {
        return (
            <div key={quotation.id} style={{background:this.getQuotationColor(quotation), padding:'10px',marginBottom:'15px'}}>
                <a href={quotation.url} style={{color:'white'}}>
                    <i className={'fa fa-file'}/>
                    <div style={{width:20,height:20, background: this.getQuotationColor(quotation), borderRadius:'50%',display:'inline-block'}} >
                    </div>
                    {quotation.reference}
                </a>
            </div>
       )
    }

    renderMission = () => {
        const get_query = (new URLSearchParams({
                auth_token: this.state.auth_token
            }))
            .toString();

        let informations = [
            {label: 'Name', value: this.state.data.name},
            {label: 'Status', value: getMissionStatus(this.state.data.status, 'txt')},
            {label: 'Sold days', value: this.state.data.sold_days},
            {label: 'Remaining days', value: this.state.data.remaining_days},
            {label: 'Amount', value: this.state.data.amount},
            {label: 'Cost per day', value: this.state.data.cost_per_day},
            {label: 'Additional Costs', value: this.state.data.additional_costs},
            {
                label: 'Start date',
                value: this.state.data.starting_at ? moment(this.state.data.starting_at.date).format('DD-MM-YYYY') : 'Unknown'
            },
            {
                label: 'End date',
                value: this.state.data.ending_at ? moment(this.state.data.ending_at.date).format('DD-MM-YYYY') : 'Unknown'
            },
        ];

        let stats = [
            { label: '# Jours consommés', value: '', suffix:'jours' },
        ];

        return (
            <div className={"mission"}>
                <h2><span>{this.state.data.name}</span> <PrivilegesButton type="Server" id={this.state.data.id}/></h2>
                <h4 className={"text-muted mb-4"}>
                    <Link to={"/customers/"+this.state.data.customer.id}>
                        {this.state.data.customer ? this.state.data.customer.name : 'Bluesquare'}
                    </Link>
                </h4>

                {this.renderStats(stats,informations)}

                <div className="row" style={{marginTop:100}}>
                    <div className="col-md-4">
                        <h3 className={"d-inline-block mb-3"}>Gestion du devis</h3>
                        <div>
                            <div>
                                <div className={'relative'}>
                                    {
                                        !this.hasLegalRepresentative()
                                        ? (
                                            <div className={'remove-quote-action-container'}>
                                                Impossible de faire ces actions lorsque aucuns représentant légal n'est rattaché à ce client
                                            </div>
                                        )
                                        : null
                                    }
                                    <div className="btn-group w-100">
                                        <a className="btn btn-info w-100 mb-1"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           href={API_URL + "/mission/" + this.state.id + "/make-document/quotation?test=1&" + get_query}
                                        >
                                            <i className="fa fa-file-invoice mr-2"/> Test quotation
                                        </a>

                                        <button className="btn btn-dark mb-1" title="Edit quotation introduction" onClick={() => this.setState({showEditIntroModal: true})}>
                                            <i className="fa fa-edit"/>
                                        </button>
                                        <button className="btn btn-dark mb-1" title="Edit quotation conclusion" onClick={() => this.setState({showEditOutroModal: true})}>
                                            <i className="fa fa-edit"/>
                                        </button>
                                    </div>

                                    <div className={'row'}>
                                        <div className={'col-md-12'}>
                                            <a className="btn btn-info "
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               href={API_URL + "/mission/" + this.state.id + "/make-document/quotation?test=0&" + get_query}
                                               style={{'width':'100%'}}
                                            >
                                                <i className="fa fa-file-invoice mr-2"/> Create quotation
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className={'row'} style={{marginTop:20}}>
                                    <div className={'col-md-12'}>
                                        <h4>Devis générés</h4>
                                        { this.state.data && this.state.data.quotations ? this.state.data.quotations.map((quotation)=>{
                                            return this.renderQuotation(quotation);
                                        }) : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className={"px-3 row"}>
                            <div className={"col-md-6"}>

                            </div>
                            <div className={"col-md-6"}>
                                <button
                                    className={"btn btn-primary float-right"}
                                    onClick={this._onAddModuleModalOpen}>
                                    Add a module
                                </button>
                            </div>
                        </div>
                        {this.renderModules()}
                    </div>
                </div>
            </div>
        )
    };

    renderModules = () => {
        const modules = this.state.modules;
        if (!modules.length) return <Empty text={"No modules added"}/>;

        modules.sort((a, b) => {
            return a.position > b.position ? 1 : -1;
        });

        return (
            <div className={"missions mt-3"}>
                {modules.map((module) => this.renderModule(module))}
            </div>
        )
    };

    renderModule = (module) => {
        return (
            <div className="col mb-3" key={module.id}>
                <Module data={module} missionId={this.state.id} onMoved={this.getModules} onDelete={this.getModules}/>
            </div>
        )
    };

    // Modals

    _onUpdateModalOpen = () => this.setState({showUpdateModal: true});
    _onUpdateModalClose = (updated) => {
        this.setState({showUpdateModal: false});
        if (updated) this.loadMission()
    };

    _onDeleteModalOpen = () => this.setState({showDeleteModal: true});
    _onDeleteModalClose = (deleted) => {
        this.setState({showDeleteModal: false});
        if (deleted) this.props.history.push('/customers/' + this.state.data.customer.id)
    };

    _onAddModuleModalOpen = () => this.setState({showAddModuleModal: true});
    _onAddModuleModalClose = (updated) => {
        this.setState({showAddModuleModal: false});
        if (updated) this.getModules()
    };

    _onEditIntroClose = (txt) => {
        this.setState({showEditIntroModal: false});
    };

    _onEditOutroClose = (txt) => {
        this.setState({showEditOutroModal: false});
    };


}

export default connect(({missions, modules}) => ({missions, modules}), {
    getMission,
    getModules,
    getMissionQuotationIntro,
    getMissionQuotationOutro,
    setMissionQuotationIntro,
    setMissionQuotationOutro,
    acceptMission,
    sendEmail,
    refuseMission,
    getMissionCalendars,
    noRemainingDays,
    getCustomer
})(withRouter(MissionPage));
