import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getNotes} from "../data/notes";
import {chunks} from "../helpers";
import ContentLoader from "../components/ContentLoader";
import Empty from "../components/Empty";
import Note from "../components/notes/Note";
import Loader from "../components/Loader";
import NoteAddModal from "../components/notes/NoteAddModal";
import PrivilegesButton from "../components/privileges/PrivilegesButton";

class NotesPage extends Component
{
    state = {
        showAddModal: false
    }

    componentDidMount()
    {
        this.props.getNotes()
    }

    filter = (index) => {
        console.log(index)
        return index.filter((note) => note.customer_id === null && note.user_id === null )
    }

    render = () => {
        return (
            <div>
                {this.renderLinks()}
                {this.renderNotes()}
                {this.state.showAddModal ? <NoteAddModal onClose={this._onAddModalClose} /> : null }
            </div>
        )
    }

    renderLinks = () => {
        return (
            <div className={"row"}>
                <div className="col">
                    <h2><span>Notes</span> <PrivilegesButton type="Note" /></h2>
                </div>
                <div className="col text-right">
                    <Loader display={this.props.notes.loading}/>
                    <button
                        className={"btn btn-primary float-right"}
                        onClick={this._onAddModalOpen}>
                        Add a note
                    </button>
                </div>
            </div>
        )
    };

    renderNotes = () => {
        const notes = chunks(this.filter(this.props.notes.index), 3)

        if (this.props.notes.loading) return <ContentLoader/>
        if (!notes.length) return <Empty text={"No notes yet"}/>

        return(
            <div className={"notes mt-3"}>
                {notes.map((chunk) => this.renderNoteRow(chunk))}
            </div>
        )
    }

    renderNoteRow = (notes) => {
        return (
            <div className={"row mb-3"} key={notes[0].id}>
                {notes.map((note) => this.renderNote(note))}
            </div>
        )
    }

    renderNote = (note) => {

        return (
            <div className="col-md-4" key={note.id}>
                <Note data={note}/>
            </div>
        )
    }

    // Events

    _onAddModalOpen = () => this.setState({ showAddModal: true })

    _onAddModalClose = (created) => {
        this.setState({ showAddModal: false })
        if (created) this.props.getNotes()
    }

}

export default connect(({notes}) => ({notes}), {getNotes})(NotesPage)