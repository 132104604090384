import {request} from '../helpers';
import {
    MISSIONS_DELETE_CALENDAR,
    MISSIONS_DELETE_CALENDAR_FAIL,
    MISSIONS_DELETE_CALENDAR_SUCCESS
} from "./missionCalendars";


// Fetch phase

const MISSION_CALENDAR_PHASES_FETCH = 'MISSION_CALENDAR_PHASES_FETCH';
export function getMissionCalendarPhases(id)
{
    return request(MISSION_CALENDAR_PHASES_FETCH, 'get', '/mission_calendars/' + id + '/mission_calendar_phase')
}

const MISSION_CALENDAR_PHASE_FETCH = 'MISSION_CALENDAR_PHASE_FETCH';
export function getMissionCalendarPhase(id)
{
    return request(MISSION_CALENDAR_PHASE_FETCH, 'get', '/mission_calendar_phases/' + id )
}

// Create a phase

export const MISSION_CREATE_CALENDAR_PHASE = 'MISSIONS_CREATE_CALENDAR_PHASE';
export const MISSION_CREATE_CALENDAR_PHASE_SUCCESS = 'MISSION_CREATE_CALENDAR_PHASE_SUCCESS';
export const MISSION_CREATE_CALENDAR_PHASE_FAIL = 'MISSION_CREATE_CALENDAR_PHASE_FAIL';

export function createMissionCalendarPhase(name, description, content, starting_at, ending_at, mission_calendar_id)
{
    const data = { name, description, content, starting_at, ending_at, mission_calendar_id };
    return request(MISSION_CREATE_CALENDAR_PHASE, 'put', '/mission_calendar_phases', data)
}

// Update a phase

const MISSION_UPDATE_CALENDAR_PHASE = 'MISSION_UPDATE_CALENDAR_PHASE';
const MISSION_UPDATE_CALENDAR_PHASE_SUCCESS = 'MISSION_UPDATE_CALENDAR_PHASE_SUCCESS';
const MISSION_UPDATE_CALENDAR_PHASE_FAIL = 'MISSION_UPDATE_CALENDAR_PHASE_FAIL';

export function updateMissionCalendarPhase(id, name, description, content, starting_at, ending_at)
{
    const data = { id, name, description, content, starting_at, ending_at };
    return request(MISSION_UPDATE_CALENDAR_PHASE, 'post', '/mission_calendar_phases/' + id, data, {id})
}

// Delete a phase

export const MISSION_DELETE_CALENDAR_PHASE = 'MISSION_DELETE_CALENDAR_PHASE';
export const MISSION_DELETE_CALENDAR_PHASE_SUCCESS = 'MISSION_DELETE_CALENDAR_PHASE_SUCCESS';
export const MISSION_DELETE_CALENDAR_PHASE_FAIL = 'MISSION_DELETE_CALENDAR_PHASE_FAIL';

export function deleteMissionCalendarPhase(id)
{
    return request(MISSION_DELETE_CALENDAR_PHASE, 'delete', '/mission_calendar_phases/' + id, {}, {id})
}

// Store

const defaultState = {
    index: [],
    loading: false
};

// Reducer

export default function missionCalendarPhasesReducer(state = defaultState, action)
{
    let index;

    switch(action.type) {
        case MISSION_CALENDAR_PHASES_FETCH:
        case MISSION_CALENDAR_PHASE_FETCH:
        case MISSION_CREATE_CALENDAR_PHASE:
        case MISSION_UPDATE_CALENDAR_PHASE:
        case MISSION_DELETE_CALENDAR_PHASE:
            return {...state, loading: true};

        case MISSION_CREATE_CALENDAR_PHASE_FAIL:
        case MISSION_UPDATE_CALENDAR_PHASE_FAIL:
        case MISSION_DELETE_CALENDAR_PHASE_FAIL:
            return { ...state, loading: false };

        case MISSION_CREATE_CALENDAR_PHASE_SUCCESS:
            index = [...state.index];
            index.push(action.payload.data.mission_calendar_phase);
            return { ...state, index, loading: false }

        case MISSION_UPDATE_CALENDAR_PHASE_SUCCESS:
            index = state.index.filter(( mission_calendar_phase ) => mission_calendar_phase.id !== action.meta.previousAction.id)
            index.push(action.payload.data.mission_calendar_phase);
            return { ...state, index, loading: false }

        case MISSION_DELETE_CALENDAR_PHASE_SUCCESS:
            index = [...state.index].filter((mission_calendar_phase) => mission_calendar_phase.id !== action.meta.previousAction.id)
            return { ...state, index, loading: false }
    }

    return state;
}
