import FormProvider from "../FormProvider";
import Input from "../Input";
import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";
import {createModule, getReferenceModules, cloneModule} from "../../data/modules";
import Textarea from "../Textarea";
import Select from "../Select";
import SelectSearch from "react-select";

class ModuleAddModal extends Component
{
    state = {
        name: '',
        description: '',
        errors: null,
        creating: false,
        reference_modules: [],
        reference_module: 0
    };


    componentDidMount() {
        this.props.getReferenceModules().then(( callback) => {
            this.setState({
                reference_modules: callback.payload.data
            })
        } )
    }

    create = (e) => {
        let { name, description } = this.state;
        let mission_id = this.props.id;

        e.preventDefault();
        this.setState({ creating: true });

        this.props.createModule(name, description, mission_id)
            .then((data) => {
                this.setState({ creating: false })
                this.props.onClose(true);
            })
            .catch((res) => {
                this.setState({ creating: false })
                if (res.error) this.setState({ errors: res.error.response.data.errors })
            });

        return false;
    };

    cloneModule = () => {
        this.setState({ creating: true });

        let mission_id = this.props.id;
        let module_id = this.state.reference_module.value;

        this.props.cloneModule(mission_id, module_id)
            .then( () => {
                this.setState({ creating: false })
                this.props.onClose(true);
            })
    }

    renderModuleOptions(modules)
    {

        let newOptions = [];
        modules.map((module) =>{
            newOptions.push({'value':module.id,'label':module.name });
        })

        return newOptions;
    }


    render()
    {
        return (
            <Modal title="Add a module" onClose={() => this.props.onClose(false)}>
                {this.renderForm()}
            </Modal>
        )
    }

    renderForm()
    {
        return (
            <div>
                <FormProvider onSubmit={this.create} errors={this.state.errors}>
                    <div className="row">
                        <div className="col">
                            <Input required autoFocus
                                   type={"text"}
                                   name={"name"}
                                   id={"name"}
                                   placeholder={"Module name *"}
                                   value={this.state.name}
                                   onChange={(name) => this.setState({name})}
                                   label={"Module name *"}/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Textarea
                                name={"description"}
                                id={"description"}
                                onChange={(description) => this.setState({description})}
                                label={"Description"}
                                value={this.state.description}
                            />
                        </div>
                    </div>
                    <button className="btn btn-primary btn-block" disabled={this.state.creating}>Add</button>
                </FormProvider>
                <hr/>
                <h1 className={'bc-modal-title'}>
                    Ou cloner depuis :
                </h1>
                <SelectSearch
                    name={"reference_modules"}
                    value={this.state.reference_module}
                    options={this.renderModuleOptions(this.state.reference_modules)}
                    placeholder="Select a Reference Module"
                    searchable={true}
                    onChange={(d) => {this.setState({reference_module: d})}}
                />
                <button className="btn btn-primary btn-block mt-3" disabled={this.state.creating} onClick={this.cloneModule} >Clone</button>
                <div style={{height: '200px'}}></div>
            </div>
        )
    }

}

ModuleAddModal.defaultProps = {
    onClose: () => {},
    customerId: null,
};

export default connect(null, { createModule, getReferenceModules, cloneModule })(ModuleAddModal)
