import React from 'react';
import axios from "axios";
import {applyMiddleware, combineReducers, createStore} from "redux";
import reduxAxiosMiddleware from 'redux-axios-middleware';
import ReduxThunk from 'redux-thunk';
import {Provider} from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {API_URL} from "./config";
import routes from "./config/routes";

//

import './App.css';
import './styles/index.scss';

import 'jquery';
import 'jquery-easing';
import 'bootstrap';
import 'chart.js';
import 'datatables';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-pro/css/all.min.css'

//
import PageLayer from "./components/PageLayer";

//
import appReducer from "./data/app";
import authReducer from "./data/auth";
import customersReducer from "./data/customers";
import usersReducer from "./data/users";
import notesReducer from "./data/notes";
import invoicesReducer from "./data/invoices";
import missionsReducer from "./data/missions";
import modulesReducer from "./data/modules";
import tasksReducer from "./data/tasks";
import bagReducer from "./data/bag";
import bagEditorReducer from "./data/bag_editor";
import userHoursReducer from "./data/userHours";
import userEventsReducer from './data/userEvents';

const client = axios.create({
    baseURL: API_URL,
    responseType: 'json'
});

const axiosMiddlewareOptions = {
    returnRejectedPromiseOnError: true,
    interceptors: {
        request: [
            ({ getState }, config) => {
                let data = config.reduxSourceAction.payload.request.data;
                if (data && Object.keys(data).filter((key) => data[key] instanceof File || data[key] instanceof FileList || (data[key] instanceof Array && data[key][0] instanceof File)).length) {
                    // File detected in payload, request type: form data
                    let form = new FormData();
                    Object.keys(data).map((key) => {
                        if (data[key] instanceof FileList || (data[key] instanceof Array && data[key][0] instanceof File)) {
                            for(let i = 0; i < data[key].length; i++ ){
                                let file = data[key][i];
                                form.append(key + '[' + i + ']', file);
                            }
                        }
                        else form.append(key, data[key]);
                        return null
                    });
                    if (config.method.toLowerCase() != 'post') {
                        form.append('_method', config.method);
                        config.method = 'post';
                    }
                    config.data = form;
                    config.headers['Content-Type'] = 'multipart/form-data';
                }

                config.crossdomain = true;
                config.headers['Accept'] = 'application/json';
                // config.headers['Access-Control-Allow-Origin'] = '*';
                if (getState().auth.token) {
                    config.headers['X-AUTH-TOKEN'] = getState().auth.token
                }

                return config
            }
        ]
    }
};

const rootReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    customers: customersReducer,
    users: usersReducer,
    notes: notesReducer,
    invoices: invoicesReducer,
    missions: missionsReducer,
    modules: modulesReducer,
    tasks: tasksReducer,
    bag: bagReducer,
    bag_editor: bagEditorReducer,
    userEvents: userEventsReducer,
    userHours: userHoursReducer
})

const store = createStore(
    rootReducer,
    applyMiddleware(
        ReduxThunk,
        reduxAxiosMiddleware(client, axiosMiddlewareOptions)
    )
);

export default class App extends React.Component
{
    render()
    {
        return (
            <Provider store={store}>

                <Router>
                    <Switch>
                        {routes.map(({path, exact, layout, roles, rights, page: Component, layoutProps}) => (
                            <Route
                                key={path}
                                path={path}
                                exact={exact}
                                render={props => (
                                    <PageLayer {...props} roles={roles} rights={rights} layout={layout} layoutProps={layoutProps}>
                                        <Component {...props} />
                                    </PageLayer>
                                )}
                            />
                        ))}
                    </Switch>
                </Router>
            </Provider>
        )
    }
}
