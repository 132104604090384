import React from 'react'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'

/**
 * Doc @ https://react-dropzone.js.org/
 */

export default class FileImport extends React.Component
{
    static defaultProps = {
        onChange: () => null,
        value: null
    }

    _onDrop = (e) => {
        if (e.preventDefault) e.preventDefault()
        if (e[0] && e[0] instanceof File) {
            this.props.onChange(e[0])
        }
        else {
            this.props.onChange(null)
        }
        if (e.preventDefault) return false;
    }

    render() {
        const baseStyle = {
            border: '2px solid #aeb0b5',
            padding: '10px',
            borderRadius: '3px',
            cursor: 'pointer',
            marginBottom: 0
        };

        const dropStyle = {
            border: '2px solid #6390ff',
            padding: '10px',
            borderRadius: '3px',
            marginBottom: 0
        };

        return (
            <div className="mb-2" style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                    <Dropzone multiple={false} onDrop={this._onDrop}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return (
                                <div
                                    {...getRootProps()}
                                    className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                                >
                                    <input {...getInputProps()}/>
                                    {
                                        isDragActive ?
                                            <p style={dropStyle}>Droooooooop!</p> :
                                            <p style={baseStyle}><i className="far fa-file mr-2"/>
                                                {this.props.value === null ? 'Drop a file or click to explore' : (this.props.value instanceof File ? this.props.value.name : this.props.value)}
                                                </p>
                                    }
                                </div>
                            )
                        }}
                    </Dropzone>
                </div>
                {this.props.value !== null ? <button className="btn btn-danger ml-1" onClick={(e) => this._onDrop(e)}><i className="fas fa-trash"/></button> : null}
            </div>
        );
    }
}
