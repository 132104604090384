import FormProvider from "../FormProvider";
import Input from "../Input";
import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";
import {createMission,getMissions, cloneMission} from "../../data/missions";
import Select from "../Select";
import {getCustomers} from '../../data/customers';
import SelectSearch from "react-select";

class MissionAddModal extends Component
{
    state = {
        name: '',
        starting_at: '',
        ending_at: '',
        errors: null,
        creating: false,
        sold_days: 0,
        amount: 0,
        customer_id: '',
        all_missions: [],
        mission_to_clone: 0
    };

    cloneMission = () => {
        this.setState({ creating: true });
        let customer_id = this.props.fromMissionPage ? this.state.customer_id : this.props.customerId;
        this.props.cloneMission(this.state.mission_to_clone.value, customer_id)
            .then( () => {
                this.setState({ creating: false })
                this.props.onClose(true);
            })
    }

    renderMissionOptions(missions)
    {
        return missions.map(mission => ({
            'value' : mission.id,
            'label' : mission.customer.name + " : " + mission.name
        }))
    }


    componentDidMount() {
        this.props.getCustomers();
        this.props.getMissions().then((res) => {
            this.setState({
                all_missions: res.payload.data
            });
        });
    }

    create = (e) => {
        let { name, starting_at, ending_at, sold_days, amount } = this.state;
        let customer_id = this.props.fromMissionPage ? this.state.customer_id : this.props.customerId;

        e.preventDefault();
        this.setState({ creating: true });

        this.props.createMission(name, starting_at, ending_at, customer_id, sold_days, amount)
            .then((data) => {
                this.setState({ creating: false })
                this.props.onClose(true);
            })
            .catch((res) => {
                this.setState({ creating: false })
                if (res.error) this.setState({ errors: res.error.response.data.errors })
            });

        return false;
    };

    render()
    {
        return (
            <Modal title="Add a mission" onClose={() => this.props.onClose(false)}>
                {this.renderForm()}
            </Modal>
        )
    }

    renderForm()
    {
        const CUSTOMERS_OPTIONS = this.props.customers.index.map((customer) => ({
            label: customer.name,
            value: customer.id
        }));

        CUSTOMERS_OPTIONS.unshift({ label: 'Veuillez sélectionner un client', value: '' })

        return (
            <div>
                <FormProvider onSubmit={this.create} errors={this.state.errors}>
                    <div className="row">
                        {this.props.fromMissionPage ?
                            <div className="col">
                                <Select name="type"
                                        label="Client"
                                        required
                                        value={this.state.customer_id}
                                        options={CUSTOMERS_OPTIONS}
                                        onChange={(customer_id) => {
                                            if (customer_id === '') return;

                                            const hasLegalRepresentative = this.props.customers.index.filter(c => c.id.toString() === customer_id && c.contacts.filter(co => co.business_function === 'signatory_legal_representative').length > 0).length > 0

                                            if (!hasLegalRepresentative)
                                                window.alert("Il n'y a pas de représentant légal rattaché à ce client")
                                            else
                                                this.setState({ customer_id })
                                        }}
                                />
                            </div>
                            : null}

                        <div className="col">
                            <Input required autoFocus
                                   type={"text"}
                                   name={"name"}
                                   id={"name"}
                                   placeholder={"Mission name *"}
                                   value={this.state.name}
                                   onChange={(name) => this.setState({name})}
                                   label={"Mission name *"}/>
                        </div>
                    </div>
                    <div className={"row mb-4"}>
                        <div className="col">
                            <label htmlFor="modal-starting-at">Sold days</label>
                            <input
                                name="sold_days"
                                type="number"
                                className="form-control"
                                id="modal-starting-at"
                                onChange={(d) => {this.setState({sold_days: d.target.value})}}
                                value={this.state.sold_days}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="modal-ending-at">Amount</label>
                            <input
                                name="ending_at"
                                type="number"
                                className="form-control"
                                id="modal-ending-at"
                                onChange={(d) => {this.setState({amount: d.target.value})}}
                                value={this.state.amount}
                            />
                        </div>
                    </div>
                    <div className={"row mb-4"}>
                        <div className="col">
                            <label htmlFor="modal-starting-at">Start date</label>
                            <input
                                   name="starting_at"
                                   type="date"
                                   className="form-control"
                                   id="modal-starting-at"
                                   onChange={(d) => {this.setState({starting_at: d.target.value})}}
                                   value={this.state.starting_at}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="modal-ending-at">End date</label>
                            <input
                                   name="ending_at"
                                   type="date"
                                   className="form-control"
                                   id="modal-ending-at"
                                   onChange={(d) => {this.setState({ending_at: d.target.value})}}
                                   value={this.state.ending_at}
                            />
                        </div>
                    </div>
                    <button className="btn btn-primary btn-block" disabled={this.state.creating}>Add</button>
                </FormProvider>
                <hr/>
                <h1 className={'bc-modal-title'}>
                    Ou cloner depuis :
                </h1>
                <SelectSearch
                    name={"mission_to_clone"}
                    value={this.state.mission_to_clone}
                    options={this.renderMissionOptions(this.state.all_missions)}
                    placeholder="Select a Mission"
                    searchable={true}
                    onChange={(d) => {this.setState({mission_to_clone: d})}}
                />
                <button className="btn btn-primary btn-block" disabled={this.state.creating} onClick={this.cloneMission} >Clone</button>
                <div style={{height: '200px'}}></div>
            </div>

        )
    }

}

MissionAddModal.defaultProps = {
    onClose: () => {},
    customerId: null,
};

export default connect(({ customers }) => ({ customers }), { getCustomers, createMission, getMissions, cloneMission })(MissionAddModal)
