import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deleteModule, modulePosition} from '../../data/modules';
import ModuleDeleteModal from './ModuleDeleteModal';
import ModuleUpdateModal from './ModuleUpdateModal';
import {getTasks} from "../../data/tasks";
import Empty from "../Empty";
import Task from "../../components/tasks/Task"
import ContentLoader from "../ContentLoader";
import TaskAddModal from "../../components/tasks/TaskAddModal";
import {nl2br} from "../../helpers";

class Module extends Component
{
    state = {
        id: this.props.data.id,
        showDeleteModal: false,
        showUpdateModal: false,
        showAddTaskModal: false,
        tasks: null
    };

    componentDidMount = () => {
        this.getTasks()
    }

    componentWillReceiveProps (props) {

        const tasks = props.tasks.index.filter((task) => task.missionModule_id == this.state.id);

        this.setState({ tasks })
    }

    getTasks = () => {
        this.props.getTasks(this.state.id)
            .then((res) => {
                this.setState({tasks : res.payload.request.response})
            })
    };

    render = () => {
        return (
            <div>
                {this.renderModule()}
                {this.state.showDeleteModal ? <ModuleDeleteModal id={this.props.data.id} onClose={this.onDeleteModalClose} /> : null}
                {this.state.showUpdateModal ? <ModuleUpdateModal data={this.props.data} onClose={this.onUpdateModalClose} /> : null}
                {this.state.showAddTaskModal ? <TaskAddModal id={this.state.id} onClose={this.onAddTaskModalClose} /> : null}
            </div>
        )
    };

    renderModule()
    {
        const module = this.props.data;
        const cursorPointer = {
            cursor: 'pointer'
        };
        const cardHeader = {
            background: "#B4B4BB"
        };
        return (
            <div className="card">
                <div className={"card-header"} style={cardHeader}>
                    <div className={"row"}>
                        <div className={"col-md-8"}>
                            <h5 className={"card-title d-inline-block mt-2"}>{module.name}</h5>
                        </div>
                        <div className={"d-inline-block col-md-2"}>
                            <div className={"float-right mt-2"}>
                                <span style={cursorPointer} onClick={this.movePositionUp}>
                                    <i className={"fas fa-angle-up mr-3"}></i>
                                </span>
                                <span style={cursorPointer} onClick={this.movePositionDown}>
                                    <i className={"fas fa-angle-down"}></i>
                                </span>
                            </div>
                        </div>
                        <div className={"col-md-2 d-inline-block"}>
                            <div className={"float-right"}>
                                <button onClick={this.onDeleteModalOpen} className="btn btn-danger mr-1">
                                    <i className="fa fa-trash"></i>
                                </button>
                                <button onClick={this.onUpdateModalOpen} className="btn btn-secondary">
                                    <i className="fa fa-edit"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <p>{module.description ? nl2br(module.description) : null}</p>
                    {this.renderTasks()}
                    <div className={"text-center"}>
                        <button
                            className={"btn btn-primary"}
                            onClick={this.onAddTaskModalOpen}>
                            Add a task
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    renderTasks = () => {
        const tasks = this.state.tasks;
        if (!tasks) return <ContentLoader/>;
        if (!tasks.length) return <Empty text={"No tasks added"} />;

        tasks.sort((a, b) => {
            return a.position > b.position ? 1 : -1;
        });

        return (
            <div className={"tasks mt-3"}>
                { tasks.map((task) => this.renderTask(task)) }
            </div>
        )
    }

    renderTask = (task) => {
        const taskMargin = {
            margin: "3rem auto"
        };
        return (
            <div className="col-md-9 mb-3" key={task.id} style={taskMargin}>
                <Task data={task} moduleId={this.state.id} onMoved={this.getTasks} onDelete={this.getTasks} />
            </div>
        )
    }

    movePositionUp = () => {
        this.props.modulePosition(this.props.data.id, this.props.data.position - 1, this.props.missionId)
            .then(() => {
                this.props.onMoved()
            })
    }

    movePositionDown = () => {
        this.props.modulePosition(this.props.data.id, this.props.data.position + 1, this.props.missionId)
            .then(() => {
                this.props.onMoved()
            })
    }

    onDeleteModalOpen = () => this.setState({ showDeleteModal: true })
    onDeleteModalClose = (deleted) => {
        this.setState({ showDeleteModal: false })
        if (deleted) this.props.onDelete()
    }

    onUpdateModalOpen = () => this.setState({ showUpdateModal: true })
    onUpdateModalClose = () => this.setState({ showUpdateModal: false })

    onAddTaskModalOpen = () => this.setState({ showAddTaskModal: true });
    onAddTaskModalClose = (updated) => {
        this.setState({ showAddTaskModal: false });
        if (updated) this.getModules()
    }

}

Module.defaultProps = {
    onDelete: () => {},
    onMoved: () => {},
}

export default connect(({ tasks }) => ({ tasks }), {deleteModule, modulePosition, getTasks})(Module);
