import React, {Component} from 'react';
import Modal from '../Modal';
import FormProvider from "../FormProvider";
import Input from "../Input";
import Textarea from "../Textarea";
import {updateMissionCalendar} from '../../data/missionCalendars';
import {connect} from "react-redux";

class MissionCalendarUpdateModal extends Component
{
    state = {
        id: this.props.data.id,
        title: this.props.data.title,
        description: this.props.data.description,
        errors: null,
        updating: false
    };

    update = (e) => {

        e.preventDefault();
        const {id, title, description} = this.state;

        this.setState({ updating: true });

        this.props.updateMissionCalendar(id, title, description)
            .then((res) => {
                this.updated(res.payload.data.mission_calendar);
            })
            .catch((res) => {
                if (res.error) this.setState({ errors: res.error.response.data.errors });
                this.setState({ updating: false });
            });

        return false;
    };

    updated(data) {
        this.props.onUpdate(data);
        this.props.onClose();
    }

    render() {
        return(
            <Modal title="Update the calendar" onClose={this.props.onClose}>
                {this.renderForm()}
            </Modal>
        )
    }

    renderForm = () => {
        return (
            <FormProvider onSubmit={this.update} errors={this.state.errors}>
                <div className="row">
                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"title"}
                               id={"title"}
                               placeholder={"Calendar title *"}
                               value={this.state.title}
                               onChange={(title) => this.setState({title})}
                               label={"Calendar title *"}/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Textarea
                            name={"description"}
                            id={"description"}
                            onChange={(description) => this.setState({description})}
                            label={"Description"}
                            value={this.state.description}
                        />
                    </div>
                </div>
                <button className="btn btn-primary btn-block" disabled={this.state.updating}>Update</button>
            </FormProvider>
        )
    };
}

export default connect(null, {updateMissionCalendar})(MissionCalendarUpdateModal);
