import React, {Component} from 'react'

const FormContext = React.createContext({ errors: [] });

export default class FormProvider extends Component
{
  constructor(props) {
    super(props)
    this.state = {
      errors: props.errors
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      errors: props.errors
    })
  }

  render() {
    return (
      <FormContext.Provider value={this.state}>
        <form onSubmit={this.props.onSubmit} className={this.state.errors ? null : null}>
          {this.props.children}
        </form>
      </FormContext.Provider>
    )
  }
}

FormProvider.defaultProps = {
  errors: null
}

export class FormConsumer extends Component
{
  render()
  {
    return (
      <FormContext.Consumer>
        {(props) => this.props.children(props)}
      </FormContext.Consumer>
    )
  }
}