import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import '../../styles/pages/drive.scss';
import Loader from "../Loader";
import DeleteDriveFilesModal from "./DeleteDriveFilesModal";
import MoveSelectionModal from "./MoveSelectionModal";
import {
    getFolder,
    createFolder,
    renameFolder,
    deleteResources,
    moveResources,
    uploadFiles,
    downloadFiles,
    renameFile
} from "../../data/drive";

import fileGeneric from '../../images/file_icons/file.svg';
import filePDF from '../../images/file_icons/pdf.svg';
import fileMP4 from '../../images/file_icons/mp4.svg';
import fileMP3 from '../../images/file_icons/mp3.svg';
import filePNG from '../../images/file_icons/png.svg';
import filePPT from '../../images/file_icons/ppt.svg';
import fileFolder from '../../images/file_icons/folder.svg';
import fileZip from '../../images/file_icons/zip.svg';
import DownloadModal from "./DownloadModal";
import {withRouter} from "react-router-dom";
import PrivilegesButton from "../privileges/PrivilegesButton";
import {API_URL} from "../../config";
import Cookies from "universal-cookie/cjs/Cookies";
import Empty from "../Empty";


class Drive extends Component {

    state = {
        fileList: [],
        isLoading: true,
        breadcrumb: [],
        directoryStructure: {},
        forbiddenUids: [],
        currentFolder: '',
        showDeleteModal: false,
        showDirectoriesModal: false,
        showDownloadModal: false,
        downloadInfo: {},
        auth_token: (new Cookies()).get('hub_token')
    };

    componentWillReceiveProps (props) {
        if (props.hash != this.state.currentFolder && props.router) {
            this.loadDirectory(props.hash, true)
        }
    }

    componentDidMount() {
        this.loadDirectory(this.props.hash, true);
    };

    mimeToThumbnailURL = (mime) => {
        switch (mime) {
            case 'folder':
                return fileFolder;
            case 'application/pdf':
                return filePDF;
            case 'mp3':
                return fileMP3;
            case 'mp4':
                return fileMP4;
            case 'ppt':
                return filePPT;
            case 'image/png':
                return filePNG;
            case 'application/zip':
                return fileZip;
            default:
                return fileGeneric;
        }
    };

    placeholder = () => {
        //console.log("placeholder");
    };

    loadDirectory = (uid, files = true) => {
        this.setState({isLoading: true});

        uid = (uid === null) ? 'null' : uid;

        // Async
        this.props.getFolder(uid, files)
            .then((r) => {
                const bc = r.payload.data.breadcrumb;
                const files = r.payload.data.folders;
                const newuid = bc[bc.length - 1].uid //(uid === '-1' ? bc[0].uid : uid);

                let newfiles = [];
                files.forEach((f) => {
                    newfiles.push({
                        name: f.name,
                        mime: f.type,
                        uid: f.uid,
                        lastEditDate: 'Never edited',
                        selected: false,
                        editing: false
                    });
                });

                this.setState(
                    {
                        breadcrumb: bc,
                        fileList: newfiles,
                        isLoading: false,
                        currentFolder: newuid
                    },
                    () => {
                        if (this.props.router && newuid != this.props.hash)
                            this.props.history.push(this.props.path + '/' + newuid);
                    }
                );
            })
        ;


    };

    toggleRowSelected = (uid) => {
        this.state.fileList.filter(x => x.uid === uid)[0].selected = !this.state.fileList.filter(x => x.uid === uid)[0].selected;
        this.setState(this.state);
    };

    toggleRowEditing = (uid, inputDOM) => {
        const editing = this.state.fileList.filter(x => x.uid === uid)[0].editing;
        this.state.fileList.filter(x => x.uid === uid)[0].editing = !editing;
        this.setState(this.state);
        return !editing;
    };

    onDriveRowClick = (e) => {
        const uid = e.currentTarget.dataset.target;

        // Toggle selection
        this.toggleRowSelected(uid);
    };

    onDriveRowDoubleClick = (e) => {
        e.preventDefault();
        const uid = e.currentTarget.dataset.target;
        const mime = e.currentTarget.dataset.mime;

        if (mime === 'folder') {
            this.loadDirectory(uid);
            return;
        }

        window.open(API_URL + '/drive/file/read/' + uid + '?auth_token=' + this.state.auth_token, 'blank');
    };

    toggleEdit = (rowId, event) => {
        if (this.toggleRowEditing(rowId, event.target.parentElement.querySelector('input'))) {
            this.toggleRowSelected(rowId);
        }

        let editIcon = event.target.parentElement.querySelector("i.fa");
        editIcon.classList.toggle("fa-edit");
    };

    confirmRename = (node, event) => {
        if (event.key !== "Enter") return;
        const newName = event.target.value;

        this.setState({isLoading: true});

        if (node.mime === "folder") {
            this.props.renameFolder(node.uid, newName)
                .then(() => {
                    this.loadDirectory(this.state.currentFolder);
                });
        }
        else {
            this.props.renameFile(node.uid, newName)
                .then(() => {
                    this.loadDirectory(this.state.currentFolder);
                });
        }
    };

    confirmDelete = () => {
        const nodes = this.state.fileList.filter(x => x.selected);

        this.props.deleteResources(nodes)
            .then(() => {
                this.loadDirectory(this.state.currentFolder);
                this.setState({showDeleteModal: false});
            });
    };

    onMoveModalOpen = () => {
        let rootStruct = {
            name: this.state.breadcrumb[0].name,
            uid: this.state.breadcrumb[0].uid,
            mime: 'directory',
            toggled: false,
            children: []
        };

        // On cherche les endroits où l'utilisateur n'a pas le droit de déplacer le contenu
        const selected = this.state.fileList.filter(x => x.selected);
        let uids = [];
        selected.forEach((e) => {
            if (e.mime === "folder")
                uids.push(e.uid);
        });

        this.setState({
            showDirectoriesModal: true,
            forbiddenUids: uids,
            directoryStructure: rootStruct
        });
    };

    onMoveModalConfirm = (node) => {
        const selected = this.state.fileList.filter(x => x.selected);

        this.setState({showDirectoriesModal: false});
        this.props.moveResources(selected, node.uid)
            .catch((e) => {
                console.warn("Une erreur s'est produite du move: ", e)
            })
            .then(() => {
                this.loadDirectory(this.state.currentFolder);
            });
    };

    openNewFolderModal = () => {
        // TMP
        let name = prompt("Folder name");
        this.onNewFolderConfirm(name);
    };

    onNewFolderConfirm = (name) => {
        this.props.createFolder(this.state.currentFolder, name)
            .then((r) => {
                this.loadDirectory(this.state.currentFolder);
            });
    };

    onFileSubmit = (e) => {
        let files = e.target.files;
        if (files.length === 0) return;

        this.props.uploadFiles(files, this.state.currentFolder)
            .then((r) => {
                this.loadDirectory(this.state.currentFolder);
            });
    };

    onDownloadClicked = () => {
        this.setState({
            showDownloadModal: true,
            downloadInfo: {message: "Please wait, your files are being processed..", loaded: false, link: ""}
        });
        const nodes = this.state.fileList.filter(x => x.selected);
        this.props.downloadFiles(nodes)
            .then((r) => {
                const result = r.payload;
                if (!result) {
                    this.setState({
                        downloadInfo: {
                            message: "Oh oh, something went wrong.. Make sure you selected at least one file to download or try again later",
                            loaded: false,
                            link: ""
                        }
                    });
                    return;
                }

                this.setState({
                    downloadInfo: {
                        message: result.data.message,
                        loaded: true,
                        link: result.data.url,
                        auth_token: result.config.headers["X-AUTH-TOKEN"] || ""
                    }
                });
            })
            .catch((r) => {
                const result = r.payload;
                if (!result) {
                    this.setState({
                        downloadInfo: {
                            message: "Oh oh, something went wrong.. Make sure you selected at least one file to download or try again later",
                            loaded: false,
                            link: ""
                        }
                    });
                }
            });

    };

    // == Sous renders

    renderDrive = () => {
        if (this.state.isLoading) {
            return <div className="text-center"><Loader display={this.state.isLoading}/></div>
        }

        let rows = this.createRowList();

        if (rows.length === 0) {
            return <div className="drive-wrapper">
                {this.createNavigationBar()}
                <Empty/>
            </div>;
        }

        return <div className="drive-wrapper">
            {this.createNavigationBar()}
            {rows}
        </div>;
    };

    createRowList = () => {
        let rows = [];

        this.state.fileList.forEach((e) => {
            rows.push(<div className="drive-row d-flex justify-content-between" key={e.uid} onClick={this.onDriveRowClick}
                           onDoubleClick={this.onDriveRowDoubleClick} data-mime={e.mime} data-target={e.uid}>
                <div style={{flexDirection: 'row'}}>
                    <input className="mr-3 h-100" type="checkbox" checked={e.selected} onClick={this.placeholders} onChange={this.placeholder}/>
                    <div className="file-thumbnail-wrapper mr-3">
                        <img src={this.mimeToThumbnailURL(e.mime)} alt="file thumbnail"/>
                    </div>
                </div>

                <span style={{flex: 1}} className="overflow-hidden">
                    <span>
                        <span className={"row-file-name mr-2 " + (e.editing ? 'd-none' : null)}
                              data-id={e.uid}>{e.name}</span>
                        <i style={{cursor: 'pointer', color: '#6d6d6d'}} title="Rename" onClick={(ev) => {
                            this.toggleEdit(e.uid, ev)
                        }} className={"row-edit-icon fa fa-edit d-none"}> </i>
                        <input defaultValue={e.name} className={e.editing ? null : "d-none"}
                               onClick={() => this.toggleRowSelected(e.uid)} onKeyPress={(ev) => {
                            this.confirmRename(e, ev)
                        }} autoFocus/>
                    </span>
                </span>
                <span style={{whiteSpace: 'nowrap'}} className="text-right pl-1">
                    {e.lastEditDate}
                </span>
            </div>);
        });

        return rows;
    };

    createNavigationBar = () => {
        let breadcrumb = [];

        this.state.breadcrumb.forEach((e, i) => {
            breadcrumb.push(<span key={i} className="drive-dir-title"
                                  onClick={() => this.loadDirectory(e.uid)}>{e.name}</span>);
            breadcrumb.push(<span key={i + 1000} className="separator">/</span>);
        });

        breadcrumb.pop();

        return <div className="drive-navigation-bar drive-row">
            {breadcrumb}
        </div>
    };


    // == Render

    render() {
        return (
            <div>
                {this.state.showDeleteModal ?
                    <DeleteDriveFilesModal onClose={() => this.setState({showDeleteModal: false})}
                                           onConfirm={this.confirmDelete}/> : null}
                {this.state.showDirectoriesModal ?
                    <MoveSelectionModal files={this.state.directoryStructure}
                                        forbidden={this.state.forbiddenUids}
                                        onClose={() => this.setState({showDirectoriesModal: false})}
                                        onConfirm={(node) => {
                                            this.onMoveModalConfirm(node)
                                        }}/> : null}
                {this.state.showDownloadModal ?
                    <DownloadModal onClose={() => this.setState({showDownloadModal: false})}
                                   onConfirm={this.confirmDelete} data={this.state.downloadInfo}/> : null}
                <div className="row mb-3">
                    <div className="col-12">
                        <div className="float-left">
                            <input id="drive-file-upload-input" type="file" onChange={this.onFileSubmit} multiple/>
                            <label style={{margin: 0}} className="btn btn-primary mr-1"
                                    htmlFor="drive-file-upload-input" onClick={this.placeholder}>
                                <i className="fa fa-upload mr-2"></i>Upload files
                            </label>
                            <button className="btn btn-primary mr-1" onClick={this.openNewFolderModal}>
                                <i className="fa fa-folder-plus mr-2"></i>Create folder
                            </button>
                            {this.state.breadcrumb.length ? <PrivilegesButton id={this.state.breadcrumb[this.state.breadcrumb.length - 1].id} type="Folder" /> : null}
                        </div>

                        {
                            this.state.fileList.filter(x => x.selected).length > 0
                                ?
                                <div className="float-right">
                                    <button className="btn btn-secondary mr-1" onClick={this.onDownloadClicked}>
                                        <i className="fa fa-download mr-2"></i>Download archive
                                    </button>
                                    <button className="btn btn-secondary mr-1" onClick={this.onMoveModalOpen}>
                                        <i className="fa fa-arrow-right mr-2"></i>Move
                                    </button>
                                    <button className="btn btn-danger mr-1"
                                            onClick={() => this.setState({showDeleteModal: true})}>
                                        <i className="fa fa-trash mr-2"></i>Delete
                                    </button>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Dropzone disableClick={true}>
                            {({getRootProps, getInputProps, isDragActive}) => {
                                return (
                                    <div
                                        {...getRootProps()}
                                        className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                                    >
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                                <div style={{background: 'red'}}>Drop files here...</div> :
                                                this.renderDrive()
                                        }
                                    </div>
                                )
                            }}
                        </Dropzone>
                    </div>
                </div>
            </div>
        )
    }
}

Drive.defaultProps = {
    hash: null,
    router: true,
    path: '/drive'
}

export default connect(({drive}) => ({drive}), {
    getFolder,
    createFolder,
    renameFolder,
    deleteResources,
    moveResources,
    uploadFiles,
    downloadFiles,
    renameFile
})(withRouter(Drive));
