import {ROLE_CHIEF, ROLE_EMPLOYEE, ROLE_GUEST, ROLE_TRAINEE, ROLE_USER, ROLE_BLUESQUARE} from "./roles";

import LoginPage from "../pages/auth/LoginPage";
import AuthLayout from "../layouts/AuthLayout";
import BaseLayout from "../layouts/BaseLayout";
import RegisterPage from "../pages/auth/RegisterPage";
import NotFoundPage from "../pages/NotFoundPage";
import CustomersPage from "../pages/CustomersPage";
import CustomerPage from "../pages/CustomerPage";
import UsersPage from "../pages/UsersPage";
import UserPage from "../pages/UserPage";
import NotesPage from '../pages/NotesPage';
import MissionPage from "../pages/MissionPage";
import EmptyLayout from "../layouts/EmptyLayout";
import MissionsPage from "../pages/MissionsPage";
import InvoicesPage from "../pages/InvoicesPage";
import InvoicePage from "../pages/InvoicePage";
import BadAssGeneratorPage from "../pages/bag/HomePage";
import BadAssEditorPage from "../pages/bag/EditorPage";
import StatsPage from "../pages/StatsPage";
import TimesquarePage from "../pages/TimesquarePage";
import StaffingPage from "../pages/StaffingPage";
import ContactsPage from "../pages/ContactsPage";
import ContactPage from "../pages/ContactPage";
import LeadPages from "../pages/LeadPages";
import ConnectPage from "../pages/auth/ConnectPage"

const routes = [
    {
        path: '/',
        exact: true,
        page: UsersPage,
        layout: BaseLayout,
        roles: [ROLE_BLUESQUARE]
    },

    // auth
    {
        path: '/login',
        exact: true,
        page: LoginPage,
        layout: AuthLayout,
        roles: [ROLE_GUEST]
    },
    {
        path: '/register',
        exact: true,
        page: RegisterPage,
        layout: BaseLayout,
        roles: [ROLE_GUEST]
    },
    {
        path: '/connect/:token',
        exact: false,
        page: ConnectPage,
        layout: null,
        roles: [ROLE_GUEST]
    },
    // Notes
    {
        path: '/notes',
        exact: true,
        layout: BaseLayout,
        page: NotesPage,
        roles: [ROLE_BLUESQUARE],
        rights: ['notes'],
        layoutProps: {
            extended: true
        }
    },
    // Customers
    {
        path: '/customers',
        exact: true,
        layout: BaseLayout,
        page: CustomersPage,
        roles: [ROLE_BLUESQUARE],
        rights: ['customers']
    },
    {
        path: '/customers/:id',
        exact: true,
        layout: BaseLayout,
        page: CustomerPage,
        roles: [ROLE_BLUESQUARE],
        rights: ['customers'],
        layoutProps: {
            extended: true
        }
    },
    // Users
    {
        path: '/users',
        exact: true,
        layout: BaseLayout,
        page: UsersPage,
        roles: [ROLE_BLUESQUARE],
        rights: ['users']
    },
    {
        path: '/users/:id',
        exact: true,
        layout: BaseLayout,
        page: UserPage,
        roles: [ROLE_BLUESQUARE],
        rights: ['users'],
        layoutProps: {
            extended: true
        }
    },

    //Contacts
    {
        path: '/contacts',
        exact: true,
        layout: BaseLayout,
        page: ContactsPage,
        roles: [ROLE_BLUESQUARE],
        rights: ['users']
    },
    {
        path: '/contacts/:id',
        exact: true,
        layout: BaseLayout,
        page: ContactPage,
        roles: [ROLE_BLUESQUARE],
        rights: ['users'],
        layoutProps: {
            extended: true
        }
    },

    // Mission
    {
        path: '/missions',
        exact: true,
        layout: BaseLayout,
        page: MissionsPage,
        roles: [ROLE_BLUESQUARE],
        layoutProps: {
            extended: true
        }
    },
    {
        path: '/missions/:id',
        exact: true,
        layout: BaseLayout,
        page: MissionPage,
        roles: [ROLE_BLUESQUARE],
        layoutProps: {
            extended: true
        }
    },

    //Invoices
    {
        path: '/invoices/:mission?',
        exact: true,
        layout: BaseLayout,
        page: InvoicesPage,
        roles: [ROLE_BLUESQUARE],
        layoutProps: {
            extended: true
        }
    },
    {
        path: '/invoice/:id',
        exact: true,
        layout: BaseLayout,
        page: InvoicePage,
        roles: [ROLE_BLUESQUARE],
        layoutProps: {
            extended: true
        }
    },
    // Statistiques
    {
        path: '/stats',
        exact: true,
        layout: BaseLayout,
        page: StatsPage,
        roles: [ROLE_BLUESQUARE]
    },
    {
        path: '/staffing',
        exact: true,
        layout: BaseLayout,
        page: StaffingPage,
        roles: [ROLE_BLUESQUARE]
    },
    // Timesquare
    {
        path: '/timesquare',
        exact: true,
        layout: BaseLayout,
        page: TimesquarePage,
        roles: [ROLE_BLUESQUARE]
    },

    // BAG : TODO REMOVE
    {
        path: '/bag',
        exact: true,
        page: BadAssGeneratorPage,
        layout: BaseLayout,
        roles: [ROLE_BLUESQUARE]
    },
    //BAG : TODO REMOVE
    {
        path: '/bag/:id',
        exact: true,
        page: BadAssEditorPage,
        layout: EmptyLayout,
        roles: [ROLE_BLUESQUARE]
    },

    // Lead
    {
        path: '/call',
        exact: true,
        page: LeadPages,
        layout: EmptyLayout,
        roles: [ROLE_BLUESQUARE]
    },

    // extra
    {
        path: '/',
        exact: false,
        layout: null,
        page: NotFoundPage,
        roles: [ROLE_GUEST, ROLE_USER]
    }
];

export default routes
