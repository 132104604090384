import React, {Component} from 'react';
import {register} from "../../data/auth";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import FormProvider from "../../components/FormProvider";
import Input from "../../components/Input";

class RegisterPage extends Component
{
    state = {
        email: '',
        password: '',
        confirm_password: '',
        firstname: '',
        lastname: '',
        gender: 'M',
        errors: null
    }

    register = (e) => {
        e.preventDefault()
        if (this.state.password === this.state.confirm_password) {
            this.props.register(this.state.email, this.state.password, this.state.firstname, this.state.lastname, this.state.gender)
                .then((data) => {
                    this.props.history.push('/')
                })
                .catch((res) => {
                    this.setState({ errors: res.error.response.data.errors })
                })
        } else {
          this.setState({
            errors: {
                confirm_password: { key: "auth.register.invalid:confirm_password" }
            }
          })
        }
        return false;
    }

    render = () => {
        return (
            <div className="container">
                <div className="card card-register mx-auto mt-5">
                    <div className="card-header">Register an account</div>
                    <div className="card-body">
                        {this.renderForm()}
                        {this.renderLinks()}
                    </div>
                </div>
            </div>
        )
    }

    renderForm = () => {
        return (
            <FormProvider onSubmit={this.register} errors={this.state.errors}>

                <div className={"form-row"}>
                    <div className={"col-md-6"}>
                        <Input required autoFocus
                               type={"text"}
                               name={"firstname"}
                               id={"firstName"}
                               value={this.state.firstname}
                               placeholder={"First name"}
                               onChange={(firstname) => this.setState({ firstname })}
                               label={"First name"}  />
                    </div>
                    <div className={"col-md-6"}>
                        <Input required
                               type={"text"}
                               name={"lastname"}
                               id={"lastName"}
                               value={this.state.lastname}
                               placeholder={"Last name"}
                               onChange={(lastname) => this.setState({ lastname }) }
                               label={"Last name"} />
                    </div>
                </div>

                <Input required
                       type={"email"}
                       name={"email"}
                       id={"inputEmail"}
                       value={this.state.email}
                       placeholder={"Email address"}
                       onChange={(email) => this.setState({ email }) }
                       label={"Email address"} />

                <div className={"form-row"}>
                    <div className={"col-md-6"}>
                        <Input required
                               type={"password"}
                               name={"password"}
                               id={"inputPassword"}
                               value={this.state.password}
                               placeholder={"Password"}
                               onChange={(password) => this.setState({ password })}
                               label={"Password"} />
                    </div>
                    <div className={"col-md-6"}>
                        <Input required
                               type={"password"}
                               name={"confirm_password"}
                               id={"confirm_password"}
                               placeholder={"Confirm password"}
                               label={"Confirm password"}
                               value={this.state.confirm_password}
                               onChange={(confirm_password) => this.setState({ confirm_password })}/>
                    </div>
                </div>

                <button className="btn btn-primary btn-block mb-3">Register</button>
            </FormProvider>
        )
    }

    renderLinks = () => {
        return (
            <div className="text-center">
                <Link to={"/login"} className={"d-block small"}>Already have an account?</Link>
            </div>
        )
    }
}

export default connect(null, { register })(RegisterPage)
