import React, {Component} from 'react'

export default class NotFoundPage extends Component
{
  render() {
    return (
      <div className="NotFoundPage">
        <h1>Blue<strong>fail</strong></h1>
        <i className="fas fa-sad-cry text-white mb-3"/>
        <p className="text-muted">This page doesn't exist.</p>
      </div>
    )
  }
}