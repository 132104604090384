import React, {Component} from 'react'

export default class MyTreeNode extends Component {
    state = {
        toggled: false
    };

    onClick = () => {
        let toggled = !this.state.toggled;
        this.setState({toggled: toggled});
        this.props.onToggle(this.props.node, toggled);
    };

    render() {
        return (
            <li className={"tree-node"}>
                {
                    this.renderNode()
                }


                {this.state.toggled ? this.renderChildren() : null}

            </li>
        );
    }

    renderNode = () => {
        if (this.props.forbidden.indexOf(this.props.node.uid) > -1) {
            return <div className={"tree-node-name tree-node-forbidden"}>
                <i className="fa fa-stop mr-2"></i>
                {this.props.node.name} - Forbidden
            </div>;
        }

        if (this.props.node.loading) {
            return <div className={"tree-node-name tree-node-forbidden"}>
                <i className="fa fa-circle-notch fa-spin mr-2"></i>
                {this.props.node.name} - Loading...
            </div>
        }

        // else
        return <div onClick={this.onClick}
             className={"tree-node-name" + (this.props.node.active ? " tree-node-active" : "")}>
            {this.state.toggled ?
                <i className="fa fa-caret-down mr-2"></i> :
                <i className="fa fa-caret-right mr-2"></i>
            }
            {this.props.node.name}
        </div>;
    };

    renderChildren = () => {
        let node = this.props.node;

        let children = node.children;
        if (!Array.isArray(children)) {
            children = children ? [children] : [];
        }

        return (
            <ul>
                {children.map((child, index) => <MyTreeNode {...this._eventBubbles()}
                                                            key={child.id || index}
                                                            node={child}
                                                            forbidden={this.props.forbidden}
                    />
                )}
            </ul>
        );
    };

    _eventBubbles() {
        const {onToggle} = this.props;

        return {
            onToggle
        };
    }
}
