import React, {Component} from 'react';
import Modal from "../Modal";
import Input from "../Input";
import Select from "../Select";
import FormProvider from "../FormProvider";
import {connect} from "react-redux";
import {updateUser} from "../../data/users";
import moment from "moment";



class UserUpdateModal extends Component
{
    state = {
        id: this.props.data.id,
        firstname: this.props.data.firstname,
        lastname: this.props.data.lastname,
        birthdate: this.props.data.birthdate ? moment(this.props.data.birthdate.date).format('YYYY-MM-DD') : null,
        social_security_number: this.props.data.social_security_number,
        address: this.props.data.address,
        zipcode: this.props.data.zipcode,
        city: this.props.data.city,
        marital_name: this.props.data.marital_name,
        nationality: this.props.data.nationality,
        phone: this.props.data.phone,
        skype: this.props.data.skype,
        email: this.props.data.email,
        gender: this.props.data.gender,
        is_active: this.props.data.is_active,
        errors: null,
        updating: false
    };

    update = (e) => {
        let {id, firstname, lastname, birthdate, social_security_number, address, zipcode, city, marital_name, nationality, phone, skype, gender, email, is_active } = this.state;

        e.preventDefault();
        this.setState({ updating: true });

        this.props.updateUser(id, firstname, lastname, birthdate, social_security_number, address, zipcode, city, marital_name, nationality, phone, skype, gender, email, is_active)
            .then((data) => {
                this.props.onClose(true);
                this.props.onUpdated(true);
            })
            .catch((res) => {
                if (res.error) this.setState({ errors: res.error.response.data.errors });
                this.setState({ updating: false });
            });

        return false;
    };

    render = () => {
        return (
            <Modal title="Update a user" onClose={() => this.props.onClose(false)}>
                {this.renderForm()}
            </Modal>
        )
    };

    renderForm = () => {
        return (
            <FormProvider onSubmit={this.update} errors={this.state.errors}>
                <div className="row">
                    <div className="col-6">
                        <Input required autoFocus
                               type={"text"}
                               name={"firstname"}
                               id={"firstname"}
                               placeholder={"Firstname *"}
                               value={this.state.firstname}
                               onChange={(firstname) => this.setState({firstname})}
                               label={"Firstname *"}/>

                    </div>
                    <div className="col-6">
                        <Input required
                               type={"text"}
                               name={"lastname"}
                               id={"lastname"}
                               placeholder={"Lastname *"}
                               value={this.state.lastname}
                               onChange={(lastname) => this.setState({lastname})}
                               label={"Lastname *"}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input
                            type={"text"}
                            name={"marital_name"}
                            id={"marital_name"}
                            placeholder={"Marital name"}
                            value={this.state.marital_name}
                            onChange={(marital_name) => this.setState({marital_name})}
                            label={"Marital name"}/>
                    </div>
                    <div className="col-6">
                        <Input
                            name="birthdate"
                            type="date"
                            className="form-control"
                            label="Birthdate"
                            id="modal-birthdate"
                            onChange={(d) => {this.setState({birthdate: d})}}
                            value={this.state.birthdate}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input required
                               type={"text"}
                               name={"phone"}
                               id={"phone"}
                               placeholder={"Phone *"}
                               value={this.state.phone}
                               onChange={(phone) => this.setState({phone})}
                               label={"Phone *"}/>
                    </div>
                    <div className="col-6">
                        <Input required
                               type={"text"}
                               name={"skype"}
                               id={"skype"}
                               placeholder={"Skype *"}
                               value={this.state.skype}
                               onChange={(skype) => this.setState({skype})}
                               label={"Skype *"}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input required
                               type={"email"}
                               name={"email"}
                               id={"email"}
                               placeholder={"Email"}
                               value={this.state.email}
                               onChange={(email) => this.setState({email})}
                               label={"Email *"}/>
                    </div>
                    <div className="col-6">
                        <Input
                            type={"text"}
                            name={"nationality"}
                            id={"nationality"}
                            placeholder={"Nationality"}
                            value={this.state.nationality}
                            onChange={(nationality) => this.setState({nationality})}
                            label={"Nationality"}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input
                            type={"text"}
                            name={"address"}
                            id={"address"}
                            placeholder={"Address"}
                            value={this.state.address}
                            onChange={(address) => this.setState({address})}
                            label={"Address"}/>
                    </div>
                    <div className="col-6">
                        <Input
                            type={"text"}
                            name={"zipcode"}
                            id={"zipcode"}
                            placeholder={"Zipcode"}
                            value={this.state.zipcode}
                            onChange={(zipcode) => this.setState({zipcode})}
                            label={"Zipcode"}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Input
                            type={"text"}
                            name={"city"}
                            id={"city"}
                            placeholder={"City"}
                            value={this.state.city}
                            onChange={(city) => this.setState({city})}
                            label={"City"}/>
                    </div>
                    <div className="col-6">
                        <Input
                            type={"text"}
                            name={"social_security_number"}
                            id={"social_security_number"}
                            placeholder={"Social security number"}
                            value={this.state.social_security_number}
                            onChange={(social_security_number) => this.setState({social_security_number})}
                            label={"Social security number"}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Select name="type"
                                label="Gender"
                                required
                                value={this.state.gender}
                                options={[
                                    { label: 'Male', value: 'M' },
                                    { label: 'Female', value: 'F' }
                                ]}
                                onChange={(gender) => this.setState({ gender })} />
                    </div>
                    <div className={'col-6'}>
                        <Select name="type"
                                label="Actif"
                                required
                                value={this.state.is_active}
                                options={[
                                    { label: 'Inactif', value: 0 },
                                    { label: 'Actif', value: 1 }
                                ]}
                                onChange={(is_active) => this.setState({ is_active })} />
                    </div>
                </div>
                <button className="btn btn-primary btn-block" disabled={this.state.updating}>Update</button>
            </FormProvider>
        )
    };

}

UserUpdateModal.defaultProps = {
    onUpdated: () => {}
};

export default connect(null, { updateUser }) (UserUpdateModal)
