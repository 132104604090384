import React, {Component} from 'react';
import '../styles/components/plaincheckbox.scss';


export default class PlainCheckbox extends Component {
    render() {
        return (
            <label className="plain-check container d-flex"><div>{this.props.label}</div>
                <input type="checkbox"
                       name={this.props.name}
                       checked={this.props.value}
                       onChange={(e) => this.props.onChange(e.target.checked)}
                />
                <span className={"plain-check-control" + (this.props.value ? " checked" : '')}>
                    <i className="fa fa-check"></i>
                </span>
            </label>
        )
    }
}

PlainCheckbox.defaultProps = {
    name: 'checkbox',
    label: 'Checkbox label',
    value: false,
    hideInput: true
};
