import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getUsers} from "../data/users";
import {chunks} from "../helpers";
import ContentLoader from "../components/ContentLoader";
import Empty from "../components/Empty";
import Item from "../components/Item";
import Informations from "../components/Informations";
import User from "../components/users/User";
import PrivilegesButton from "../components/privileges/PrivilegesButton";

class UsersPage extends Component
{
    state = {
        only_active: true
    }

    componentDidMount()
    {
        this.props.getUsers(true)
    }

    updateOnlyActive(){
        let current_value = this.state.only_active;

        this.setState( {only_active: !current_value} , () => {
            this.props.getUsers(this.state.only_active)
        })
    }

    render = () => {
        return (
            <div>
                <h2><span>Users</span> <PrivilegesButton type="User" /></h2>
                <em onClick={ () => this.updateOnlyActive() } style={{cursor: 'pointer'}}>
                    {this.state.only_active ? 'Voir les anciens employés' : 'Voir les employés actuels'}
                </em>
                {this.renderUsers(this.state.only_active)}
            </div>
        )
    };

    renderUsers = () => {
        const users = chunks(this.props.users.index, 3);

        if (this.props.users.loading) return <ContentLoader/>
        if (!users.length) return <Empty text={"No users yet"}/>

        return(
            <div className={"users mt-3"}>
                {users.map((chunk) => this.renderUserRow(chunk))}
            </div>
        )
    };

    renderUserRow = (users) => {
        return (
            <div className={"row mb-3"} key={users[0].id}>
                {users.map((user) => this.renderUser(user))}
            </div>
        )
    }

    renderUser = (user) => {
        return (
            <div className="col-md-4" key={user.id}>
                <User data={user}/>
            </div>
        )
    }
}

export default connect(({users}) => ({users}), {getUsers})(UsersPage)