import {request} from '../helpers'

import Cookies from 'universal-cookie';

const cookies = new Cookies();

//

export const AUTH_REGISTER = 'AUTH_REGISTER'

export function register(email, password, firstname, lastname, gender)
{
    const data = { email, password, firstname, lastname, gender }
    return request(AUTH_REGISTER, 'put', '/auth/user/register', data)
}

export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'

export function login(email, password)
{
    const data = { email, password }
    return request(AUTH_LOGIN, 'post', '/auth', data)
}

export const AUTH_TOKEN_LOGIN = 'AUTH_TOKEN_LOGIN';
export const AUTH_TOKEN_LOGIN_SUCCESS = 'AUTH_TOKEN_LOGIN_SUCCESS';
export const AUTH_TOKEN_LOGIN_FAIL = 'AUTH_TOKEN_LOGIN_FAIL';
export const AUTH_TOKEN_DISABLE = 'AUTH_TOKEN_DISABLE';

export const AUTH_AUTHORIZE = 'AUTH_AUTHORIZE';
export const AUTH_AUTHORIZE_SUCCESS = 'AUTH_AUTHORIZE_SUCCESS'

export function authorize() {
    return request(AUTH_AUTHORIZE, 'get', '/oauth/authorize');
}

export function loginFromCookie()
{
    const token = cookies.get('hub_token');

    if (token) {
        return {
            type: AUTH_TOKEN_LOGIN,
            payload: {
                request: {
                    url: '/auth/user',
                    method: 'get',
                    headers: {
                        "X-AUTH-TOKEN": token
                    }
                }
            },
            token
        }
    }

    return {
        type: AUTH_TOKEN_DISABLE
    }
}

//

export const AUTH_CHECK = 'AUTH_CHECK'
export const AUTH_CHECK_FAIL = 'AUTH_CHECK_FAIL'

export function check()
{
    return request(AUTH_CHECK, 'get', '/auth')
}

//

export const AUTH_PASSWORD_RESET = "AUTH_PASSWORD_RESET"
export const AUTH_PASSWORD_RESET_SUCCESS = "AUTH_PASSWORD_RESET_SUCCESS"
export const AUTH_PASSWORD_RESET_FAIL = "AUTH_PASSWORD_RESET_FAIL"

export function resetPassword(email)
{
    return request(AUTH_PASSWORD_RESET, 'post', 'auth/user/reset-password', { email })
}

export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'

export function logout()
{
    return request(AUTH_LOGOUT, 'delete', '/auth')
}

//

const defaultState = {
    token: null,
    user: null
}

//

export default function authReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case AUTH_LOGOUT_SUCCESS:
        case AUTH_TOKEN_LOGIN_FAIL:
            cookies.remove('hub_token');
        case AUTH_TOKEN_DISABLE:
            return {...state, token: false, user: null};
        case AUTH_TOKEN_LOGIN_SUCCESS:
            return {...state, token: action.meta.previousAction.token, user: action.payload.data.user};
        case AUTH_LOGIN_SUCCESS:
            cookies.set('hub_token', action.payload.data.token);
            return {...state, token: action.payload.data.token, user: action.payload.data.user}
        default:
            //console.log("Unknown action in auth.js: " + action.type);
    }
    return state
}

