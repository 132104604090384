import React, {Component} from 'react';
import {connect} from 'react-redux';
import ElementTemplate from "../../pages/bag/ElementTemplate";
import Loader from "../Loader";
import {getContextPath, getDataRecursively} from "../../helpers";
import EditableProperty from "./EditableProperty";
import {updateElement, deleteElement, startLinking} from "../../data/bag_editor";

class Sidebar extends Component {
    state = {
        linkType: null
    }

    render()
    {
        if (this.props.editor.linking !== null) return null
        return (
            <aside className="sidebar">
                {this.renderElements()}
                {this.renderProperties()}
                {this.renderLinks()}
            </aside>
        )
    }

    renderElements()
    {
        let elements = []

        if (!this.props.editor.project.tech) {
            if (this.props.board == 'model') {
                elements.push('Block')
                elements.push('View')
            }
            else {
                elements.push('Table')
                elements.push('Attribute')
            }
        }
        else {
            let specs = this.props.editor.specifications

            if (!specs) {
                return (
                    <p className="text-center">
                        <Loader display />
                    </p>
                )
            }

            let path = getContextPath(this.props.context, this.props.board, this.props.data)
            let level = path.length - 1

            Object.keys(specs).map((type) => {
                if (!specs[type].contexts) return;

                specs[type].contexts.map((context) => {
                    if (context.board && context.board != this.props.board) return;
                    if (typeof context.level !== 'undefined' && context.level != level) return;
                    if (context.parent) {
                        if (level < 1) return;
                        if (path[level].type != context.parent) return;
                    }

                    elements.push(type)
                })
            })
        }

        if (elements.length === 0) return null;

        return (
            <div className="section">
                <header><i className="fa fa-plus"/> Elements</header>
                <div className="element-templates">
                    {elements.map((element) => (
                        <ElementTemplate key={element} type={element} />
                    ))}
                </div>
            </div>
        )
    }

    renderProperties()
    {
        if (!this.props.selection) return null

        const data = getDataRecursively(this.props.selection, this.props.data)
        if (!data) return null

        const specs = this.props.editor.specifications
        if (!specs) return null

        const elementSpecs = specs[data.type]
        if (!elementSpecs) return null

        if (!elementSpecs.props) return null
        if (elementSpecs.props.length === 0) return null

        return (
            <div className="section">
                <header><i className="fa fa-cogs"/> Properties</header>
                {elementSpecs.props.map((prop) => (
                    <EditableProperty
                        data={prop}
                        context={data}
                        onChange={(data) => {
                            this.props.updateElement(this.props.board, data)
                        }}
                    />
                ))}
            </div>
        )
    }

    renderLinks()
    {
        if (!this.props.selection) return null

        const data = getDataRecursively(this.props.selection, this.props.data)
        if (!data) return null

        const specs = this.props.editor.specifications
        if (!specs) return null

        let linkable = []

        Object.keys(specs).map((specName) => {
            if (specs[specName].contexts) {
                specs[specName].contexts.map((context) => {
                    if (!context.board || context.board != 'links') return;
                    if (context.origin == data.type) {
                        linkable.push({ type: specName, ...context })
                    }
                })
            }
        })

        let links = this.props.editor.schema.links.filter((link) => {
            if (link.origin == data.id || link.target == data.id) {
                return true
            }
            return false
        }).map((data) => {
            let link = {...data}
            let originId = link.origin
            let targetId = link.target

            link.origin = getDataRecursively(originId, this.props.editor.schema.model)
            if (!link.origin) link.origin = getDataRecursively(originId, this.props.editor.schema.database)

            link.target = getDataRecursively(targetId, this.props.editor.schema.model)
            if (!link.target) link.target = getDataRecursively(targetId, this.props.editor.schema.database)

            return link
        })

        if (!linkable.length && !links.length) return null

        let selectedLinkType = this.state.linkType
        if (linkable.map((l) => l.type).indexOf(selectedLinkType) === -1) selectedLinkType = null

        return (
            <div className="section">
                <header><i className="fa fa-link"/> Links</header>
                <div>
                    {links.map((link) => {
                        return (
                            <table className="table table-relation">
                                <tbody>
                                <tr>
                                    <th className="pt-2">{link.type}</th>
                                    <td className="text-right">
                                        <button
                                            title="Remove relation"
                                            onClick={() => {
                                                this.props.deleteElement('links', link.id)
                                            }}
                                            className="btn btn-danger"><i className="fas fa-trash"/>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{link.origin.type}</th>
                                    <td className="text-right">
                                        <span>{link.origin.name}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{link.target.type}</th>
                                    <td className="text-right">
                                        <span>{link.target.name}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        )
                    })}
                </div>
                {linkable.length ? (
                    <div>
                        <select
                            value={selectedLinkType}
                            onChange={(e) => {
                                this.setState({ linkType: e.target.value })
                            }}
                            className="form-control">
                            <option value={null}>Pick a link type</option>
                            {linkable.map((link) => (
                                <option value={link.type}>{link.type} ({link.target})</option>
                            ))}
                        </select>
                        <button
                            disabled={selectedLinkType === null}
                            onClick={() => {
                                let link = linkable.find((l) => l.type === selectedLinkType)
                                link.originId = data.id
                                this.props.startLinking(link)
                            }}
                            className="btn btn-warning w-100">Create Link</button>
                    </div>
                ) : null}
            </div>
        )
    }
}

export default connect(({ bag_editor }) => ({ editor: bag_editor }), { updateElement, deleteElement, startLinking })(Sidebar)