import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";

class DownloadModal extends Component {
    state = {};

    render() {
        return (
            <Modal title="Downloading your files" onClose={this.props.onClose}>
                {this.renderForm()}
            </Modal>
        );
    }

    renderForm() {
        return (
            <div style={{textAlign: 'center'}}>
                <h5>{this.props.data.message || "Oops, something was meant to be displayed here.."}</h5>

                {this.props.data.loaded ?
                    <a href={"https://api.bluesquare.io" + (this.props.data.link || '#') + "?auth_token=" + (this.props.data.auth_token || "")} target="_blank" className="btn btn-primary"><i className="fa fa-download mr-1"></i> Click
                        to download</a>
                    : null}
            </div>
        )
    }
}

export default connect(null, {})(DownloadModal)
