import React, {Component} from 'react';
import {Link} from "react-router-dom";

const styles = {
    title: {
        padding: 0,
        margin: 0,
        color: '#000',
        fontSize: 20,
    },
    container: {
        borderRadius: 3,
        backgroundColor: '#f4f4f4',
        padding: 20,
        userSelect: 'none',
        marginBottom: 10
    },
    link: {
        cursor: 'pointer',
    },
    contentOffset: {
        paddingTop: 10
    }
}

export default class Item extends Component {

    render() {
        if (this.props.to) {
            return (
                <Link className={"item-link"} to={this.props.to}>
                    <div style={styles.link}>
                        {this.renderItem()}
                    </div>
                </Link>
            )
        }

        return this.renderItem()
    }

    renderItem() {
        return (
            <div className={"item"} style={styles.container}>
                {this.renderTitle()}
                {this.renderContent()}
            </div>
        )
    }

    renderTitle() {
        if (!this.props.title) return null

        return (
            <h4 style={styles.title}>{this.props.title}</h4>
        )
    }

    renderContent() {
        if (!this.props.children) return null

        return (
            <div style={this.props.title ? styles.contentOffset : null}>
                {this.props.children}
            </div>
        )
    }
}

Item.defaultProps = {
    title: null,
    to: null
}