import React, {Component} from 'react';
import Modal from '../Modal';
import FormProvider from "../FormProvider";
import Input from "../Input";
import Textarea from "../Textarea";
import {connect} from 'react-redux';
import {createMissionCalendar} from "../../data/missionCalendars";

class MissionCalendarCreateModal extends Component
{
    state = {
        title: null,
        description: null,
        creating: false,
        errors: null
    };

    create = (e) => {

        e.preventDefault();

        let { title, description } = this.state;
        this.setState({ creating: true });

        this.props.createMissionCalendar(title, description, this.props.mission_id)
            .then((data) => {
                this.setState({ creating: false });
                this.props.onClose(true);
            })
            .catch((res) => {
                this.setState({ creating: false });
                if (res.error) this.setState({ errors: res.error.response.data.errors })
            });

        return false;
    };

    render() {
        return(
            <Modal title="Create a calendar" onClose={this.props.onClose}>
                {this.renderForm()}
            </Modal>
        )
    }

    renderForm = () => {
        return (
            <FormProvider onSubmit={this.create} errors={this.state.errors}>
                <div className="row">
                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"title"}
                               id={"title"}
                               placeholder={"Calendar title *"}
                               value={this.state.title}
                               onChange={(title) => this.setState({title})}
                               label={"Calendar title *"}/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Textarea
                            name={"description"}
                            id={"description"}
                            onChange={(description) => this.setState({description})}
                            label={"Description"}
                            value={this.state.description}
                        />
                    </div>
                </div>
                <button className="btn btn-primary btn-block" disabled={this.state.creating}>Create</button>
            </FormProvider>
        )
    };
}

export default connect(null, {createMissionCalendar})(MissionCalendarCreateModal);
