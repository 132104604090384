import React, {Component} from 'react'

/**
 * Props:
 *  wrapperClass: parent div classes. Default: ""
 *  wrapperStyle: parent div style attribute value. Default: {}
 *  iconClass: icon classes. Default: "fa fa-copy"
 *  hideText: hide the text to display next to the icon. Default: false
 *  textToCopy: The text to copy in the clipboard. Default: "No text to copy"
 *  copyLabel: Text to show after the icon. Default: "Copy"
 */
export default class CopyTextButton extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        style: {display: 'none'},
        textChanged: false
    };

    render() {
        return (
            <div className={this.props.wrapperClass} style={this.props.wrapperStyle} onClick={this.onIconClick}
                 title={"Will copy:\n" + this.props.textToCopy}>
                <textarea ref={this.myRef} style={this.state.style} defaultValue={this.props.textToCopy}></textarea>
                <span>{this.props.hideText ? '' : (this.state.textChanged ? "Copied!" : this.props.copyLabel)}</span>
                <i style={{ marginLeft: 5 }} className={this.props.iconClass}/>
            </div>
        )
    }

    onIconClick = () => {
        this.setState({style: {}}, () => {
            this.myRef.current.select();
            document.execCommand('copy');
            this.setState({style: {display: 'none'}, textChanged: true});
            setTimeout(() => {
                this.setState({textChanged: false});
            }, 2000);
        });
    }
}

CopyTextButton.defaultProps = {
    wrapperClass: "",
    wrapperStyle: {},
    textToCopy: "No text to copy",
    iconClass: "fa fa-copy",
    hideText: false,
    copyLabel: "Copy"
};
