import {request} from '../helpers'

// Get all customers

//import {SERVERS_FETCH_SERVER_SUCCESS} from "./servers";

export const CUSTOMERS_GET = 'CUSTOMERS_GET';
export const CUSTOMERS_GET_SUCCESS = 'CUSTOMERS_GET_SUCCESS';
export const CUSTOMERS_GET_FAIL = 'CUSTOMERS_GET_FAIL';


export function getCustomers()
{
    return request(CUSTOMERS_GET, 'get', '/customers')
}

// Get one customer

export const CUSTOMER_GET = "CUSTOMER_GET";
export const CUSTOMER_GET_FAIL= "CUSTOMER_GET_FAIL";
export const CUSTOMER_GET_SUCCESS= "CUSTOMER_GET_SUCCESS";


export function getCustomer(id)
{
    return request(CUSTOMER_GET, 'get', '/customers/' + id, {}, { id })
}

// Get customers for Timesquare

export const CUSTOMERS_GET_TIMESQUARE = "CUSTOMERS_GET_TIMESQUARE";
export const CUSTOMERS_GET_TIMESQUARE_FAIL= "CUSTOMERS_GET_TIMESQUARE_FAIL";
export const CUSTOMERS_GET_TIMESQUARE_SUCCESS= "CUSTOMERS_GET_TIMESQUARE_SUCCESS";

export  function getCustomersTimesquare(date)
{
    const data = { date };
    return request(CUSTOMERS_GET_TIMESQUARE, 'post', '/timesquare/all-clients', data)
}

// Create a customer

export const CUSTOMERS_CREATE = "CUSTOMERS_CREATE";
export const CUSTOMERS_CREATE_SUCCESS = "CUSTOMERS_CREATE_SUCCESS";
export const CUSTOMERS_CREATE_FAIL = "CUSTOMERS_CREATE_FAIL";


export function createCustomer(data)
{
    return request(CUSTOMERS_CREATE, 'put', '/customers', data)
}

export const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";

export function updateCustomer(id, company_name, company_phone, address, siret,status, color, country = 'France', company_shape = '', social_capital = '', social_capital_currency = '', rcs_number = '', rcs_country = '')
{
    const data = { id, company_name, company_phone, address, siret,status, color, country, company_shape, social_capital, social_capital_currency, rcs_number, rcs_country };
    return request(CUSTOMER_UPDATE, 'post', '/customers', data);
}

// Delete a customer

export const CUSTOMERS_DELETE = "CUSTOMERS_DELETE";
export const CUSTOMERS_DELETE_SUCCESS = "CUSTOMERS_DELETE_SUCCESS";
export const CUSTOMERS_DELETE_FAIL = "CUSTOMERS_DELETE_FAIL";


export function deleteCustomers(id)
{
    return request(CUSTOMERS_DELETE, 'delete', '/customers', { id }, { id })
}

export const CUSTOMER_CREATE_CONTACT = "CUSTOMER_CREATE_CONTACT";
export function createContact(data) {
    return request(CUSTOMER_CREATE_CONTACT, 'post', '/customers/contact', data);
}

export const CUSTOMER_UNLINK_CONTACT = "CUSTOMER_UNLINK_CONTACT"
export function unlinkContact(customer_id, user_id) {
    const data = { customer_id, user_id }
    return request(CUSTOMER_UNLINK_CONTACT, 'post', '/customers/contact/unlink', data)
}

// Store
const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function customersReducer(state = defaultState, action)
{
    let index;

    switch(action.type)
    {
        case CUSTOMERS_GET:
        case CUSTOMER_UPDATE:
        case CUSTOMER_GET:
        case CUSTOMERS_CREATE:
        case CUSTOMERS_DELETE:
            return { ...state, loading: true};

        case CUSTOMERS_GET_FAIL:
        case CUSTOMER_GET_FAIL:
        case CUSTOMERS_DELETE_FAIL:
        case CUSTOMERS_CREATE_FAIL:
        case CUSTOMERS_GET_TIMESQUARE_FAIL:
            return { ...state, loading: false};

        // Get all customers
        case CUSTOMERS_GET_SUCCESS:
        case CUSTOMERS_GET_TIMESQUARE_SUCCESS:
            return { ...state, index: action.payload.data, loading: false, lastUpdate: new Date() };

        // Get specific customer
        case CUSTOMER_GET_SUCCESS:
            index = [...state.index];
            if (index.find((customer) => customer.id == action.meta.previousAction.id)) {
                index.map((customer) => {
                    if (customer.id == action.meta.previousAction.id) {
                        customer = action.payload.data;
                        customer.fullInformations = true
                    }
                    return customer
                })
            }
            return {
                ...state,
                index,
                loading: false
            };

        // Create a customer
        case CUSTOMERS_CREATE_SUCCESS:
            index = state.index;
            index.push(action.payload.data.customer);
            return { ...state, index, loading: false};

        case CUSTOMERS_DELETE_SUCCESS:
            index = state.index.filter((customer) => customer.id !== action.meta.previousAction.id);
            return { ...state, index, loading: false };
        case CUSTOMER_CREATE_CONTACT:
            console.log(action);
            // return {...state, }
    }
    return state
}







