// export const API_HOST = 'localhost'
// export const API_URL = `http://${API_HOST}/bluesquare/api/public`;

// export const WS_SSL = true;
//
export const API_HOST = 'api.bluesquare.io';
export const API_URL = `https://${API_HOST}`;

// export const API_HOST = "http://localhost:9090";
// export const API_URL = API_HOST;
