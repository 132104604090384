import {request} from '../helpers'

const APP_GET_METRICS = 'APP_GET_METRICS';

export function getMetrics() {
    return request(APP_GET_METRICS, 'get', '/app/metrics')
}

//

const defaultState = {}

//

export default function appReducer(state = defaultState, action)
{
    return state
}