import React, {Component} from 'react';
import Modal from "../Modal";
import Input from "../Input";
import FormProvider from "../FormProvider";
import Textarea from "../Textarea";
import {connect} from "react-redux";
import {updateNote} from "../../data/notes";
import ToolsPasswordGenerator from "../ToolsPasswordGenerator";



class NoteUpdateModal extends Component
{
    state = {
        id: this.props.data.id,
        type: this.props.data.type,
        name: this.props.data.name,
        username: this.props.data.username,
        password: this.props.data.password,
        link: this.props.data.link,
        sticky: this.props.data.sticky,
        errors: null,
        updating: false,
        showPasswordGeneratorModal: false
    };

    update = (e) => {
        let {id, name, username, password, link, sticky} = this.state;

        e.preventDefault();
        this.setState({ updating: true });

        this.props.updateNote(id, name, username, password, link, sticky)
            .then((data) => {
                this.props.onClose(true);
            })
            .catch((res) => {
                if (res.error) this.setState({ errors: res.error.response.data.errors });
                this.setState({ updating: false });
            });

        return false;
    };

    render = () => {
        return (
            <Modal title="Update a note" onClose={() => this.props.onClose(false)}>
                {this.renderForm()}
            </Modal>
        )
    };

    renderForm = () => {
        return (
            <FormProvider onSubmit={this.update} errors={this.state.errors}>
                <div className="row">
                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"name"}
                               id={"name"}
                               placeholder={"Note name"}
                               value={this.state.name}
                               onChange={(name) => this.setState({name})}
                               label={"Note name"}/>
                    </div>
                </div>
                {this.state.type === 'credentials' ? this.renderCredentials() : null}
                {this.state.type === 'link' ? this.renderLink() : null}
                {this.state.type === 'sticky' ? this.renderSticky() : null}
                <button className="btn btn-primary btn-block" disabled={this.state.updating}>Update</button>
            </FormProvider>
        )
    };

    renderCredentials = () => {
        return (
            <div className="container px-0">
                <div className="row">
                    <div className="col">
                        <Input required
                               name={"username"}
                               id={"username"}
                               placeholder={"Username"}
                               value={this.state.username}
                               onChange={(username) => this.setState({username})}
                               label={"Username"}
                        />
                    </div>
                    <div className="col">
                        <Input required
                               name={"password"}
                               id={"password"}
                               placeholder={"Password"}
                               value={this.state.password}
                               onChange={(password) => this.setState({password})}
                               label={"Password"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <button className="btn btn-primary btn-block" onClick={this._openPasswordModal}>Generate Password</button>
                        {this.state.showPasswordGeneratorModal ? <ToolsPasswordGenerator onClose={this._closePasswordModal}/> : null}
                    </div>
                </div>
            </div>
        )
    };

    renderLink = () => {
        return (
            <div className="row">
                <div className="col">
                    <Input required
                           name={"link"}
                           id={"link"}
                           placeholder={"Link"}
                           value={this.state.link}
                           onChange={(link) => this.setState({link})}
                           label={"Link"}
                    />
                </div>
            </div>
        )
    };

    renderSticky = () => {
        return (
            <div className="row">
                <div className="col">
                    <Textarea required
                              name={"sticky"}
                              id={"sticky"}
                              onChange={(sticky) => this.setState({sticky})}
                              label={"Sticky"}
                              value={this.state.sticky}
                    />
                </div>
            </div>
        )
    };

    _openPasswordModal = (event) => {
        event.preventDefault();
        this.setState({ showPasswordGeneratorModal: true });
        return (false);
    };
    _closePasswordModal = (generatedPassword) => {
        generatedPassword = (typeof generatedPassword === 'string') ? generatedPassword : this.state.password;
        this.setState({
            showPasswordGeneratorModal: false,
            password: generatedPassword
        });
    }
}

export default connect(null, { updateNote }) (NoteUpdateModal)
