import React, {Component} from 'react';
import Modal from '../Modal'
import {deleteCustomers} from '../../data/customers'
import {connect} from 'react-redux'

class CustomerDeleteModal extends Component
{
    state = {
        deleting: false
    };

    deleteCustomer = () => {
        this.setState({ deleting: true });
        this.props.deleteCustomers(this.props.id)
            .then(() => {
                this.props.onClose(true)
            })
            .catch(() => {
                this.setState({ deleting: false })
            })
    };

    render = () => {
        return (
            <Modal title="Delete">
                <p>Are you sure you want to delete this customer?</p>
                <button className="btn btn-danger float-right" disabled={this.state.deleting} onClick={() => this.deleteCustomer()}>Yes I'm sure</button>
                <button className="btn btn-primary" disabled={this.state.deleting} onClick={() => this.props.onClose(false)}>Please don't, I'm full of regrets</button>
            </Modal>
        )
    }
}

CustomerDeleteModal.defaultProps = {
    id: null
};

export default connect(null, {deleteCustomers})(CustomerDeleteModal);
