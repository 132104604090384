import React, {Component} from 'react'
import {FormConsumer} from "./FormProvider";

export default class Input extends Component
{

    render()
    {
        return (
            <FormConsumer>
                {({ errors }) => this.renderInput(errors)}
            </FormConsumer>
        )
    }

    renderInput(errors)
    {
        const error = errors && errors[this.props.name] ? errors[this.props.name] : null
        return (
            <div className="form-group">
                <div className="form-label-group">
                    <input
                           ref={this.props.childRef}
                           type={this.props.type}
                           id={this.props.id ? this.props.id : 'input_'+this.props.name}
                           className={"form-control" + (error ? ' is-invalid' : '')}
                           placeholder={this.props.placeholder ? this.props.placeholder : this.props.label}
                           value={this.props.value}
                           onChange={(e) => this.props.onChange(e.target.value)}
                           required={this.props.required}
                           autoFocus={this.props.autoFocus}
                           pattern={this.props.pattern}
                    />
                    {this.props.label ? <label htmlFor={this.props.id ? this.props.id : 'input_'+this.props.name}>{this.props.label}</label> : null }
                    {error ? <div className="invalid-feedback">{error.key}</div> : null}
                </div>
            </div>
        )
    }
}

Input.defaultProps = {
    name: 'input',
    id: null,
    type: 'text',
    placeholder: null,
    value: '',
    onChange: () => {},
    autoFocus: false,
    required: false,
    label: null,
    pattern: null,
    childRef: null
}
