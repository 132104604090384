import FormProvider from "../FormProvider";
import Input from "../Input";
import Select from "../Select";
import Textarea from "../Textarea";
import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";
import {createNote} from "../../data/notes";
import ToolsPasswordGenerator from "../ToolsPasswordGenerator";

class NoteAddModal extends Component
{
    state = {
        name: '',
        type: 'credentials',
        username: '',
        password: '',
        link: '',
        sticky: '',
        errors: null,
        creating: false,
        showPasswordGeneratorModal: false
    };

    create = (e) => {
        let {name, type, username, password, link, sticky} = this.state;
        let customerId = this.props.customerId;
        let userId = this.props.userId;

        e.preventDefault();
        this.setState({ creating: true });

        this.props.createNote(name, type, username, password, link, sticky, customerId, userId)
            .then((data) => {
                this.props.onClose(true);
            })
            .catch((res) => {
                if (res.error) this.setState({ errors: res.error.response.data.errors })
            })
            .finally((res) => {
                this.setState({ creating: false })
            });

        return false;
    };

    render()
    {
        return (
            <Modal title="Add a note" onClose={() => this.props.onClose(false)}>
                {this.renderForm()}
            </Modal>
        )
    }

    renderForm()
    {
        return (
            <FormProvider onSubmit={this.create} errors={this.state.errors}>
                <div className="row">
                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"name"}
                               id={"name"}
                               placeholder={"Note name"}
                               value={this.state.name}
                               onChange={(name) => this.setState({name})}
                               label={"Note name"}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Select required
                                name={"type"}
                                id={"type"}
                                label={"Please choose a type"}
                                value={this.state.type}
                                options={[
                                    {label: 'Credentials', value: 'credentials'},
                                    {label: 'Link', value: 'link'},
                                    {label: 'Sticky', value: 'sticky'}
                                ]}
                                onChange={(type) => this.setState({type})}
                        />
                    </div>
                </div>
                {this.state.type === 'credentials' ? this.renderCredentials() : null}
                {this.state.type === 'link' ? this.renderLink() : null}
                {this.state.type === 'sticky' ? this.renderSticky() : null}
                <button className="btn btn-primary btn-block" disabled={this.state.creating}>Add</button>
            </FormProvider>
        )
    }

    renderCredentials = () => {
        return (
            <div className="row">
                <div className="col">
                    <Input required
                           name={"username"}
                           id={"username"}
                           placeholder={"Username"}
                           value={this.state.username}
                           onChange={(username) => this.setState({username})}
                           label={"Username"}
                    />
                </div>
                <div className="col">
                    <Input required
                           name={"password"}
                           id={"password"}
                           placeholder={"Password"}
                           value={this.state.password}
                           onChange={(password) => this.setState({password})}
                           label={"Password"}
                    />
                </div>
                <div className="col">
                    <button className="btn btn-primary btn-block" onClick={this._openPasswordModal}>Generate Password</button>
                    {this.state.showPasswordGeneratorModal ? <ToolsPasswordGenerator onClose={this._closePasswordModal}/> : null}
                </div>
            </div>
        )
    };

    renderLink = () => {
        return (
            <div className="row">
                <div className="col">
                    <Input required
                           name={"link"}
                           id={"link"}
                           placeholder={"Link"}
                           value={this.state.link}
                           onChange={(link) => this.setState({link})}
                           label={"Link"}
                    />
                </div>
            </div>
        )
    };

    renderSticky = () => {
        return (
            <div className="row">
                <div className="col">
                    {/*To change into textarea !*/}
                    <Textarea required
                           name={"sticky"}
                           id={"sticky"}
                           onChange={(sticky) => this.setState({sticky})}
                           label={"Sticky"}
                           value={this.state.sticky}
                    />
                </div>
            </div>
        )
    };

    _openPasswordModal = (event) => {
        event.preventDefault();
        this.setState({ showPasswordGeneratorModal: true });
        return (false);
    };
    _closePasswordModal = (generatedPassword) => {
        generatedPassword = (typeof generatedPassword === 'string') ? generatedPassword : this.state.password;
        this.setState({
            showPasswordGeneratorModal: false,
            password: generatedPassword
        });
    }
}

NoteAddModal.defaultProps = {
    onClose: () => {},
    customerId: null,
    userId: null
};

export default connect(null, { createNote })(NoteAddModal)
