import React, {Component} from 'react';
import {connect} from 'react-redux';
import {initProject, stopLinking, fetchSpecs, createLink, generateCode} from "../../data/bag_editor";
import ContentLoader from "../../components/ContentLoader";

import './EditorPage.scss'
import {Link} from "react-router-dom";
import Board from "../../components/bag/Board";

import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import NextStepModal from "../../components/bag/NextStepModal";
import {getDataRecursively} from "../../helpers";
import {API_URL} from "../../config";
import {saveProject} from "../../data/bag";

class EditorPage extends Component
{
    state = {
        id: null,
        saving: false,
        generating: false,
        board: 'database',
        modelSelection: null,
        databaseSelection: null,

        nextStepModal: false
    }

    componentDidMount()
    {
        this._initEditor(this.props)
    }

    componentWillReceiveProps(props)
    {
        this._initEditor(props)
    }

    getData = () => (this.props.editor)

    //

    _initEditor = (props) => {
        const id = props.match.params.id

        if (this.state.id != id) {
            this.setState({ id }, () => {
                this.props.initProject(id)
            })
        }

        if (props.editor.project && props.editor.project.tech && props.editor.specifications === null) {
            this.props.fetchSpecs(props.editor.project.tech)
        }
    };

    _switchTo = (board) => this.setState({ board })

    //

    render()
    {
        if (!this.getData().project || this.getData().project.id !== this.state.id) {
            return <ContentLoader/>
        }

        return this.renderEditor()
    }

    renderEditor()
    {
        return (
            <div>
                <div className="BAG-EditorPage">
                    {this.renderHeader()}
                    {this.renderBoards()}
                    {this.state.nextStepModal ? <NextStepModal id={this.state.id} onClose={this._nextStepClose} /> : null}
                </div>
            </div>
        )
    }

    //

    renderBoards()
    {
        return [
            <Board
                key="model"
                board="model"
                active={this.state.board === "model"}
                switchTo={this._switchTo}
                onSelectionChange={(modelSelection) => this.setState({ modelSelection })}
            />,
            <Board
                key="database"
                board="database"
                active={this.state.board === "database"}
                switchTo={this._switchTo}
                onSelectionChange={(databaseSelection) => this.setState({ databaseSelection })}
            />
        ]
    }

    renderHeader()
    {
        return (
            <header className="header">
                <h1>
                    <Link to={"/bag"}>{this.getData().project.name}</Link>
                    &nbsp;
                    <span className={this.getData().project.tech ? "badge badge-primary" : "badge badge-secondary"}>{this.getData().project.tech ? this.getData().project.tech : 'concept'}</span>
                </h1>
                {this.renderActions()}
                {this.renderSwitch()}
            </header>
        )
    }

    renderActions() {
        if (this.props.editor.linking === null) {
            return (
                <menu className="actions btn-group">
                    <button style={{width: 100}}
                            onClick={() => this.props.saveProject(this.state.id, {...this.props.editor.project, ...this.props.editor.schema}, false)}
                            disabled={this.state.saving}
                            className="btn btn-secondary">{this.state.saving ? "Saving..." : "Save"}</button>
                    <button title="Export / download"
                            onClick={() => this.props.saveProject(this.state.id, {...this.props.editor.project, ...this.props.editor.schema})}
                            disabled={this.state.saving}
                            className="btn btn-secondary"><i className="fa fa-save"></i></button>
                    <button style={{width: 125}}
                            disabled={this.state.saving || this.state.generating}
                            onClick={() => this._nextStep()}
                            className="btn btn-primary">{this.getData().project.tech ? (this.state.generating ? 'Generating...' : 'Generate') : 'Next step'}</button>
                </menu>
            )
        }

        let selection = null
        let element = null
        let linkable = false

        if (this.state.board == 'model') {
            selection = this.state.modelSelection
            if (selection !== null) element = getDataRecursively(selection, this.getData().schema.model)
        }

        if (this.state.board == 'database') {
            selection = this.state.databaseSelection
            if (selection !== null) element = getDataRecursively(selection, this.getData().schema.database)
        }

        const link = this.props.editor.linking

        if (element) {
            if (link.target == element.type) linkable = true
        }

        return (
            <menu className="actions btn-group" key={"linking-tools"}>
                <button onClick={() => {
                    this.props.createLink(element)
                }} disabled={!linkable} className="btn btn-primary">Link element</button>
                <button className="btn btn-secondary" onClick={() => {
                    this.props.stopLinking()
                }}>Cancel</button>
            </menu>
        )
    }

    renderSwitch()
    {
        return (
            <menu className="switch">
                <button onClick={() => this._switchTo('database')}
                        className={this.state.board === 'database' ? 'active' : ''}>Database</button>
                <button onClick={() => this._switchTo('model')}
                        className={this.state.board === 'model' ? 'active' : ''}>Model</button>
            </menu>
        )
    }

    async _nextStep () {
        //
        const project = this.props.editor.project;
        const schema = this.props.editor.schema;

        if (project.tech) {
            this.setState({ generating: true });

            // Generate code
            const data = {
                project: {
                    ...project,
                    model: schema.model,
                    database: schema.database,
                    links: schema.links
                }
            };

            this.props.generateCode(project.tech, data)
                .then((res) => {
                    this.setState({ generating: false });
                    window.open(API_URL + '/bag/proxy?tool=transcriber&method=download&id=' + encodeURIComponent(res.payload.data.id) + '&auth_token=' + encodeURIComponent(this.props.auth.token))
                })
                .catch(() => {
                    this.setState({ generating: false })
                })
        }
        else {
            this.setState({ nextStepModal: true })
        }
    }

    _nextStepClose = () => {
        this.setState({ nextStepModal: false });
    }
}

export default connect(({ bag, bag_editor, auth }) => ({ bag, editor: bag_editor, auth }), { saveProject, initProject, fetchSpecs, stopLinking, createLink, generateCode })(EditorPage)
