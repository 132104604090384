import React, {Component} from 'react';
import {connect} from 'react-redux';
import {initProject, translateSchema, fetchTechs} from "../../data/bag_editor";
import Modal from "../Modal";
import {saveProject} from "../../data/bag";

class NextStepModal extends Component
{
    state = {
        techs: [],
        tech: "",
        loading: true
    }

    componentDidMount() {
        this.props.fetchTechs()
            .then((res) => {
                this.setState({ loading: false, techs: res.payload.data.techs })
            })
    }

    async _execute()
    {
        if (this.state.tech === "") return;

        this.setState({ loading: true });

        const project = this.props.editor.project;
        const schema = this.props.editor.schema;

        // Next step
        const data = {
            schema: {
                ...project,
                ...schema
            }
        };

        this.props.translateSchema(this.state.tech, data)
            .then((res) => {
                let proj = res.payload.data.project;
                this.setState({ loading: false });
                this.props.initProject(this.props.id, proj);

                proj.id = this.props.id.split('-')[0] + '-' + proj.tech;
                this.props.saveProject(proj.id, proj, false);

                this.props.onClose();
            })
            .catch(() => {
                this.setState({ loading: false })
            })
    }

    render() {
        return (
            <Modal onClose={this.props.onClose} title="Next step">
                <p>
                    <select
                        disabled={this.state.loading}
                        value={this.state.tech}
                        onChange={(e) => this.setState({ tech: e.target.value })}
                        className="form-control">
                        <option value={""} key={"nothing"}>Pick a tech!</option>
                        {this.state.techs.map((tech) => (
                            <option value={tech} key={tech}>{tech}</option>
                        ))}
                    </select>
                </p>
                <button className="btn btn-primary" disabled={this.state.tech == "" || this.state.loading} onClick={() => this._execute()}>Next step</button>
            </Modal>
        )
    }
}

export default connect(({bag_editor}) => ({editor: bag_editor}), {initProject, translateSchema, fetchTechs, saveProject})(NextStepModal)
