import React, {Component} from 'react';

export default class ElementTemplate extends Component
{
    _onDragStart = (e) => {
        e.dataTransfer.setData('text/plain', JSON.stringify({
            type: this.props.type,
            nature: 'ElementTemplate'
        }))
    }

    render() {
        return (
            <div className="element-template"
                 draggable="true"
                 onDragStart={this._onDragStart}>{this.props.type}</div>
        )
    }
}