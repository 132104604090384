import React, {Component} from 'react';
import {connect} from 'react-redux';
import PrivilegesModal from './PrivilegesModal';
import {ROLE_CHIEF} from "../../config/roles";

class PrivilegesButton extends Component {

    state = {
        modal: false
    }

    _toggleModal = () => this.setState({ modal: !this.state.modal })

    render() {
        let { auth } = this.props
        if (!auth.user || !auth.user.roles || auth.user.roles.indexOf(ROLE_CHIEF) === -1) return null

        return (
            <span>
                <div className="d-inline-block align-middle ml-2"
                      onClick={this._toggleModal}
                      style={{
                          cursor: 'pointer', padding: 5, fontSize: 12, color: '#555',
                          borderWidth: 1, borderColor: '#CCC', borderStyle: 'solid',
                          backgroundColor: '#eee', borderRadius: 3,
                      }}>
                    <i className="fas fa-lock"/>
                </div>
                {this.state.modal ? <PrivilegesModal onClose={() => this._toggleModal()} {...this.props} /> : null}
            </span>
        )
    }
}

export default connect(({ auth }) => ({ auth }), null)(PrivilegesButton)