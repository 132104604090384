import FormProvider from "../FormProvider";
import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";
import Textarea from "../Textarea";
import FileImport from '../FileImport';
import {createInvoice} from "../../data/invoices";
import { getCustomers } from "../../data/customers";
import SelectSearch from 'react-select';

class AddInvoiceModal extends Component
{
    state = {
        mission: '',
        description: '',
        amount_ttc: '',
        file: null,
    };

    componentDidMount() {
        this.props.getCustomers();
    }

    renderOptions(missions)
    {
        return missions.index.map((mission) => ({
            value : mission.id,
            label : mission.customer.name + " : " + mission.name,
            customer_id: mission.customer.id
        }))
    }

    create = (e) => {
        let { mission, amount_ttc, description, file } = this.state;

        e.preventDefault();

        this.setState({ creating: true });

        this.props.createInvoice(mission.value, amount_ttc, description,file)
            .then(() => {
                this.setState({ creating: false });
                this.props.onClose(true);
            })
            .catch((res) => {
                if (res.error) this.setState({ errors : res.error.response.data.errors });
                this.setState({ creating: false });
            });
    };

    render() {
        return (
            <Modal title="Invoice" onClose={this.props.onClose}>
                {this.renderForm()}
            </Modal>
        );
    }

    renderForm() {
        return (
            <FormProvider onSubmit={this.create}>
                <label>
                    Mission
                </label>

                <SelectSearch
                    name={"mission"}
                    value={this.state.mission}
                    options={this.renderOptions(this.props.missions)}
                    placeholder="Select a Mission"
                    searchable={false}
                    onChange={mission => {
                        const hasLegalRepresentative = this.props.customers.index.filter(c => c.id === mission.customer_id && c.contacts.filter(co => co.business_function === 'signatory_legal_representative').length > 0).length > 0

                        if (!hasLegalRepresentative)
                            window.alert("Il n'y a pas de représentant légal rattaché au client rattaché à cette mission")
                        else
                            this.setState({ mission })
                    }}
                />

                <Textarea
                    row={3}
                    type={"text"}
                    name={"description"}
                    id={"modal-description"}
                    placeholder={"Description"}
                    label={"Description"}
                    onChange={description => this.setState({ description })}
                    value={this.state.description}
                />

                <div className="row">
                    <div className="col">
                        <label htmlFor="modal-amount">Amount TTC *</label>
                        <input required
                               name="amount_ttc"
                               type="number"
                               placeholder="0"
                               className="form-control"
                               id="modal-amount-ttc"
                               onChange={(a) => {this.setState({amount_ttc: a.target.value})}}
                               value={this.state.amount_ttc}
                        />
                    </div>
                </div>

                <label htmlFor="modal-file" className="mt-3">Attached file</label>
                <FileImport id="modal-file" value={this.state.file} onChange={(file) => this.setState({ file })} />
                <button className="btn btn-primary btn-block" style={{"marginTop":"41px"}}>Add Invoice</button>
            </FormProvider>
        )
    }
}

export default connect(({ customers }) => ({ customers }), {createInvoice, getCustomers })(AddInvoiceModal)
