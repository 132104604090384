import FormProvider from "../FormProvider";
import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";
import {updateInvoice, getInvoiceAssociateEmails} from "../../data/invoices";
import Textarea from "../Textarea";
import FileImport from '../FileImport';

class EditInvoiceModal extends Component {

    state = {
        id: this.props.rowData.id,
        mission: this.props.rowData.mission,
        created_at: this.props.rowData.created_at,
        emitted_at: this.props.rowData.emitted_at,
        payed_at: this.props.rowData.payed_at,
        due_date: this.props.rowData.due_date,
        amount_ht: this.props.rowData.amount_ht,
        tax: this.props.rowData.tax,
        description: this.props.rowData.description,
        reference: this.props.rowData.reference,
        amount_ttc: this.props.rowData.amount_ttc,
        email: this.props.rowData.email,
        randomString :this.props.rowData.randomString,
        errors: null,
        updating: false,
        file: null,
        emails: {}
    };

    componentDidMount() {
        this.props.getInvoiceAssociateEmails(this.props.rowData.id)
            .then(({ payload }) => this.setState({ emails: payload.data }))
            .catch(e => console.error(e))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.id !== this.props.rowData.id)
            this.props.getInvoiceAssociateEmails(this.props.rowData.id)
                .then(({ payload }) => this.setState({ emails: payload.data }))
                .catch(e => console.error(e))
    }

    getLegalRepresentative = () => {
        if (!this.state.emails.legal_representative || this.state.emails.legal_representative.length === 0)
            return 'Aucuns'
        return Object.values(this.state.emails.legal_representative).join(', ')
    }

    getBillingContacts = () => {
        if (!this.state.emails.billing_contact || this.state.emails.billing_contact.length === 0)
            return 'Aucuns'

        return Object.values(this.state.emails.billing_contact).join(', ')
    }

    update = (e) => {
        let { id, created_at, emitted_at, due_date, payed_at, amount_ht, tax, description,reference, amount_ttc, randomString, file, email  } = this.state;

        e.preventDefault();
        this.setState({ updating: true });

        this.props.updateInvoice(id, created_at, emitted_at, due_date, payed_at, amount_ht, tax, description,reference, amount_ttc, randomString, file, email)
            .then((data) => {
                this.setState({ updating: false });
                this.props.onClose(true);
            })
            .catch((res) => {
                this.setState({ updating: false });
                if (res.error) this.setState({ errors: res.error.response.data.errors });
            });

        return false;
    };

    render() {
        return (
            <Modal title="Invoice" onClose={this.props.onClose}>
                {this.renderForm()}
            </Modal>
        );
    }

    renderForm() {
        return (
            <FormProvider onSubmit={this.update}>
                <Textarea
                    type={"text"}
                    name={"description"}
                    id={"modal-description"}
                    placeholder={"Description"}
                    value={this.state.description}
                    onChange={(t) => {this.setState({description: t})}}
                    label={"Description"}
                />
                <label>Créée le </label>
                <input required
                       name="created_at"
                       type="date"
                       className="form-control"
                       id="modal-created-at"
                       onChange={(d) => {this.setState({created_at: d.target.value})}}
                       value={this.state.created_at}
                />
                <label>Envoyée le </label>
                <input
                       name="emitted_at"
                       type="date"
                       className="form-control"
                       id="modal-emitted-at"
                       onChange={(d) => {this.setState({emitted_at: d.target.value})}}
                       value={this.state.emitted_at}
                />
                <label>Du pour le </label>
                <input
                    name="due_date"
                    type="date"
                    className="form-control"
                    id="modal-due-date"
                    onChange={(d) => {this.setState({due_date: d.target.value})}}
                    value={this.state.due_date}
                />
                <label>Payée le </label>
                <input
                       name="payed_at"
                       type="date"
                       className="form-control"
                       id="modal-payed-at"
                       onChange={(d) => {this.setState({payed_at: d.target.value})}}
                       value={this.state.payed_at}
                />
                <label>Référence </label>
                <input required
                       name="reference"
                       type="text"
                       className="form-control"
                       id="modal-reference"
                       onChange={(a) => {this.setState({reference: a.target.value})}}
                       value={this.state.reference}
                />
                <label>Emails associés :</label>
                <div className={'mb-2'}>
                    <div>
                        <span>Représentants légaux / signataires : </span><span>{ this.getLegalRepresentative() }</span>
                    </div>
                    <div>
                        <span>Contacts de facturation : </span><span>{ this.getBillingContacts() }</span>
                    </div>
                </div>
                <label> Prix HT </label>
                <input required
                       name="amount_ht"
                       type="text"
                       className="form-control"
                       id="modal-amount-ht"
                       onChange={(a) => {this.setState({amount_ht: a.target.value})}}
                       value={this.state.amount_ht}
                />
                <label> Tax </label>
                <input required
                       name="tax"
                       type="text"
                       className="form-control"
                       id="modal-tax"
                       onChange={(a) => {this.setState({tax: a.target.value})}}
                       value={this.state.tax}
                />
                <label> Prix ttc </label>
                <input required
                       name="amount_ttc"
                       type="text"
                       className="form-control"
                       id="modal-amount-ttc"
                       onChange={(a) => {this.setState({amount_ttc: a.target.value, tax: 0.2 * a.target.value, amount_ht: 0.8 * a.target.value})}}
                       value={this.state.amount_ttc}
                />
                <label htmlFor="modal-file" className="mt-3">Attached file</label>
                <FileImport id="modal-file" value={this.state.file} onChange={(file) => this.setState({ file })} />
                <button className="btn btn-primary btn-block" type={"submit"}>Update</button>
            </FormProvider>
        )
    }
}

export default connect(null, {updateInvoice, getInvoiceAssociateEmails})(EditInvoiceModal)
