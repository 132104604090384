import React, {Component} from 'react';
import ContentLoader from "../ContentLoader";
import {getMissionCalendarPhases} from '../../data/missionCalendarPhases';
import {connect} from "react-redux";
import Empty from "../Empty";
import MissionCalendarPhase from "./MissionCalendarPhase";
import MissionCalendarUpdateModal from "./MissionCalendarUpdateModal";
import MissionCalendarDeleteModal from "./MissionCalendarDeleteModal";
import MissionCalendarPhaseCreateModal from "../missionCalendarPhase/MissionCalendarPhaseCreateModal";

class MissionCalendar extends Component
{
    state = {
        id: this.props.id,
        calendar: this.props.mission_calendar,
        showDeleteModal: false,
        showUpdateModal: false,
        showCreatePhaseModal: false,
        phases: []
    };

    componentDidMount() {
        this.getMissionCalendarPhase();
    }

    getMissionCalendarPhase = () => {

        this.props.getMissionCalendarPhases(this.state.id)
            .then((res) => {
                this.setState({phases : res.payload.request.response})
            })
    };

    render() {

        if (!this.state.calendar)
            return <ContentLoader/>;

        const cardHeader = {
            background: "#B4B4BB"
        };

        const { calendar } = this.state;

        return (
            <div className="card">
                <div className={"card-header"} style={cardHeader}>
                    <div className={"row"}>
                        <div className={"col-md-10"}>
                            <h5 className={"card-title d-inline-block mt-2"}>{calendar.title}</h5>
                        </div>
                        <div className={"col-md-2 d-inline-block"}>
                            <div className={"float-right"}>
                                <button onClick={this.toggleDeleteModal} className="btn btn-danger mr-1">
                                    <i className="fa fa-trash"></i>
                                </button>
                                <button onClick={this.toggleUpdateModal} className="btn btn-secondary">
                                    <i className="fa fa-edit"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <p>{calendar.description}</p>
                    {this.renderPhases()}
                    <div className={"text-center"}>
                        <button
                            className={"btn btn-primary mt-3"}
                            onClick={this.togglePhaseCreateModal}>
                            Add a phase
                        </button>
                    </div>
                </div>
                {this.state.showUpdateModal ? <MissionCalendarUpdateModal data={calendar} onClose={this.toggleUpdateModal} onUpdate={(calendar) => this.setState({ calendar })}/> : null}
                {this.state.showDeleteModal ? <MissionCalendarDeleteModal id={calendar.id} onClose={(deleted) => this.toggleDeleteModal(deleted)}/> : null}
                {this.state.showCreatePhaseModal ? <MissionCalendarPhaseCreateModal calendar_id={calendar.id} onClose={(created) => this.togglePhaseCreateModal(created)}/> : null}
            </div>
        )
    }

    renderPhases = () => {

        if (!this.state.phases)
            return <Empty/>;

        const { phases } = this.state;

        return(
            <>
                {phases.map((phase) => <MissionCalendarPhase phase={phase} id={phase.id} onUpdate={() => this.props.onUpdate()} key={phase.id} onDelete={() => this.getMissionCalendarPhase()}/>)}
            </>
        )
    }

    movePositionUp = () => {
        // this.props.modulePosition(this.props.data.id, this.props.data.position - 1, this.props.missionId)
        //     .then(() => {
        //         this.props.onMoved()
        //     })
    };

    movePositionDown = () => {
        // this.props.modulePosition(this.props.data.id, this.props.data.position + 1, this.props.missionId)
        //     .then(() => {
        //         this.props.onMoved()
        //     })
    };

    toggleDeleteModal = (deleted) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal });
        if (deleted) this.props.onDelete();
    };

    toggleUpdateModal = () => this.setState({ showUpdateModal: !this.state.showUpdateModal });

    togglePhaseCreateModal = (created) => {
        this.setState({ showCreatePhaseModal: !this.state.showCreatePhaseModal });
        if (created) this.getMissionCalendarPhase()
    };
}

export default connect(null, {getMissionCalendarPhases})(MissionCalendar);

