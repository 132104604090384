import React, {Component} from 'react';
import Modal from "../Modal";
import FormProvider from "../FormProvider";
import Input from "../Input";
import moment from "moment";
import Checkbox from "../Checkbox";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {createMissionCalendarPhase} from "../../data/missionCalendarPhases";
import {connect} from 'react-redux';


class MissionCalendarPhaseCreateModal extends Component
{
    state = {
        name: "",
        description: "",
        content: "",
        starting_at: moment(),
        ending_at: moment(),
        no_ending_date: false,
        editor: null,
        creating: false
    };

    create = (e) => {
        e.preventDefault();

        let { name, description, starting_at, ending_at, no_ending_date } = this.state;
        let body = this.state.editor.getData();

        this.setState({ creating: true });

        this.props.createMissionCalendarPhase(name, description, body, starting_at, no_ending_date ? null : ending_at, this.props.calendar_id)
            .then((data) => {
                this.setState({ creating: false });
                this.props.onClose(true);
            })
            .catch((res) => {
                this.setState({ creating: false });
                if (res.error) this.setState({ errors: res.error.response.data.errors })
            });

        return false;
    };

    render() {
        return(
            <Modal title="Create a phase" onClose={this.props.onClose}>
                {this.renderForm()}
            </Modal>
        )
    }

    renderForm = () => {
        return (
            <FormProvider onSubmit={this.create} errors={this.state.errors}>
                <div className="row">
                    <div className="col">
                        <Input required autoFocus
                               type={"text"}
                               name={"name"}
                               id={"name"}
                               placeholder={"Phase name *"}
                               value={this.state.name}
                               onChange={(name) => this.setState({name})}
                               label={"Phase name *"}/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Input
                            type={"text"}
                            name={"description"}
                            id={"description"}
                            placeholder={"Description"}
                            onChange={(description) => this.setState({description})}
                            label={"Description"}
                            value={this.state.description}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="modal-starting-at">Start date</label>
                        <input
                            name="starting_at"
                            type="date"
                            className="form-control"
                            id="modal-starting-at"
                            onChange={(d) => {this.setState({starting_at: d.target.value})}}
                            value={this.state.starting_at}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="modal-ending-at">End date</label>
                        <input
                            name="ending_at"
                            type="date"
                            className="form-control"
                            id="modal-ending-at"
                            onChange={(d) => {this.setEndingDate(d.target.value)}}
                            value={this.state.ending_at}
                            disabled={this.state.no_ending_date}
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-2">
                    <div className="col text-right">
                        <Checkbox
                            value={this.state.no_ending_date}
                            onChange={(no_ending_date) => this.setState({no_ending_date}) }
                            label={"No ending date"}
                        />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col">
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.content}
                            onChange={(event, editor) => this.setState({content: editor.getData()})}
                            onInit={ editor => {
                                this.setState({editor});
                            }}
                        />
                    </div>
                </div>
                <button className="btn btn-primary btn-block" disabled={this.state.creating}>Create</button>
            </FormProvider>
        )
    };

    setEndingDate(ending_at) {

        const {starting_at} = this.state;

        if (starting_at && ending_at < starting_at)
            this.setState({ ending_at: starting_at});
        else
            this.setState({ ending_at });
    }
}

export default connect(null, {createMissionCalendarPhase})(MissionCalendarPhaseCreateModal);
