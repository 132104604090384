import React, {Component} from "react";
import Modal from "../Modal";
import Input from "../Input";
import FormProvider from "../FormProvider";
import {connect} from 'react-redux';
import {resetPassword} from "../../data/auth";


class PasswordResetModal extends Component
{
    state = {
        email: '',
        errors: null
    };

    resetPassword = (e) => {
        e.preventDefault();
        this.props.resetPassword(this.state.email)
            .then((data) => {
                this.props.onClose(true);
            })
            .catch((res) => {
                this.setState({errors: res.error.response.data.errors})
                console.log(res);
            });
    };

    render = () => {
        return (
            <div>
                <Modal title={"Reset your password"} onClose={() => this.props.onClose(false)}>
                    {this.renderForm()}
                </Modal>
            </div>
        )
    };

    renderForm = () => {
        return (
            <div>
                <FormProvider onSubmit={this.resetPassword} errors={this.state.errors}>
                    <Input required autoFocus
                           name={"email"}
                           label={"Email address"}
                           type={"text"}
                           value={this.state.email}
                           onChange={(email) => this.setState({ email })} />
                    <button className="btn btn-primary btn-block">Send me an email</button>
                </FormProvider>
            </div>
        )
    }
}



export default connect(null, {resetPassword})(PasswordResetModal);