import React, {Component} from 'react'
import MyTreeNode from "./MyTreeNode";
import '../../styles/components/directorypicker.scss'

export default class DirectoryPicker extends Component {
    render() {
        let data = this.props.data;
        let onToggle = this.props.onToggle;

        if (!Array.isArray(data)) {
            data = [data];
        }

        return (
            <ul style={{padding: 0}}>
                {data.map((node, index) =>
                    <MyTreeNode key={node.id || index}
                                node={node}
                                onToggle={onToggle}
                                forbidden={this.props.forbidden}
                    />
                )}
            </ul>
        );
    }
}
