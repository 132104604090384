import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from "react-router-dom";
import {logout} from "../data/auth";
import logo from '../images/bluesquare.png';
import '../styles/generalpurpose/hoverdisplayproperties.scss'

class BaseLayout extends Component
{
    render()
    {
        let classNames = ["BaseLayout", "page-top"]
        if (this.props.extended) classNames.push('extended')
        return (
            <div className={classNames.join(' ')}>
                <div id="wrapper">
                    {this.renderSidebar()}
                    <div id="content-wrapper" style={this.props.container === false ? {paddingTop: 0} : {}}>
                        {this.props.container === false ? this.props.children : (
                            <div className="container-fluid">
                                {this.props.children}
                            </div>
                        )}
                    </div>
                </div>

                <a className="scroll-to-top rounded" href="#page-top">
                    <i className="far fa-angle-up"/>
                </a>

            </div>
        )
    }

    renderSidebar()
    {
        const rights = this.props.auth.user.rights;

        return (
            <ul className="sidebar navbar-nav hover-overflow-auto overflow-hidden">
                <li className="logo">
                    <Link className="navbar-brand mr-1" to="/">
                        <img src={logo} style={{width: 40}} />
                    </Link>
                </li>


                {rights.customers ? (
                    <li className="nav-item active">
                        <Link className="nav-link" to="/customers">
                            <i className="fas fa-users"/>
                            <span>Customers</span>
                        </Link>
                    </li>
                ) : null}

                <li className="nav-item active">
                    <Link className="nav-link" to="/missions">
                        <i className="fas fa-archway"></i>
                        <span>Missions</span>
                    </Link>
                </li>


                {rights.users ? (
                    <li className="nav-item active">
                        <Link className="nav-link" to="/invoices">
                            <i className="fas fa-euro-sign"></i>
                            <span>Invoices</span>
                        </Link>
                    </li>
                ) : null}

                <li className="nav-item active">
                    <Link className="nav-link" to="/timesquare">
                        <i className="fas fa-hourglass-start"></i>
                        <span>Timesquare</span>
                    </Link>
                </li>

                <li className="nav-item active">
                    <Link className="nav-link" to="/staffing">
                        <i className="far fa-rabbit-fast"></i>
                        <span>Staffing</span>
                    </Link>
                </li>
                {
                    rights.statistics ? (
                        <li className="nav-item active">
                            <Link className="nav-link" to="/stats">
                                <i className="fa fa-chart-bar"></i>
                                <span>Suivi</span>
                            </Link>
                        </li>
                    ) : null
                }
                {rights.notes ? (
                    <li className="nav-item active">
                        <Link className="nav-link" to="/notes">
                            <i className="fas fa-sticky-note"/>
                            <span>Notes</span>
                        </Link>
                    </li>
                ) : null}

                {rights.users ? (
                    <li className="nav-item active">
                        <Link className="nav-link" to="/users">
                            <i className="fas fa-globe"/>
                            <span>Users</span>
                        </Link>
                    </li>
                ) : null}

                {rights.users ? (
                    <li className="nav-item active">
                        <Link className="nav-link" to="/contacts">
                            <i className="fas fa-users"/>
                            <span>Contacts</span>
                        </Link>
                    </li>
                ) : null}

                <li className="nav-item active">
                    <a className="nav-link" href="#" onClick={this.props.logout}>
                        <i className="fas fa-sign-out-alt"/>
                        <span>Logout ({this.props.auth.user.firstname})</span>
                    </a>
                </li>
            </ul>
        )
    }


}

export default connect(({ auth }) => ({ auth }), { logout })(BaseLayout)
