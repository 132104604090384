import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import EmptyLayout from "../layouts/EmptyLayout";
import LoadingPage from "../pages/LoadingPage";
import {ROLE_CHIEF, ROLE_CUSTOMER, ROLE_EMPLOYEE, ROLE_GUEST} from "../config/roles";
import {loginFromCookie} from "../data/auth";
import Cookies from "universal-cookie";

class PageLayer extends Component
{
    componentDidMount()
    {
        if (this.props.auth.token === null) {
            this.props.loginFromCookie()
        }
    }

    render()
    {
        const auth = this.props.auth;

        console.log('auth ?', auth)

        if (auth.token === null) {
            return (
                <EmptyLayout {...this.props}>
                    <LoadingPage {...this.props} />
                </EmptyLayout>
            )
        }

        if (this.props.match.params.token) {
            const date = new Date();
            const expired_at = new Date(date.setHours(date.getHours() + 2));
            (new Cookies())
                .set('hub_token', this.props.match.params.token, { expires: expired_at, path: '/'});
        }

        if (auth.user) {
            console.log('auth user....')
            console.log('rule 1 >', this.props.roles && auth.user.roles && !this.props.roles.some(r => auth.user.roles.indexOf(r) >= 0))
            if (this.props.roles && auth.user.roles && !this.props.roles.some(r => auth.user.roles.indexOf(r) >= 0)) {
                return <Redirect to="/"/>
            }

            console.log('rule 2 >', this.props.rights && auth.user.rights && !this.props.rights.some(r => auth.user.rights[r]))
            if (this.props.rights && auth.user.rights && !this.props.rights.some(r => auth.user.rights[r])) {
                return <Redirect to="/"/>
            }
        }
        else if (this.props.roles && this.props.roles.indexOf(ROLE_GUEST) === -1) {
            return <Redirect to="/login"/>
        }

        console.log('layout ?', this.props.layout !== null ? 'Y' : 'N')
        if (this.props.layout !== null) {
            let props = {...this.props}
            if (typeof this.props.layoutProps != 'undefined') props = {...props, ...this.props.layoutProps}
            return React.createElement(this.props.layout, props, this.props.children);
        }

        return this.props.children
    }
}

export default connect(({ auth }) => ({ auth }), {loginFromCookie})(PageLayer)